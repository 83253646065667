import React, { useState, useEffect } from "react";
import "./Dynamic_panel.css";
import "./my_imports/Fonts/NotoSans-Medium.ttf";
import Application from "./utils/application.json";
import Module from "./utils/module.json";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Header from "./components/Header";
import { useDispatch, useSelector } from "react-redux";
import { module } from "./Store/Slices/UserSlice";
import { moduleAdmin } from "./Store/Slices/AdminSlice";
import { deleteModuleById, fetchModuleByAppId } from "./services/RestApi";
import CardModel from "./components/CardModel";
import LoadingModal from "./components/LoadingModal";
import { Theme } from "./utils/Theme";
import noModules from "../src/assets/noModules.png";
import { getThemeGradient } from "./utils/Theme";
import { v4 } from "uuid";
import ContentHeader from "./components/ContentHeader";
import {
  Button,
  Card,
  CardContent,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { horizontal, vertical } from "./Login";
import { BottomSheet } from "react-spring-bottom-sheet-updated";
import moment from "moment";
import BottomSheets from "./admin/BottomSheets";
import Footer from "./components/Footer";
import { AddOutlined } from "@mui/icons-material";
import { Grid } from "@material-ui/core";
import SvgIcons from "./components/SvgIcons";
import RightSideBar from "./admin/RightSideBar";

export default function Dynamic_panel(props) {
  const navigateTo = useNavigate();
  const dispatch = useDispatch();
  const isInEditMode = useLocation().pathname.split("/")[2] === "edit";
  const [currentState, setCurrentState] = useState({});

  // const onClickHandler = (item) => {
  //   dispatch(module(item));
  //   console.log("Item--->", item);
  //   navigateTo(`/${item.moduleName}/dashboard`);
  // };
  const [bottomClicked, setBottomClicked] = useState(false);
  const { applicationName } = useParams();
  const [modules, setModules] = useState([]);
  const [showLoading, SetShowLoading] = useState(true);
  const [snackbar, setSnackbar] = useState(false);
  const [message, setMessage] = useState("");

  const addModuleHandler = () => {
    navigateTo("add-new-module");
  };

  const appID = useSelector((state) => {
    return state.admin.applicationArray;
  });

  const appId = appID.find((ele) => ele.aplName === applicationName);

  useEffect(() => {
    fetchModuleByAppId({ apl_Id: appId.aplId }).then((res) => {
      if (res.status === 201) {
        setModules(res.data);
        SetShowLoading(false);
      }
    });
    const interval = setInterval(() => {
      fetchModuleByAppId({ apl_Id: appId.aplId }).then((res) => {
        if (res.status === 201) {
          setModules(res.data);
        }
      });
    }, 10000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const editiorHandler = () => {
    let item = currentState;
    dispatch(moduleAdmin(item));
    navigateTo(`${item.moduleName}/dashboard`);
  };

  const bottomClickHandler = (value, item) => {
    setBottomClicked(value);
    setCurrentState(item);
  };
  const deleteHandler = async () => {
    let item = currentState;
    await deleteModuleById({ id: item._id }).then((res) => {
      console.log(res);
      if (res.status === 200) {
        setBottomClicked(false);
        setMessage("Module deleted successfully ");
        setSnackbar(true);
      } else {
        setMessage("Something went wrong!!!! ");
        setSnackbar(true);
        setBottomClicked(false);
      }
    });
  };
  return (
    // Navbar
    <>
      {showLoading ? (
        <LoadingModal />
      ) : (
        <div className="bodies">
          <ContentHeader appId={appId} applicationName={applicationName} />

          {modules.length === 0 ? (
            isInEditMode ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  flexDirection: "column",
                  marginTop: "1rem",
                }}
              >
                {/* <img
                  src={noModules}
                  alt="No Module"
                  style={{ width: "100%", maxWidth: "500px" }}
                /> */}
                <SvgIcons icon="addForm" height={320} width={320} />
                <Typography variant="h6">
                  You do not have any module yet
                </Typography>
                <Typography variant="h7">
                  Your module will appear here
                </Typography>
                <Button
                  variant="outlined"
                  size="large"
                  style={{
                    borderColor: getThemeGradient(),
                    borderWidth: "2px",
                    borderRadius: "4px",
                    fontSize: "22px",
                    padding: "0px 60px",
                    marginTop: "20px",
                    color: getThemeGradient(),
                  }}
                  onClick={() => navigateTo("add-new-module")}
                >
                  CREATE MODULE
                </Button>
              </div>
            ) : null
          ) : (
            <div style={{ padding: "1.1rem" }}>
              <Grid container spacing={2} style={{ padding: "1rem" }}>
                {isInEditMode && (
                  <Grid item xs={5} sm={4} md={2}>
                    <Card
                      style={{
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        borderRadius: "13px",
                        cursor: "pointer",
                      }}
                      onClick={() => navigateTo("add-new-module")}
                    >
                      <CardContent style={{ textAlign: "center" }}>
                        <AddOutlined
                          style={{ color: "#03bfeb", fontSize: "4rem" }}
                        />
                        <Typography variant="h5" color="#03bfeb">
                          Add Modules
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                )}

                {modules.map((item, index) => (
                  <Grid item xs={5} sm={4} md={2} key={index}>
                    <>
                      <CardModel
                        ReducerFunction={isInEditMode ? moduleAdmin : module}
                        item={item}
                        navigate={
                          isInEditMode
                            ? `${item.moduleName}/dashboard`
                            : `${item.moduleName}/dashboard`
                        }
                        id={item._id}
                        createdDate={item.created_at}
                        Name={item.moduleName}
                        Icon={item.moduleIcon}
                        deleteButton={isInEditMode ? true : null}
                        BottomClicked={bottomClickHandler}
                        edit={
                          isInEditMode
                            ? `${currentState.moduleName}/dashboard`
                            : null
                        }
                        Application={false}
                        EditMode={isInEditMode}
                      />

                      <RightSideBar
                        Details={currentState}
                        bottomClicked={bottomClicked}
                        ReducerFunction={isInEditMode ? moduleAdmin : module}
                        bottomClickHandler={bottomClickHandler}
                        editorLink={
                          isInEditMode
                            ? `${currentState.moduleName}/dashboard`
                            : null
                        }
                        UserLink={
                          !isInEditMode
                            ? `${currentState.moduleName}/dashboard`
                            : null
                        }
                        Inside={"Module"}
                        EditMode={isInEditMode ? true : false}
                        deleteApi={isInEditMode ? deleteModuleById : null}
                      />
                    </>
                  </Grid>
                ))}
              </Grid>
            </div>
          )}
          {/* <Footer /> */}
        </div>
      )}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={snackbar}
        autoHideDuration={6000}
        message={message}
        key={vertical + horizontal}
        style={{ backgroundColor: "white", color: "black" }}
      />
    </>
  );
}
