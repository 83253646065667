import React, { createContext, useState, useContext , useEffect } from "react";

const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [currentContextState, setCurrentContextState] = useState({}); // Your initial state here

  useEffect(() => {
    // console.log("Context state updated:", currentContextState);
  }, [currentContextState]);

  return (
    <AppContext.Provider value={{ currentContextState, setCurrentContextState }}>
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);