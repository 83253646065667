import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
  input: {
    display: 'none',
  },
}));

function FileInput({ onChange, label }) {
  const classes = useStyles();
  const [file, setFile] = React.useState(null);
  const [fileName, setFileName] = React.useState('');

  const handleChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    setFileName(selectedFile.name);
    onChange(selectedFile);
  };

  return (
    <>
     
      <TextField
        id="file-name"
        label={label}
        value={fileName}
        variant="filled"
        disabled
      />
       <input
        accept="*"
        className={classes.input}
        id="contained-button-file"
        multiple
        type="file"
        onChange={handleChange}
      />
      <label htmlFor="contained-button-file">
        <Button variant="contained" component="span" color='#563538'
        style={{    backgroundColor:" #567fdd",
          marginLeft: "1rem",
          borderRadius: "10px"}}>
          Upload
        </Button>
      </label>
    </>
  );
}

export default FileInput;
