import { Card, Typography } from "@material-ui/core";
import React from "react";
import "./NewModuleForm.css";
import AddIcon from "@mui/icons-material/Add";
import ArticleIcon from "@mui/icons-material/Article";
import { useNavigate, useParams } from "react-router-dom";

function NewSubmoduleForm() {
  const navigateTo = useNavigate();
  const { applicationName } = useParams();
  return (
    // <div className='SubmoduleFormPanel'>
    //     <div className='SMFformpage'>

    //         <p className='SMFmoduleHeading'>Sub Modules</p>

    //         <div className='SMFDiv'>

    //             {/* <div>
    //                 <label htmlFor="SMFselectModule"> Select Module Name</label>
    //                 <input placeholder='Select Module' className='SMFselectModule' type="text" />
    //             </div> */}

    //             <div className='SMFmoduleNameDiv'>
    //                 <label htmlFor="SMFmoduleName"> Sub Module Name</label>
    //                 <input placeholder='Enter Sub Module Name' className='SMFmoduleName' type="text" />
    //             </div>

    //             <div className='SMFmoduleDiscriptionDiv'>
    //                 <label htmlFor="SMFmoduleDiscription">Sub Module Discription</label>
    //                 <input placeholder='Enter Sub Module Discription' className='SMFmoduleDiscription' type="text" />
    //             </div>

    //             <div className='SMFmoduleIconDiv'>
    //                 <label htmlFor="SMFmoduleIcon"> Selection Icon</label>
    //                 <input className='SMFmoduleIcon' type="file" />
    //             </div>

    //             {/* <div className='SMFmodulePathDiv'>
    //                 <label htmlFor="SMFmodulePath"> Module Path</label>
    //                 <input placeholder='Enter Module Path' className='SMFmodulePath' type="text" />
    //             </div> */}

    //             <div className='SMFmoduleStatusDiv'>
    //                 <label htmlFor="SMFmoduleStatus"> Status</label>
    //                 <input placeholder='Select Status' className='SMFmoduleStatus' type="text" />
    //             </div>

    //             <a href="/admin/:applicationName/:moduleName/dashboard">
    //                 <div className='SMFmodulebuttonDiv'>
    //                     <button className='SMFmodulebutton' type="text" >Submit</button>
    //                 </div>
    //             </a>

    //         </div>
    //     </div>
    // </div>

    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: "1%",
      }}
    >
      <div
        style={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
        }}
      >
        <Typography variant="h5" style={{ fontWeight: "bold" }}>
          Create A Sub-Module
        </Typography>
      </div>

      <div
        style={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
        }}
      >
        <Typography variant="h6" style={{ color: "#9da2c3" }}>
          Create or import a form to start gathering data
        </Typography>
      </div>

      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          // padding: "2%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "20px",
          }}
        >
          <Card
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "150px",
              background: "#c8ceed",
              cursor: "pointer",
            }}
            onClick={() => navigateTo("from-scratch")}
          >
            <AddIcon
              fontSize="large"
              style={{ fontSize: "5rem", color: "#6f76a7" }}
            />
          </Card>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "12px",
            }}
          >
            <Typography variant="h5" style={{ fontWeight: "bold" }}>
              Start From Scratch
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography variant="h6" style={{ color: "#9da2c3" }}>
              A Blank Slate is All You Need
            </Typography>
          </div>
        </div>

        {/* <div style={{ padding: "20px" }}>
          <Card
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "150px",
              background: "#c8ceed",
              cursor: "pointer",
            }}
          >
            <ArticleIcon
              fontSize="large"
              style={{ fontSize: "5rem", color: "#6f76a7" }}
            />
          </Card>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "12px",
            }}
          >
            <Typography variant="h5" style={{ fontWeight: "bold" }}>
              Use Template
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography variant="h6" style={{ color: "#9da2c3" }}>
              Choose from 10,000+ premade forms
            </Typography>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default NewSubmoduleForm;
