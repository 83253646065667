import React, { useState } from 'react';
import './SearchBar.css';

const SearchBar = ({ onSearch }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    onSearch(e.target.value); 
  };
  

  return (
    <div style={{ padding: '20px', width: '85%', height: '75px', backgroundColor: '#fbfafd' }}>
      <form className="search-bar" onSubmit={handleSearch}>
        <input
          className="search-bar-input"
          type="text"
          placeholder="Filter Member"
          value={searchTerm}
          onChange={handleSearch}
        />
        <button className="search-bar-button" type="submit">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <circle cx="11" cy="11" r="8" />
            <line x1="21" y1="21" x2="16.65" y2="16.65" />
          </svg>
        </button>
      </form>
    </div>
  );
};

export default SearchBar;
