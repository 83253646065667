// core components
import TextType from "./TextType";
import ImageInput from "./ImageInput";
import TextDescription from "./TextDescription";
import DropDown from "./DropDown";
import CheckBox from "./CheckBox";
import FileInput from "./FileInput";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Grid, Box, Card } from "@mui/material";
import RadioButton from "./RadioButton";
import Button from "@mui/material/Button";
import "./FormFile.css";
import { useEffect, useState } from "react";
import {
  addProp,
  addProperties,
  fetchSubModuleListById,
} from "../services/RestApi";
import { useSelector } from "react-redux";
import LoadingModal from "../components/LoadingModal";
import MiddleForm from "../admin/FormBuilder/MiddleForm";
import Footer from "../components/Footer";

const FormFile = () => {
  let localForm = JSON.parse(localStorage.getItem("formElements"));
  let userEmail = localStorage.getItem("email");
  console.log("User Email --->", userEmail, typeof userEmail);
  const [text, setText] = useState({});
  const [showLoading, setShowLoading] = useState(true);
  const { applicationName, moduleName, subModuleName } = useParams();
  const [formElementsList, setFormElementsList] = useState(localForm);
  const navigateTo = useNavigate();
  const { submoduleName } = useParams();

  const location = useLocation();

  const [formSubmitted, setFormSubmitted] = useState(false);

  const subModuleDetail = useSelector((state) => {
    return state.application.submoduleArray;
  });

  useEffect(() => {
    setShowLoading(false);
  }, []);

  useEffect(() => {
    setShowLoading(true);
    setTimeout(() => {
      fetchSubModuleListById({ id: subModuleDetail._id }).then((res) => {
        setFormElementsList(res.data[0].subModuleProperties);
        setShowLoading(false);
      });
    }, 500);
  }, [location]);

  const isValidated = () => {
    let canSubmit = true;
    for (let item of formElementsList) {
      item.hasError = false;
      if (typeof item.value === "object" && item.isRequired) {
        if (item.value.length > -1) {
          if (item.value.length === 0) {
            item.hasError = true;
            item.errorMsg = `${item.label} is required field`;
            canSubmit = false;
            break;
          }
        } else {
          for (let i of Object.entries(item.value)) {
            if (!i[1]) {
              item.hasError = true;
              item.errorMsg = `Fill all fields of ${item.label}`;
              canSubmit = false;
              break;
            }
          }
        }
      } else if (!item.value.length > 0 && item.isRequired) {
        canSubmit = false;
        item.hasError = true;
        item.errorMsg = `${item.label} is required field`;
      }
    }
    setFormElementsList([...formElementsList]);
    return canSubmit;
  };

  const previewSubmit = () => {
    if (isValidated()) {
      console.log("Adding Data -->", {
        application: applicationName,
        module: moduleName,
        submodule: submoduleName,
        create: "create",
        data: formElementsList,
        createdBy: userEmail,
      });
      addProperties({
        application: applicationName,
        module: moduleName,
        submodule: submoduleName,
        create: "create",
        data: formElementsList,
        createdBy: userEmail,
      }).then((res) => {
        if (res.status === 200) {
          setFormSubmitted(true);
        }
      });
    }
  };

  const closeSuccessModal = () => {
    setFormSubmitted(false);
    navigateTo(`/admin/view/${applicationName}/${moduleName}/dashboard`);
  };

  // this has to be a state and set as setstate when api is linked for submit

  return (
    <>
      {showLoading ? (
        <LoadingModal />
      ) : (
        <div className="form-card">
          <MiddleForm
            formSubmitted={formSubmitted}
            formElementsList={formElementsList}
            setFormElementsList={(elements) => setFormElementsList(elements)}
            checked={true}
            previewSubmit={previewSubmit}
            closeSuccessModal={closeSuccessModal}
          />
        </div>
      )}
      {/* <Footer subModuleFooter={true} /> */}
    </>
  );
};

export default FormFile;
