import {
  Button,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { v4 } from "uuid";
import noModules from "../assets/noModules.png";
import { addSubModuleData, uploadImage } from "../services/RestApi";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { horizontal, vertical } from "../Login";
import { LoadingButton } from "@mui/lab";
import SvgIcons from "../components/SvgIcons";
import { periscope } from "fontawesome";

const NewSubModuleFromScratch = () => {
  const [selectModule, setSelectModule] = useState("");
  const { applicationName } = useParams();
  const currentModuleName = useParams().moduleName;

  const [moduleName, setModuleName] = useState("");
  const [modulePath, setModulePath] = useState("");
  const [moduleStatus, setModuleStatus] = useState("10");
  const [tag, setTag] = useState("");
  const [tags, setTags] = useState([]);
  const [apiTags, setApiTags] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [moduleDescription, setModuleDescription] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const handleClose = () => {
    setOpenSnackbar(false);
  };

  const navigate = useNavigate();
  const moduleArr = useSelector((state) => {
    return state.admin.modArray[0];
  });

  const moduleID = moduleArr.moduleId;

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      {
        setTags((prev) => [...prev, { key: v4(), value: tag }]);
        setApiTags((prev) => [...prev, tag]);
      }
      setTag("");
    }
  };

  const handleTag = (event) => {
    setTag(event.target.value);
  };

  const handleDeleteTag = (keyToRemove) => {
    const newArray = tags.filter((item) => item.key !== keyToRemove);
    setTags(newArray);
  };

  const handlemoduleStatus = (event) => {
    setModuleStatus(event.target.value);
  };

  const handlemoduleName = (event) => {
    setModuleName(event.target.value);
    let slug = "";
    const submod = event.target.value;
    for (let i in submod) {
      if (submod[i] !== " ") {
        slug += submod[i];
      } else if (submod[i] === " ") {
        slug += "-";
      }
    }
    setModulePath(slug.toLowerCase());
  };

  const handlemoduleDescription = (event) => {
    setModuleDescription(event.target.value);
  };

  const handleSelectModule = (event) => {
    setSelectModule(event.target.value);
  };

  const handleFileSelect = (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    const formData = new FormData();
    formData.set('image', file, file.name);

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    uploadImage(formData, config).then((res) => {
      if (res.status === 200) {
        setPreviewUrl(res.data?.url)
      }
    })

    // if (file) {
    //   const reader = new FileReader();
    //   reader.onloadend = () => {
    //    ;
    //   };
    //   // reader.readAsDataURL(file);
    //   const buffer = reader.readAsArrayBuffer(file)
    //   setSelectedFile(file.name)
    //   console.log(buffer);
    // }

  };
  const handleSubmit = () => {
    if (
      moduleName.length === 0 ||
      moduleDescription.length === 0 ||
      modulePath.length === 0
    ) {
      setError(true);
      return;
    }
    setError(false);
    setLoading(true);
    addSubModuleData({
      subModuleName: moduleName,
      subModulePath: modulePath,
      tags: apiTags,
      subModuleProperties: [],
      subModuleDescription: moduleDescription,
      subModuleIcon: previewUrl,
      status: moduleStatus === "10" ? 1 : 0,
      moduleId: moduleID,
      is_active: moduleStatus === "10" ? true : false,
    }).then((res) => {
      if (res.status === 201) {
        setLoading(false);
        setOpenSnackbar(true);
        setMessage("SubModule Added!!!");
        setTimeout(() => {
          navigate(
            `/admin/edit/${applicationName}/${currentModuleName}/dashboard`
          );
        }, 500);
      }
    });
  };

  return (
    <div>
      <div className="stepperFormOuter">
        <div className="stepperFormInner">
          <div className="contents">
            <div style={{ display: "flex" }}>
              <div
                className="AFDiv"
                style={{
                  height: "72vh",
                  overflowX: "hidden",
                  overflowY: "auto",
                  width: "100%",
                }}
              >
                <div className="textFields flexColumnDirection">
                  <TextField
                    label="Sub Module Name"
                    variant="outlined"
                    value={moduleName}
                    onChange={handlemoduleName}
                    style={{ width: "40vw", fontSize: "12px" }}
                  />
                  {error && moduleName.length === 0 && (
                    <span style={{ color: "red" }}>Fill sub module name</span>
                  )}
                </div>

                <div className="textFields flexColumnDirection">
                  <TextField
                    label="Sub Module Description"
                    variant="outlined"
                    value={moduleDescription}
                    onChange={handlemoduleDescription}
                    style={{ width: "40vw" }}
                  />
                  {error && moduleName.length === 0 && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      Fill sub module description
                    </span>
                  )}
                </div>

                <div className="textFields flexColumnDirection">
                  <InputLabel htmlFor="logo-file-input">Select Icon</InputLabel>
                  <OutlinedInput
                    type="file"
                    id="logo-file-input"
                    inputProps={{ accept: "image/*" }}
                    onChange={handleFileSelect}
                    style={{ width: "40vw" }}
                  />
                  <img src={previewUrl} alt="Sub Module Icon" style={{ height: "100px", width: "100px" }} />
                </div>

                <div className="textFields flexColumnDirection">
                  <TextField
                    label="Submodule Slug"
                    variant="outlined"
                    value={modulePath}
                    onChange={handlemoduleName}
                    style={{ width: "40vw" }}
                  />
                  {error && modulePath.length === 0 && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      Fill sub module slug
                    </span>
                  )}
                </div>

                <div>
                  <label htmlFor="AFmodulePath">Tags</label>
                  {tags.map((item) => {
                    return (
                      <button key={item.key} className="AFtagButton">
                        {item.value}{" "}
                        <i
                          id="AFtagCloseIcon"
                          className="fa-solid fa-xmark"
                          onClick={() => handleDeleteTag(item.key)}
                        />
                      </button>
                    );
                  })}
                  <div className="textFields flexColumnDirection">
                    <TextField
                      label="Tags"
                      variant="outlined"
                      value={tag}
                      onKeyDown={handleKeyDown}
                      onChange={handleTag}
                      style={{ width: "40vw" }}
                    />
                    {error && tags.length === 0 && (
                      <span style={{ color: "red", fontSize: "12px" }}>
                        Fill Tags
                      </span>
                    )}
                  </div>
                </div>

                <div className="textFields">
                  <InputLabel id="dropdown-label">Select an option</InputLabel>
                  <Select
                    labelId="dropdown-label"
                    id="dropdown"
                    value={moduleStatus}
                    label="Select an option"
                    onChange={handlemoduleStatus}
                    variant="outlined"
                    style={{ width: "30%" }}
                  >
                    <MenuItem value="10">Active</MenuItem>
                    <MenuItem value="30">Inactive</MenuItem>
                  </Select>
                </div>
                <div className="textFields">
                  <LoadingButton
                    color="primary"
                    loading={loading}
                    loadingIndicator="Loading..."
                    variant="contained"
                    onClick={handleSubmit}
                  >
                    <span>Submit</span>
                  </LoadingButton>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "75%",
                  flexDirection: "column",
                }}
              >
                {/* <img
                src={noModules}
                alt="No Module"
                style={{ width: "100%", maxWidth: "500px" }}
              /> */}
                <SvgIcons icon="addForm" height={320} width={320} />

                <Typography variant="h4" width={320} textAlign="end" mt={1}>
                  Add Sub module
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={openSnackbar}
        onClose={handleClose}
        autoHideDuration={6000}
        message={message}
        key={vertical + horizontal}
      />
    </div>
  );
};

export default NewSubModuleFromScratch;
