import { Button, Drawer, makeStyles, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { Folder } from "@mui/icons-material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import MyAccordion from "./MyAccordion";
import { useSelector } from "react-redux";
import { fetchModuleByAppId } from "../services/RestApi";

const drawerWidth = "100%";

const useStyles = makeStyles({
  drawer: {
    width: drawerWidth,
    height: "100%",
  },
  drawerPaper: {
    position: "static",
    width: "100%",
    height: "100%",
  },
});

const MainSideBar = () => {
  const navigateTo = useNavigate();
  const classes = useStyles();

  const { applicationName } = useParams();
  const [modules, setModules] = useState([]);

  const appID = useSelector((state) => {
    return state.admin.applicationArray;
  });

  const appId = appID.find((ele) => ele.aplName === applicationName);
  useEffect(() => {
    setTimeout(() => {
      fetchModuleByAppId({ apl_Id: appId.aplId }).then((res) => {
        if (res.status === 201) {
          setModules(res.data);
        }
      }, 500);
    });
  }, []);
  const menuItems = modules;

  // const menuItems = [
  //   {
  //     text: "My Notes",
  //     icon: <Folder color="black" />,
  //     path: "/",

  //   },
  //   {
  //     text: "Create Notes",
  //     icon: <Folder color="black" />,
  //     path: "/",

  //   },
  //   {
  //     text: "Videos",
  //     icon: <Folder color="black" />,
  //     path: "/",

  //   },
  // ];
  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      anchor="left"
      classes={{ paper: classes.drawerPaper }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "10px",
        }}
      >
        <Button
          style={{ background: "#e65800", color: "white", width: "100%" }}
          size="large"
          onClick={() => navigateTo(`${appId.aplName}/dashboard`)}
        >
          {appId.aplName} Application
        </Button>
      </div>

      <div style={{ padding: "0 6%" }}>
        <Typography variant="h6">Modules</Typography>
      </div>

      <List>
        {menuItems.map((item) => (
          <MyAccordion item={item} key={item.moduleId} />
        ))}
      </List>
    </Drawer>
  );
};

export default MainSideBar;
