import React , {useState}from 'react'
import { useLocation } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { CardMedia, Fab, MenuItem, Select } from '@mui/material';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { format } from 'date-fns';
import DottedMenuIcon from './DottedMenuIcon';
import { ModifyApplicationAccessPost } from '../services/RestApi';

export default function SettingTable({ searchKeyword }) {
  const location = useLocation();
  const item = location.state;
  const app_id = item.item._id;
  const sourceId = item.item.team[0].source;

  const handleReload = () => {
    window.location.reload();
  };

  const [role, setRole] = useState();

  
  const handleChange = (event , user ) => {
   
    setRole(event.target.value);
   if(event.target.value){
    const inviteData = {
      email : user.email,
      app_id,
      source_id: sourceId,
      role : event.target.value,
      
      
    };
    ModifyApplicationAccessPost(inviteData)
    .then((res) => {
      handleReload()
    });
   }
  };

  const filteredUsers = item.item.team.filter((user) =>
    user.email.toLowerCase().includes(searchKeyword.toLowerCase())
  );

  return (
    <div>
      <TableContainer style={{overflow: "scroll" , height:"355px"}} component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
            <TableRow  >
              <TableCell className='font' >Account</TableCell>
              {/* <TableCell className='font' align="left">Source</TableCell> */}
              <TableCell className='font' align="left">Max&nbsp;Role</TableCell>
              {/* <TableCell className='font' align="left">Expiration</TableCell> */}
              <TableCell className='font' align="left">Activity</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredUsers.map((user) => {
              return (
                   <TableRow

                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell style={{ padding: "4px", width: "30%" }} component="th" scope="row">
                    <div className='person'>
                      <div className='personinfo'>
                        <CardMedia
                          component="img"
                          height={30}
                          style={{ borderRadius: "50%", marginTop: "3px", marginRight: "2px" }}
                          image={`https://ui-avatars.com/api/?background=03bfeb&color=fff&name=${user.email}`
                          }
                          alt="Name"
                        />
                        <div>
                          <h5 className='name'>{user.email}</h5>
                        </div>
                      </div>
                    </div>

                  </TableCell>

                  {/* <TableCell style={{ padding: "4px" }} align="left"> */}
                  {/* <div>
                      {user.source}
                    </div> */}
                  {/* </TableCell> */}

                  <TableCell style={{ padding: "4px" }} align="left">
                    <div style={{ padding: "0" }}>
                      <div >
                        <Select labelId="demo-simple-select-label" id="demo-simple-select" style={{
                          backgroundColor: "#e8eaeb", padding: "1.5px 0px ",
                          width: "110px"
                        }} value={user.role} onChange={(event)=>handleChange(event,user)} 
                        disabled ={user.role == "Owner" ? true : false}
                        >
                          
                          <MenuItem disabled  value={"Owner"}>Owner</MenuItem>
                          <MenuItem value={"admin"}>Admin</MenuItem>
                          <MenuItem value={"editor"}>Editor</MenuItem>
                          <MenuItem value={"user"}>User</MenuItem>
                        </Select>


                      </div>
                    </div>

                  </TableCell>

                  {/* <TableCell style={{ padding: "4px", width: "25%" }} align="left">
                    <div>
                      <LocalizationProvider style={{ padding: "0px" }} dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker']}>
                          <DatePicker  />
                        </DemoContainer>
                      </LocalizationProvider>
                    </div>
                  </TableCell> */}

                  <TableCell style={{ padding: "4px" }} align="left">
                    <div>
                      <p style={{ margin: "0px" }} >User Created:{format(new Date(item.item.created_at), ' dd/mm/yyyy  hh:mm:ss')}</p>
                      <p style={{ margin: "0px" }} >Access Granted:{format(new Date(user.access), ' dd/mm/yyyy  hh:mm:ss')}</p>
                      <p style={{ margin: "0px" }} >Last Activity:{format(new Date(item.item.updated_at), ' dd/mm/yyyy  hh:mm:ss')}</p>
                    </div>

                  </TableCell>
                  {user.role!=="Owner" && <DottedMenuIcon user={user} app_id={app_id} handleReload={handleReload}/>}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
