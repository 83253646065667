import React from "react";
import { Theme, getThemeGradient } from "../utils/Theme";

function ContentHeader({ Application, appId, applicationName }) {
  return (
    <>
      {Application ? (
        <div
          className="introductionHeader"
          style={{
            backgroundColor: Theme.primary,
            background: getThemeGradient(),
          }}
        >
          <h1 style={{ width: "100%", color: "white" }}>
            Welcome To NoLowStack
          </h1>
          <h4 style={{ width: "100%", color: "black" }}>
            Let's start with creating your App.
          </h4>
        </div>
      ) : (
        <div
          className="introductionHeader"
          style={{
            flexDirection: "row",
            backgroundColor: Theme.primary,
            background: getThemeGradient(),
          }}
        >
          <div style={{ width: "12%" }}>
            <img
              src={
                appId.aplLogo
                  ? appId.aplLogo
                  : appId.moduleIcon
                  ? appId.moduleIcon
                  : `https://ui-avatars.com/api/?background=fff&color=03bfeb&name=${
                      appId.aplName ? appId.aplName : appId.moduleName
                    }`
              }
              alt="Application Logo"
              style={{
                width: "120px",
                height: "120px",
                maxHeight: "125px",
                maxWidth: "125px",
                objectFit: "cover",
              }}
            />
          </div>
          <div className="user_div">
            <span className="user_name">{applicationName}</span>
            <span className="user_description">
              {appId.aplDescription
                ? appId.aplDescription
                : appId.moduleDescription}
            </span>
          </div>
        </div>
      )}
    </>
  );
}

export default ContentHeader;
