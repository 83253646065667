import { createSlice } from "@reduxjs/toolkit";

const adminSlice = createSlice({
  name: "admin",
  initialState: {
    applicationArray: [],
    modArray: [],
    subModArray: [],
  },
  reducers: {
    application(state, action) {
      state.applicationArray = [...state.applicationArray, action.payload];
    },
    moduleAdmin(state, action) {
      state.modArray = [action.payload];
    },
    submoduleAdmin(state, action) {
      state.subModArray = [...state.subModArray, action.payload];
    },
  },
});

export const { application, moduleAdmin, submoduleAdmin } = adminSlice.actions;
export default adminSlice.reducer;
