import * as React from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import AddIcon from "@mui/icons-material/Add";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Header from "../components/Header";
import "./StepperForm.css";
import "./ApplicationForm.css";
import ArticleIcon from "@mui/icons-material/Article";
import { useState, useEffect } from "react";
import Radio from "@mui/material/Radio";
import { Card, InputLabel, MenuItem, Select } from "@material-ui/core";
import { Snackbar, TextField } from "@mui/material";
import { addApplicationData, applicationList, uploadImage } from "../services/RestApi";
import { horizontal, vertical } from "../Login";
import { v4 } from "uuid";
import noModules from "../assets/noModules.png";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate } from "react-router-dom";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import SvgIcons from "../components/SvgIcons";
import Footer from "../components/Footer";

// const steps = ["Add Application", "Select Database", "Preview"];

const getSteps = () => {
  return ["Add Application", "Select Database", "Preview"];
};

export default function StepperForm() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [database, setDatabase] = useState("managed");
  const [mongoDbUrl, setMongoDbUrl] = useState("");
  const [applicationName, setApplicationName] = useState("");
  const [applicationDescription, setApplicationDescription] = useState("");
  const navigate = useNavigate();
  const [tag, setTag] = useState("");
  const [tags, setTags] = useState([]);
  const [apiTags, setApiTags] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [message, setMessage] = useState("");
  const [applicationStatus, setApplicationStatus] = useState("10");
  const [loading, setLoading] = useState(false);
  const [applicationPath, setApplicationPath] = useState("");
  const [error, setError] = useState(false);
  const [aplList, setAplList] = useState([]);
  const [aplExist, setAplExist] = useState(false);
  useEffect(() => {
    applicationList().then(async (res) => {
      const data = res.data;
      var result = [];
      await data.forEach(function (obj) {
        result.push(obj.aplName.toLowerCase());
      });
      setAplList(result);
    });
  }, []);

  const handleApplicationStatus = (event) => {
    setApplicationStatus(event.target.value);
  };
  const handleClose = () => {
    setOpenSnackbar(false);
  };

  const handleFileSelect = (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    const formData = new FormData();
    formData.set('image', file, file.name);
  
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    uploadImage(formData,config).then((res)=>{
      if(res.status===200){
      setPreviewUrl(res.data?.url)
      }
    })

    // if (file) {
    //   const reader = new FileReader();
    //   reader.onloadend = () => {
    //    ;
    //   };
    //   // reader.readAsDataURL(file);
    //   const buffer = reader.readAsArrayBuffer(file)
    //   setSelectedFile(file.name)
    // }
    
  };

  const handleTag = (event) => {
    setTag(event.target.value);
  };

  const steps = getSteps();

  const handleMongoDbUrl = (event) => {
    setMongoDbUrl(event.target.value);
  };

  const handleDeleteTag = (keyToRemove) => {
    const newArray = tags.filter((item) => item.key !== keyToRemove);
    setTags(newArray);
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      {
        setTags((prev) => [...prev, { key: v4(), value: tag }]);
        setApiTags((prev) => [...prev, tag]);
      }
      setTag("");
    }
  };

  const handleApplicationName = (event) => {
    setAplExist(false);
    if (!aplList.includes(event.target.value.toLowerCase())) {
      setApplicationName(event.target.value);
    } else {
      setAplExist(true);
    }
    let slug = "";
    const apl = event.target.value;
    for (let i in apl) {
      if (apl[i] !== " ") {
        slug += apl[i];
      } else if (apl[i] === " ") {
        slug += "-";
      }
    }
    setApplicationPath(slug.toLowerCase());
  };

  const handleApplicationDescription = (event) => {
    setApplicationDescription(event.target.value);
  };

  const handleChange = (value) => {
    setDatabase(value);
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <div style={{ display: "flex" }}>
            <div
              className="AFDiv"
              style={{
                height: "70vh",
                overflowX: "hidden",
                overflowY: "auto",
                width: "100%",
              }}
            >
              <div className="textFields flexColumnDirection">
                <TextField
                  label="Application Name"
                  variant="outlined"
                  value={applicationName}
                  onChange={handleApplicationName}
                  style={{ width: "40vw" }}
                />
                {error && applicationName.length === 0 ? (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    *Fill application name
                  </span>
                ) : (
                  aplExist && (
                    <span style={{ color: "red", fontSize: "14px" }}>
                      *Application Name Already Taken
                    </span>
                  )
                )}
              </div>

              <div className="textFields flexColumnDirection">
                <TextField
                  label="Application Description"
                  variant="outlined"
                  value={applicationDescription}
                  onChange={handleApplicationDescription}
                  style={{ width: "40vw" }}
                />
                {/* {error && applicationDescription.length === 0 && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    *Fill application description
                  </span>
                )} */}
              </div>

              <div className="textFields flexColumnDirection">
                <TextField
                  variant="outlined"
                  type="file"
                  onChange={handleFileSelect}
                  style={{ width: "40vw" }}
                />
                {previewUrl == null ? null : <img src={previewUrl} alt="icon" style={{width:"150px", height:"150px"}}/> }

              </div>

              <div>
                <label htmlFor="AFmodulePath">Tags</label>
                {tags.map((item) => {
                  return (
                    <button key={item.key} className="AFtagButton">
                      {item.value}{" "}
                      <i
                        id="AFtagCloseIcon"
                        className="fa-solid fa-xmark"
                        onClick={() => handleDeleteTag(item.key)}
                      />
                    </button>
                  );
                })}
                <div className="textFields flexColumnDirection">
                  <TextField
                    label="Tags"
                    variant="outlined"
                    value={tag}
                    onKeyDown={handleKeyDown}
                    onChange={handleTag}
                    style={{ width: "40vw" }}
                  />
                  {/* {error && tags.length === 0 && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      *Fill Tags
                    </span>
                  )} */}
                </div>
              </div>

              <div className="textFields flexColumnDirection">
                <TextField
                  label="Application Slug"
                  variant="outlined"
                  value={applicationPath}
                  onChange={handleApplicationName}
                  style={{ width: "40vw" }}
                />
                {/* {error && applicationPath.length === 0 && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    Fill Application slug
                  </span>
                )} */}
              </div>

              <div className="textFields flexColumnDirection">
                <InputLabel id="dropdown-label">Select an option</InputLabel>
                <Select
                  labelId="dropdown-label"
                  id="dropdown"
                  value={applicationStatus}
                  label="Select an option"
                  onChange={handleApplicationStatus}
                  variant="outlined"
                  style={{ width: "30%" }}
                >
                  <MenuItem value="10">Active</MenuItem>
                  <MenuItem value="30">Inactive</MenuItem>
                </Select>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "75%",
                flexDirection: "column",
              }}
            >
              {/* <img
                src={noModules}
                alt="No Module"
                style={{ width: "100%", maxWidth: "500px" }}
              /> */}
              <SvgIcons icon="addForm" height={320} width={320} />

              <Typography variant="h4" width={320} textAlign="end" mt={1}>
                Create Application
              </Typography>
            </div>
          </div>
        );

      case 1:
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              style={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                padding: "2%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "20px",
                }}
              >
                <Card
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "70px",
                    background: "#c8ceed",
                    cursor: "pointer",
                    border: database === "managed" ? "2px solid #00d1e4" : null,
                  }}
                  onClick={() => handleChange("managed")}
                >
                  <ArticleIcon
                    fontSize="large"
                    style={{ fontSize: "5rem", color: "#6f76a7" }}
                  />
                </Card>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "12px",
                  }}
                >
                  <Typography variant="h5" style={{ fontWeight: "bold" }}>
                    Managed
                  </Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography style={{ color: "#9da2c3" }}>
                    Your database will be managed by us
                  </Typography>
                </div>
              </div>

              {/* <div style={{ padding: "20px" }}>
                <Card
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "70px",
                    background: "#c8ceed",
                    cursor: "pointer",
                    border: database === "custom" ? "2px solid #00d1e4" : null,
                  }}
                  onClick={() => handleChange("custom")}
                >
                  <AddIcon
                    fontSize="large"
                    style={{ fontSize: "5rem", color: "#6f76a7" }}
                  />
                </Card>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "12px",
                  }}
                >
                  <Typography variant="h5" style={{ fontWeight: "bold" }}>
                    Custom
                  </Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography style={{ color: "#9da2c3" }}>
                    Provide your custom database link
                  </Typography>
                </div>
              </div> */}
            </div>
            {database === "custom" && (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <TextField
                  label="Custom Url"
                  variant="outlined"
                  value={mongoDbUrl}
                  onChange={handleMongoDbUrl}
                  style={{ width: "30vw" }}
                />
                {error && mongoDbUrl.length === 0 && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    Provide Link
                  </span>
                )}
              </div>
            )}
          </div>
        );
      case 2:
        return (
          <div style={{ display: "flex" }}>
            <div className="AFDiv">
              <div className="textFields">
                <TextField
                  disabled
                  label="Application Name"
                  variant="outlined"
                  value={applicationName}
                  onChange={handleApplicationName}
                  style={{ width: "40vw" }}
                />
              </div>

              <div className="textFields">
                <TextField
                  disabled
                  label="Application Description"
                  variant="outlined"
                  value={applicationDescription}
                  onChange={handleApplicationDescription}
                  style={{ width: "40vw" }}
                />
              </div>

              <div className="textFields">
                <TextField
                  disabled
                  variant="outlined"
                  type="file"
                  onChange={handleFileSelect}
                  style={{ width: "40vw" }}
                />
              </div>

              <div>
                <label htmlFor="AFmodulePath">Tags</label>
                {tags.map((item) => {
                  return (
                    <button key={item.key} className="AFtagButton">
                      {item.value}{" "}
                    </button>
                  );
                })}
                {/* <div className="textFields">
                  <TextField
                    disabled
                    label="Tags"
                    variant="outlined"
                    value={tag}
                    style={{ width: "40vw" }}
                  />
                </div> */}
              </div>

              <div className="textFields">
                <TextField
                  label="Application Slug"
                  variant="outlined"
                  value={applicationPath}
                  disabled
                  onChange={handleApplicationName}
                  style={{ width: "40vw" }}
                />
                {error && applicationPath.length === 0 && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    Fill Application slug
                  </span>
                )}
              </div>

              <div className="textFields">
                <InputLabel id="dropdown-label">Select an option</InputLabel>
                <Select
                  labelId="dropdown-label"
                  id="dropdown"
                  disabled
                  value={applicationStatus}
                  label="Select an option"
                  variant="outlined"
                  style={{ width: "30%" }}
                >
                  <MenuItem value="10">Active</MenuItem>
                  <MenuItem value="30">Inactive</MenuItem>
                </Select>
              </div>
            </div>

            <div
              style={{
                margin: "10px 0px 40px 40px",
                padding: "20px",
              }}
            >
              <p>{`Database Type - ${
                database === "managed" ? "Managed" : "Custom"
              } `}</p>
              {database === "custom" && <p>URL - {mongoDbUrl}</p>}
            </div>
          </div>
        );
      default:
        return "Unknown Step";
    }
  };

  const handleNext = () => {
    if (
      activeStep === 0 &&
      (applicationName.length === 0 
        // ||
        // applicationDescription.length === 0 ||
        // tags.length === 0
        )
    ) {
      setError(true);
      return;
    } else if (
      activeStep === 1 &&
      database === "custom" &&
      mongoDbUrl.length === 0
    ) {
      setError(true);
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setError(false);
  };

  const handleFinish = () => {
    setLoading(true);
    if (applicationName !== "") {
      addApplicationData({
        aplName: applicationName,
        aplLogo: previewUrl,
        aplDescription: applicationDescription,
        createdBy: localStorage.getItem("_id"),
        is_status: applicationStatus === "10" ? true : false,
        status: applicationStatus === "10" ? 1 : 0,
        tags: apiTags,
        aplPath: applicationPath,
      })
        .then((res) => {
          if (res.status === 201) {
            setOpenSnackbar(true);
            setLoading(false);
            setMessage("Application Added Successfully!!!");
            navigate("/admin");
          }
        })
        .catch((e) => {
          setOpenSnackbar(true);
          setMessage(e);
        });
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <div>
      {/* <div>
        <Header />
      </div> */}
      <div className="stepperFormOuter">
        <div className="stepperFormInner">
          <Stepper activeStep={activeStep}>
            {steps.map((step, index) => {
              return (
                <Step key={index}>
                  <StepLabel>{step}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <>
            <div className="contents">
              {getStepContent(activeStep)}
              <div className="backNextButtons">
                <Button
                  variant="contained"
                  color="primary"
                  style={activeStep === 0 ? { visibility: "hidden" } : {}}
                  onClick={handleBack}
                >
                  Back
                </Button>

                <div>
                  <Button
                    onClick={() => navigate("/admin")}
                    variant="text"
                    style={{ marginRight: "5px" }}
                  >
                    I'll Do It Later
                  </Button>
                  <LoadingButton
                    size="medium"
                    color="primary"
                    onClick={activeStep === 2 ? handleFinish : handleNext}
                    loading={loading}
                    loadingIndicator="Loading…"
                    variant="contained"
                  >
                    {activeStep === 2 ? <sapn>Finish</sapn> : <span>Next</span>}
                  </LoadingButton>
                </div>
              </div>
              
            </div>
          </>
        </div>
        
      </div>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={openSnackbar}
        onClose={handleClose}
        autoHideDuration={6000}
        message={message}
        key={vertical + horizontal}
      />
      {/* <Footer /> */}
    </div>
  );
}
