import http from "./http-common.js";

export const baseUrl = "https://nolowstack-8862663ff430.herokuapp.com";

// ********* Super Panel API **********

export const register = (data) => {
  return http.post("/register", data);
};
export const login = (data) => {
  return http.post("/logIn", data);
};
export const applicationListById = (data) => {
  return http.get(`/Application-list-ById/${data.id}`);
};
export const applicationListByIdRole = (data) => {
  return http.get(`/Application-list-ById-role/${data.id}`);
};
export const uploadImage = (data, config) => {
  return http.post("/uploads", data, config);
};
export const applicationList = () => {
  return http.get("/Applications/list");
};
export const addApplicationData = (data) => {
  return http.post("/Application", data);
};
export const updateApplicationData = (data) => {
  return http.patch(`/Application/${data.id}`, data);
};
export const deleteApplication = (data) => {
  return http.delete(`/Application/${data.id}`, data);
};
export const countApplicationById = (data) => {
  return http.get("/count-Application-total", data);
};
export const showApplicationCount = (data) => {
  return http.get("/showApplicationCount", data);
};
export const showModuleCount = (data) => {
  return http.get("/showModelCount", data);
};
export const showSubModuleCount = (data) => {
  return http.get("/showSubModelCount", data);
};
export const fetchModuleListById = (data) => {
  return http.get("/Module-list-ById/:id", data);
};
export const fetchModuleList = (data) => {
  return http.get("/Module/list", data);
};
export const addModuleData = (data) => {
  return http.post("/Module", data);
};
export const updateModuleDataById = (data) => {
  return http.patch(`/Module/${data.id}`, data);
};
export const deleteModuleById = (data) => {
  return http.delete(`/Module/${data.id}`, data);
};
export const fetchModuleByAppId = (data) => {
  return http.get(`/get-Module-By-ApplicationId/${data.apl_Id}`);
};
export const countModuleByAppId = (data) => {
  return http.get("/count-Module-By-ApplicationId/:apl_Id", data);
};
export const fetchSubModuleListById = (data) => {
  return http.get(`/SubModule-list-ById/${data.id}`, data);
};
export const fetchSubModuleData = (data) => {
  return http.get("/SubModule/list", data);
};
export const addSubModuleData = (data) => {
  return http.post("/SubModel", data);
};
export const updateSubModuleData = (data) => {
  return http.patch(`/SubModel/${data.subModuleId}`, data);
};
export const deleteSubModuleData = (data) => {
  return http.delete(`/SubModel/${data.id}`, data);
};
export const fetchSubModuleByModuleId = (data) => {
  return http.get(`/get-subModule-By-ModuleId/${data.moduleId}`);
};
export const countSubModuleByModuleId = (data) => {
  return http.get("/count-SubModule-By-ModuleId/:moduleId", data);
};
export const generateAPI = (data) => {
  return http.get("/generateAPI/:id", data);
};
export const generateJSON = (data) => {
  return http.get("/generateJSON/:id", data);
};

//****************************

//****************************

export const addProperties = (details) => {
  return http.post(
    `/${details.application}/${details.module}/${details.submodule}/${details.create}`,
    details
  );
};
export const viewProperties = (details) => {
  return http.get(
    `/${details.application}/${details.module}/${details.submodule}/${details.read}`,
    details.data
  );
};
export const updateProperties = (details) => {
  return http.put(
    `/${details.application}/${details.module}/${details.submodule}/${details.id}`,
    details.data
  );
};
export const deleteProperties = (details) => {
  return http.delete(
    `/${details.application}/${details.module}/${details.submodule}/${details.id}`,
    details.data
  );
};
export const ModifyApplicationAccess = (data) => {
  return http.patch(`/Application-role/`, data);
};
export const ModifyApplicationAccessPost = (data) => {
  return http.post(`/Application-role/`, data);
};
export const getUserDetailsById = (data) => {
  return http.post(`/getUserById`, data);
};
