import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import "./AdminPanel.css";
import {
  applicationListById,
  applicationListByIdRole,
  deleteApplication,
  updateApplicationData,
} from "../services/RestApi";
import { useDispatch } from "react-redux";
import { application } from "../Store/Slices/AdminSlice";
import CardModel from "../components/CardModel";
import LoadingModal from "../components/LoadingModal";
import ContentHeader from "../components/ContentHeader";
import { Card, CardContent, Grid, Snackbar, Typography } from "@mui/material";
import { horizontal, vertical } from "../Login";
import BottomSheets from "./BottomSheets";
import { AddOutlined } from "@mui/icons-material";
import RightSideBar from "./RightSideBar";

export default function AdminPanel() {
  const navigateTo = useNavigate();
  const dispatch = useDispatch();
  const [toggle, setToggle] = useState("");
  const [applicationName, setApplicationName] = useState([]);
  const [icon, setIcon] = useState(["fa-explosion"]);
  const [applicationTotal, setApplicationTotal] = useState(0);
  const [activeCount, setActiveCount] = useState(0);
  const [inactiveCount, setInactiveCount] = useState(0);
  const [showLoading, SetShowLoading] = useState(true);
  const [popUp, setPopUp] = useState(false);
  const [bottomClicked, setBottomClicked] = useState(false);
  const [currentState, setCurrentState] = useState({});
  const passBottomSheet = true;
  const [snackbar, setSnackbar] = useState(false);
  const [message, setMessage] = useState("");
  const [tagString, setTagString] = useState("");
  const isCurrentStateAvailable = currentState !== undefined;

  const modulenameAdd = (Event) => {
    setToggle(Event.target.id);
  };

  const countApplication = () => {
    applicationListByIdRole({ id: localStorage.getItem("_id") }).then((res) => {
      if (res.status === 200) {
        let count = res?.data.length;
        if (count === 0) setPopUp(true);
        setApplicationTotal(count);
        const array = res.data.map((ele) => ele);
        const appNamesOnly = array.map((ele) => ele.aplName);
        setApplicationName(array);
      }
      let activeCount = 0,
        inactiveCount = 0;

      res?.data.map((ele) => {
        if (ele.status === 1) {
          activeCount++;
        } else {
          inactiveCount++;
        }
      });
      setActiveCount(activeCount);
      setInactiveCount(inactiveCount);
      SetShowLoading(false);
    });
  };
  useEffect(() => {
    countApplication();
    const interval = setInterval(() => {
      countApplication();
    }, 10000);
    return () => {
      clearInterval(interval);
    };
  }, []);
  const modulenameRemove = () => {
    setToggle("'");
  };
  const addApplicationHandler = () => {
    navigateTo("add-new-application");
  };
  const onApplicationHandler = (item) => {
    navigateTo();
  };
  const popUpCloseHandler = (value) => {
    setPopUp(value);
  };
  const bottomClickHandler = (value, item) => {
    setBottomClicked(value);
    setCurrentState(item);
  };
  const editiorHandler = () => {
    let item = currentState;
    dispatch(application(item));
    navigateTo(`/admin/edit/${item.aplName}`);
  };
  const userHandler = () => {
    let item = currentState;
    dispatch(application(item));
    navigateTo(`/admin/view/${item.aplName}`);
  };
  const deleteHandler = async () => {
    let item = currentState;
    await deleteApplication({ id: item._id }).then((res) => {
      if (res.status === 200) {
        setBottomClicked(false);
        setMessage("Application deleted successfully ");
        setSnackbar(true);
      } else {
        setMessage("Something went wrong!!!! ");
        setSnackbar(true);
        setBottomClicked(false);
      }
    });
  };

  return (
    <>
      {showLoading ? (
        <LoadingModal />
      ) : (
        <div className="masterDiv">
          {/* navbar */}
          {/* <Header /> */}

          {/* Dashboard */}
          <div>
            <div
              style={{ background: "#03bfeb14" }}
              className="dashboardContents"
            >
              {/* ApplicationData */}
              {/* <div className="numberedApplication">
            <div
              className="totalApplicationModules"
              id="Application"
              onMouseEnter={modulenameAdd}
              onMouseLeave={modulenameRemove}
            >
              <p className="applicationpara">
                {applicationTotal}
                <span
                  className="invisibleModulename"
                  style={{ color: "#00a7ffff", marginLeft: "10px" }}
                  // style={
                  //   toggle == "Application"
                  //     ? {
                  //         display: "flex",
                  //         color: "#00a7ffff",
                  //         marginLeft: "10px",
                  //       }
                  //     : { display: "none" }
                  // }
                >
                  Applications
                </span>
              </p>
            </div>
            <div
              className="totalApplicationModules"
              id="Active"
              onMouseEnter={modulenameAdd}
              onMouseLeave={modulenameRemove}
            >
              <p className="applicationpara">
                {activeCount}{" "}
                <span
                  className="invisibleModulename"
                  style={{ color: "#00a7ffff", marginLeft: "10px" }}
                >
                  Active
                </span>
              </p>
            </div>
            <div
              className="totalApplicationModules"
              id="Inactive"
              onMouseEnter={modulenameAdd}
              onMouseLeave={modulenameRemove}
            >
              <p className="applicationpara">
                {inactiveCount}
                <span
                  className="invisibleModulename"
                  style={{ color: "#00a7ffff", marginLeft: "10px" }}
                >
                  Inactive
                </span>
              </p>
            </div>
          </div> */}

              {/* ActiveApplications */}
              {applicationTotal === 0 ? (
                //  <StepperForm/>
                <Navigate to="/admin/add-new-application" />
              ) : (
                <div>
                  <ContentHeader Application={true} />
                  {/* <div className="activeApplication">
                    {applicationName?.map((item, index) => {
                      if (item.status === 1) {
                        return (
                          <div key={index} className="applications">
                            <CardModel
                              ReducerFunction={application}
                              item={item}
                              navigate={`/admin/view/${item.aplName}`}
                              edit={`/admin/view/${item.aplName}/?edit=true`}
                              id={item._id}
                              updatedDate={item.updated_at}
                              Name={item.aplName}
                              Icon={item.aplLogo}
                              tags={item.tags}
                              BottomClicked={
                                passBottomSheet ? bottomClickHandler : undefined
                              }
                              Application={true}
                              EditMode={false}
                            />
                            {isCurrentStateAvailable && (
                              <BottomSheets
                                Details={currentState}
                                updateLink={updateApplicationData}
                                bottomClicked={bottomClicked}
                                ReducerFunction={application}
                                bottomClickHandler={bottomClickHandler}
                                editorLink={`/admin/edit/${currentState.aplName}`}
                                UserLink={`/admin/view/${currentState.aplName}`}
                                Inside={"Application"}
                                deleteApi={deleteApplication}
                                EditMode={false}
                              />
                            )}
                          </div>
                        );
                      }
                    })}
                  </div> */}
                  <div style={{ padding: "1.2rem" }}>
                    <Grid container spacing={2} style={{ padding: "1rem" }}>
                      <Grid item xs={5} sm={4} md={2}>
                        <Card
                          style={{
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            borderRadius: "13px",
                            cursor: "pointer",
                          }}
                          onClick={addApplicationHandler}
                        >
                          <CardContent style={{ textAlign: "center" }}>
                            <AddOutlined
                              style={{ color: "#03bfeb", fontSize: "4rem" }}
                            />
                            <Typography variant="h5" color="#03bfeb">
                              Add Application
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>

                      {applicationName.map((item, index) => {
                        if (item.status === 1) {
                          return (
                            <Grid item xs={5} sm={4} md={2} key={index}>
                              <div key={index}>
                                <CardModel
                                  ReducerFunction={application}
                                  item={item}
                                  navigate={`/admin/view/${item.aplName}`}
                                  edit={`/admin/view/${item.aplName}/?edit=true`}
                                  id={item._id}
                                  updatedDate={item.updated_at}
                                  Name={item.aplName}
                                  Icon={item.aplLogo}
                                  tags={item.tags}
                                  BottomClicked={
                                    passBottomSheet
                                      ? bottomClickHandler
                                      : undefined
                                  }
                                  Application={true}
                                  EditMode={false}
                                />
                                {/* {isCurrentStateAvailable && (
                                  <BottomSheets
                                    Details={currentState}
                                    updateLink={updateApplicationData}
                                    bottomClicked={bottomClicked}
                                    ReducerFunction={application}
                                    bottomClickHandler={bottomClickHandler}
                                    editorLink={`/admin/edit/${currentState.aplName}`}
                                    UserLink={`/admin/view/${currentState.aplName}`}
                                    Inside={"Application"}
                                    deleteApi={deleteApplication}
                                    EditMode={false}
                                  />
                                )} */}
                                {isCurrentStateAvailable && (
                                  <RightSideBar
                                    Details={currentState}
                                    updateLink={updateApplicationData}
                                    bottomClicked={bottomClicked}
                                    ReducerFunction={application}
                                    bottomClickHandler={bottomClickHandler}
                                    editorLink={`/admin/edit/${currentState.aplName}`}
                                    UserLink={`/admin/view/${currentState.aplName}`}
                                    Inside={"Application"}
                                    deleteApi={deleteApplication}
                                    EditMode={false}
                                  />
                                )}
                              </div>
                            </Grid>
                          );
                        }
                      })}
                    </Grid>
                  </div>

                  {/* <div
                    style={{
                      justifyContent: "center",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      className="addnewApplications"
                      onClick={addApplicationHandler}
                    >
                      <p className="applicationpara" id="applicationparaId">
                        <i id="APplus" className="fa-solid fa-plus fa-lg" /> Add
                        New Application
                      </p>
                    </div>
                    <div className="addnewApplicationsHr" />
                  </div> */}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={snackbar}
        autoHideDuration={4000}
        message={message}
        key={vertical + horizontal}
        style={{ backgroundColor: "white", color: "black" }}
      />
    </>
  );
}
