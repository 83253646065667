import React, { useEffect } from "react";
import { useState } from "react";
import "./Header.css";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import alphechLogo from "../../src/assets/logo.png";
import logoHorizontal from "../Images/logo_horizontal.png";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { CardMedia } from "@mui/material";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { applicationListByIdRole } from "../services/RestApi";

function Header({ collapse, state, isSidebarOpen, handleSearchChange }) {
  const location = useLocation().pathname.split("/");
  const navigate = useNavigate();
  const params = useParams();
  const [open, setOpen] = useState(false);
  const isInModules = params.moduleName ? true : false;
  const [applicationName, setApplicationName] = useState([]);

  const countApplication = () => {
    applicationListByIdRole({ id: localStorage.getItem("_id") }).then((res) => {
      if (res.status === 200) {
        const array = res.data.map((ele) => ele);
        setApplicationName(array);
      }
    });
  };

  useEffect(() => {
    countApplication();
  }, []);

  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    handleSearchChange(e.target.value);
  };

  const [dropdownVisible, setDropdownVisible] = useState(false);
  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const logout = () => {
    localStorage.clear();
    navigate("/Login");
  };

  return (
    <div className="header_navbar">
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {!state && params.moduleName && (
          <i
            id="sidebar_menu"
            className="fa-solid fa-bars"
            onClick={collapse}
          ></i>
        )}

        <img
          className="header_navbar_Appicon"
          src={logoHorizontal}
          style={{
            width: "200px",
            cursor: "pointer",
            visibility: isInModules && isSidebarOpen ? "hidden" : "",
          }}
          onClick={() => navigate("/admin")}
        />
      </div>

      <div>
        <Autocomplete
          className="header_navbar_search"
          options={applicationName}
          getOptionLabel={(option) => option.aplName}
          renderInput={(params) => <TextField {...params} label="Search" />}
          renderOption={(props, option) => (
            <li {...props} key={option.id} onClick={() => option.aplName}>
              {option.aplName}
            </li>
          )}
        />
      </div>

      <div className="header_navbar_autodiv">
        <Tooltip title="Notification">
          <i className="fa-regular fa-bell" id="header_bell_icon"></i>
        </Tooltip>
        <Tooltip title="Settings">
          <i
            className="fa-solid fa-gear"
            id="header_gear_logo"
            onClick={() => navigate("/admin/settings")}
          ></i>
        </Tooltip>

        <span onClick={() => navigate("/admin/userprofile")}>
          <Tooltip title="User Profile">
            <CardMedia
              component="img"
              height={30}
              style={{ marginLeft: ".3rem", borderRadius: "10%" }}
              image={`https://ui-avatars.com/api/?background=03bfeb&color=fff&name=hament`}
              alt="Name"
            />
            {/* <img className="image-container"
              src={profilePic}
              style={{ width: "34px", borderRadius: 5, marginLeft: "10px", cursor: "pointer" }}
            // onClick={(toggleDropdown)}
            ></img> */}
          </Tooltip>
        </span>

        {/* {dropdownVisible && (
          <div className="dropdown">
            <div onClick={toggleDropdown}></div>
            <div >
              <MenuList>
                <MenuItem onClick={()=>{ 
                  setDropdownVisible(false);
                  navigate("/admin/team")} }>
                  <ListItemText  inset>Team Manager</ListItemText>
                </MenuItem>
                <MenuItem onClick={()=>{
                  setDropdownVisible(false);
                  navigate("/admin/addteam")} }>
                  <ListItemText inset>Add Team</ListItemText>
                </MenuItem>
              </MenuList>
            </div>
          </div>
        )} */}

        <Tooltip title="Logout">
          <i
            className="fa-solid fa-right-from-bracket"
            id="header_logout"
            onClick={logout}
          ></i>
        </Tooltip>
        {/* <img src={props.src} className="header_navbar_user1" /> */}
        {/* <span className="header_navbar_modulename">
          {props.moduleName}
          {props.moduleName == undefined ? <span>Dynamic Panel</span> : ""}
        </span> */}
      </div>
      {/* <TeamManage 
      open={open}
      setOpen={setOpen}
      /> */}
    </div>
  );
}

export default Header;
