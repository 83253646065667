import React, { useState } from 'react';
import SettingSidebar from './SettingSidebar';
import PopupModule from './PopupModule';
import SearchBar from './Searchbar';
import SettingTable from './SettingTable';
import ContentHeader from '../components/ContentHeader';
import { useLocation } from 'react-router-dom';

const MemberScreen = () => {
  const handleReload = () => {
    window.location.reload();
  };

  const location = useLocation();
  const item = location.state;
  const appName = item.item.aplName;

  const [appId, setAppId] = useState({
    aplName: '',
    moduleName: appName,
    aplDescription: '',
    moduleDescription: '',
  });
  const [settings, setSettings] = useState(appName);
  const [searchKeyword, setSearchKeyword] = useState('');
  const handleSearch = (searchTerm) => {
    setSearchKeyword(searchTerm);
  };

  return (
    <div style={{ display: 'flex', alignItems: 'top', justifyContent: 'top' }}>
      <SettingSidebar />
      <div style={{ width: '85%', alignItems: 'center', justifyContent: 'center' }}>
        <ContentHeader appId={appId} applicationName={settings} />
        <div style={{ display: 'flex' }}>
          <SearchBar onSearch={handleSearch} />
          <PopupModule handleReload={handleReload} />
        </div>
        <div style={{ padding: '12px', color: 'white', backgroundColor: 'rgb(91, 192, 222)', width: '10%' }}>
          Members : {item.item.team.length}
        </div>
        <SettingTable searchKeyword={searchKeyword} />
      </div>
    </div>
  );
};

export default MemberScreen;
