import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { Button, Card, CardContent, IconButton, Snackbar } from "@mui/material";
import { AttachFile, ContentCopy, Download } from "@mui/icons-material";
import { horizontal, vertical } from "../Login";
import { mt } from "date-fns/locale";
import axios from "axios";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useParams } from "react-router-dom";
import { baseUrl } from "../services/RestApi";
import { useSelector } from "react-redux";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "16px",
  p: 4,
  minHeight: "500px",
};

export default function CodePopUpModal({
  openedStatus,
  toggleHandler,
  dataUrl,
  subMod,
  response,
}) {
  const jsonData = {
    object: {
      slug: "hello",
      title: "Hello",
      metadata: {
        title: null,
      },
    },
  };
  const textRef = React.useRef(null);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [isRestSelected, setIsRestSelected] = React.useState(true);
  const { applicationName, moduleName, submoduleName } = useParams();

  const appID = useSelector((state) => {
    return state.admin.applicationArray;
  });

  const appId = appID.find((ele) => ele.aplName === applicationName);

  console.log("Appp Id ----", appId._id);

  const handleClose = () => {
    setOpenSnackbar(false);
  };

  console.log("Response ob", response);

  const handleCopyClick = () => {
    if (textRef.current) {
      const textToCopy = textRef.current.innerText;
      navigator.clipboard.writeText(textToCopy).then(() => {
        setOpenSnackbar(true);
      });
    }
  };
  const toggleModal = () => {
    toggleHandler(false);
  };

  const handleDownload = async () => {
    try {
      const response = await axios.get(`${baseUrl}/generateAPI/${appId._id}`);

      if (response.status === 200) {
        console.log("Download Started");
      }
    } catch (error) {
      if (error.response) {
        console.error(
          "Download Failed:",
          error.response.status,
          error.response.data
        );
      } else if (error.request) {
        console.error("No response received:", error.request);
      } else {
        console.error("Request setup error:", error.message);
      }
    }
  };

  return (
    <div>
      <Modal
        open={openedStatus}
        onClose={toggleModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              justifyContent: "space-between",
              flexDirection: "row",
              display: "flex",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography
                variant="h6"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Developer Tools
              </Typography>
              <Typography
                sx={{ display: "flex", alignItems: "center", ml: 5 }}
                fontSize={15}
              >
                {applicationName}
                <ArrowForwardIosIcon fontSize="5px" sx={{ mx: 1 }} />
                {moduleName}
                <ArrowForwardIosIcon fontSize="5px" sx={{ mx: 1 }} />
                {submoduleName}
              </Typography>
            </div>
            <CloseIcon
              onClick={toggleModal}
              fontSize="large"
              style={{ cursor: "pointer" }}
            ></CloseIcon>
          </Box>
          <Typography variant="h6" sx={{ mt: 4 }}>
            Use the methods below to integrate {subMod} into your website or
            application.
          </Typography>
          <Box sx={{ mt: 2 }}>
            <Button
              variant={isRestSelected ? "contained" : "outlined"}
              onClick={() => setIsRestSelected(true)}
              startIcon={<AttachFile />}
            >
              Rest
            </Button>
            <Button
              variant={isRestSelected ? "outlined" : "contained"}
              onClick={() => setIsRestSelected(false)}
              startIcon={<Download />}
              sx={{ ml: 1 }}
            >
              Download
            </Button>
            {isRestSelected ? (
              <Box
                sx={{
                  position: "relative",
                  mt: 3,
                  border: "1px solid grey",
                  borderRadius: "5px",
                  p: 1,
                }}
              >
                <Typography
                  variant="h6"
                  style={{
                    overflowX: "auto",
                    whiteSpace: "nowrap",
                    maxWidth: "100%",
                  }}
                  ref={textRef}
                >
                  {dataUrl}
                </Typography>
                <Button
                  variant="contained"
                  onClick={handleCopyClick}
                  startIcon={<ContentCopy />}
                  style={{
                    cursor: "pointer",
                    position: "absolute",
                    right: "0%",
                    transform: "translate(-10%, -97%)",
                  }}
                >
                  Copy
                </Button>
              </Box>
            ) : (
              <Box sx={{ mt: 3 }}>
                <Typography
                  variant="h6"
                  style={{
                    overflowX: "auto",
                    whiteSpace: "nowrap",
                    maxWidth: "100%",
                  }}
                >
                  Download api code to run locally or deploy to own server
                </Typography>
                <IconButton onClick={handleDownload}>
                  <Download fontSize="inherit" />
                </IconButton>
              </Box>
            )}
          </Box>
          <Typography variant="h6" sx={{ mt: 2 }}>
            Response
          </Typography>
          <div
            style={{
              marginTop: "5px",
              border: "1px solid grey",
              borderRadius: "10px",
              padding: "10px",
              backgroundColor: "#F5F4F5",
            }}
          >
            {/* {`${response}`} */}
            <pre>{response}</pre>
          </div>
        </Box>
      </Modal>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={openSnackbar}
        onClose={handleClose}
        autoHideDuration={3000}
        message="Link Copied"
        key={vertical + horizontal}
      />
    </div>
  );
}
