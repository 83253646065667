import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
    name:"application",
    initialState:{
        moduleArray:[],
        submoduleArray:[]
    },
    reducers:{
        module(state, action){
            state.moduleArray=action.payload
        },
        submodule(state, action){
            state.submoduleArray=action.payload
        }
    }
    
})
export const {module, submodule}=userSlice.actions
export default userSlice.reducer