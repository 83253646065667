import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Tooltip from '@mui/material/Tooltip';
import { ModifyApplicationAccessPost } from '../services/RestApi';
import DeletePopup from './DeletePopup';

const options = [
  'Remove Member',
];

const ITEM_HEIGHT = 30;

function LongMenu({user,app_id, handleReload}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeTooltip = () =>{
    setAnchorEl(false)

  }

  const handleClose = () => {
   if(user.role!=="Owner")
   { const inviteData = {
      email : user.email,
      app_id,
      source_id: user.source,
      role : user.role,
      remove:true
    };
    ModifyApplicationAccessPost(inviteData)
    .then((res) => {
      setAnchorEl(null);
      handleReload()
    });}
    
  };

  return (
    <div>
      <Tooltip >
        <IconButton>

          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="long-menu"
            MenuListProps={{
              'aria-labelledby': 'long-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={closeTooltip}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                width: '182px',
              },
            }}
          >
            {options.map((option) => (
              <MenuItem key={option} selected={option === 'Pyxis'} >
                <DeletePopup user={user} app_id={app_id} />
              </MenuItem>
            ))}
          </Menu>

        </IconButton>
      </Tooltip>
    </div>
  );
}

export default LongMenu;