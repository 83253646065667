import { useLocation, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  addProp,
  addProperties,
  fetchSubModuleListById,
  updateProperties,
} from "../services/RestApi";
import { useSelector } from "react-redux";
import LoadingModal from "../components/LoadingModal";
import MiddleForm from "../admin/FormBuilder/MiddleForm";
import { TextField, Typography } from "@mui/material";
import Footer from "../components/Footer";

const ViewEditDetails = () => {
  let localForm = JSON.parse(localStorage.getItem("formElements"));
  const [text, setText] = useState({});
  const [showLoading, setShowLoading] = useState(true);
  const { applicationName, moduleName, subModuleName } = useParams();
  const [formElementsList, setFormElementsList] = useState(localForm);

  const { submoduleName } = useParams();

  const location = useLocation();
  const data = location.state.data;
  const isView = location.state.isView;
  console.log("Paramss000-->", useParams());
  console.log("Loca000-->", location);

  //   const data = {
  //     email: "vishal.fun97@gmail.com",
  //     phone: "9958702183",
  //     shortText: "MBBS From AIIMS",
  //     longText:
  //       "Persuing MBBS from AIIMS and currently working with Apollo Hospitals",
  //     dropdown: "Internship",
  //     singleChoice: "<18 Yrs",
  //     multiselect: [
  //       {
  //         key: "47dff579-c139-41b4-ac81-421bb519b5c1",
  //         label: "Bike",
  //       },
  //       {
  //         key: "38adf3cc-ed3d-4c19-b533-8178b6fa2234",
  //         label: "Car",
  //       },
  //       {
  //         key: "3e8da973-08e3-42db-98f2-8ebffaaa61cb",
  //         label: "Cycle",
  //       },
  //     ],
  //   };

  const [formSubmitted, setFormSubmitted] = useState(false);

  const subModuleDetail = useSelector((state) => {
    return state.application.submoduleArray;
  });

  useEffect(() => {
    setShowLoading(false);
  }, []);

  useEffect(() => {
    setShowLoading(true);
    setTimeout(() => {
      fetchSubModuleListById({ id: subModuleDetail._id }).then((res) => {
        let initialForm = res.data[0].subModuleProperties;
        for (let item of initialForm) {
          const { field, type } = item;
          if (field in data) {
            item.value = data[field];
          }
        }
        console.log("Finallll", initialForm);
        setFormElementsList(initialForm);
        setShowLoading(false);
      });
    }, 500);
  }, [location]);

  const isValidated = () => {
    let canSubmit = true;
    for (let item of formElementsList) {
      item.hasError = false;
      if (typeof item.value === "object" && item.isRequired) {
        if (item.value.length > -1) {
          if (item.value.length === 0) {
            item.hasError = true;
            item.errorMsg = `${item.label} is required field`;
            canSubmit = false;
            break;
          }
        } else {
          for (let i of Object.entries(item.value)) {
            if (!i[1]) {
              item.hasError = true;
              item.errorMsg = `Fill all fields of ${item.label}`;
              canSubmit = false;
              break;
            }
          }
        }
      } else if (!item.value.length > 0 && item.isRequired) {
        canSubmit = false;
        item.hasError = true;
        item.errorMsg = `${item.label} is required field`;
      }
    }
    setFormElementsList([...formElementsList]);
    return canSubmit;
  };

  const previewSubmit = () => {
    if (isValidated()) {
      updateProperties({
        application: applicationName,
        module: moduleName,
        submodule: submoduleName,
        id: data.id,
        data: formElementsList,
      }).then((res) => {
        if (res.status === 200) {
          setFormSubmitted(true);
        }
      });
    }
  };

  // this has to be a state and set as setstate when api is linked for submit

  return (
    <>
      {showLoading ? (
        <LoadingModal />
      ) : (
        <div style={{ padding: "2%" }}>
          <Typography
            variant="h4"
            style={{
              padding: "10px 8px",
              borderBottom: "1px solid #00d1e4",
            }}
          >
            {isView ? "View Form" : "Edit Form"}
          </Typography>
          <div style={{ paddingBottom: "2%" }}>
            <MiddleForm
              formSubmitted={formSubmitted}
              formElementsList={formElementsList}
              setFormElementsList={(elements) => setFormElementsList(elements)}
              // checked={isView ? false : true}
              checked={true}
              isViewing={isView}
              previewSubmit={previewSubmit}
            />
          </div>
        </div>
      )}
      {/* <Footer /> */}
    </>
  );
};

export default ViewEditDetails;
