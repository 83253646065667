import React from "react";
import { useNavigate} from "react-router-dom";
import GroupsIcon from '@mui/icons-material/Groups';
import { HomeOutlined } from "@mui/icons-material";
import "./SettingSidebar.css"

function SettingSidebar() {
  let navigate = useNavigate();

  return (
    <>
      <div className="sidebar_settings">

        <div className="sidebar_flex" onClick={() => navigate("/admin")}>
          <HomeOutlined style={{ marginRight: "5px" }} fontSize="small" />
          <p className="sidebar_profile_para">Home</p>
        </div>

        <div className="sidebar_flex" style={{backgroundColor:"rgb(91, 192, 222)" , color:"white"}}>
          <GroupsIcon style={{ marginRight: "5px" }} fontSize="small" />
          <p className="sidebar_profile_para">Members</p>
        </div>
        
      </div>
    </>
  );
}

export default SettingSidebar;
