import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "./ViewSubmodule.css";
import subModules from "../utils/subModule.json";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import PopUpModal from "./PopUpModal";
import { Box, Button, Card, IconButton, Typography } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import { useState } from "react";
import { videosRow } from "../utils/tableData";
import { useEffect } from "react";
import {
  baseUrl,
  deleteProperties,
  fetchSubModuleListById,
  viewProp,
  viewProperties,
} from "../services/RestApi";
import { useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import LoadingModal from "../components/LoadingModal";
import Footer from "../components/Footer";
import { Code } from "@mui/icons-material";
import CodePopUpModal from "./CodePopupModal";

const rows1 = [
  {
    id: 1,
    name: "Alex",
    email: "alex@gmail.com",
    profile:
      "https://firebasestorage.googleapis.com/v0/b/beyond-education.appspot.com/o/files%2FClass%2010.png?alt=media&token=0ba98235-c53b-4da5-92cb-d5f27307b3e3",
    phone: "9865676567",
    gender: "Male",
    languages: ["English", "French", "German"],
    file: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    multipleFiles: [
      "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
      "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
      "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    ],
    about:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer ullamcorper lobortis faucibus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi volutpat venenatis ultrices. Ut at sapien euismod, ornare est sed, vestibulum neque. Nam venenatis eget odio quis rhoncus. Proin nulla risus, porta at convallis et, posuere ut turpis.",
    skills: ["Java", "Python"],
  },
  {
    id: 2,
    name: "Newton",
    email: "newton@gmail.com",
    profile:
      "https://firebasestorage.googleapis.com/v0/b/beyond-education.appspot.com/o/files%2FClass%2010.png?alt=media&token=0ba98235-c53b-4da5-92cb-d5f27307b3e3",
    phone: "6787621234",
    gender: "Male",
    languages: ["English", "Hindi"],
    file: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    multipleFiles: [
      "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
      "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
      "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    ],
    about:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer ullamcorper lobortis faucibus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi volutpat venenatis ultrices. Ut at sapien euismod, ornare est sed, vestibulum neque. Nam venenatis eget odio quis rhoncus. Proin nulla risus, porta at convallis et, posuere ut turpis.",
    skills: ["javascript", "perl"],
  },
  {
    id: 3,
    name: "Sachin",
    email: "sachin@gmail.com",
    gender: "Male",
    profile:
      "https://firebasestorage.googleapis.com/v0/b/beyond-education.appspot.com/o/files%2FClass%2010.png?alt=media&token=0ba98235-c53b-4da5-92cb-d5f27307b3e3",
    phone: "9986120952",
    languages: ["English", "Hindi"],
    file: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    multipleFiles: [
      "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
      "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
      "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    ],
    skills: ["basic", "ruby"],
    about:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer ullamcorper lobortis faucibus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi volutpat venenatis ultrices. Ut at sapien euismod, ornare est sed, vestibulum neque. Nam venenatis eget odio quis rhoncus. Proin nulla risus, porta at convallis et, posuere ut turpis.",
  },
  {
    id: 4,
    name: "Amit",
    email: "amit@gmail.com",
    languages: ["Hindi", "French"],
    file: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    multipleFiles: [
      "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
      "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
      "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    ],
    profile:
      "https://firebasestorage.googleapis.com/v0/b/beyond-education.appspot.com/o/files%2FClass%2010.png?alt=media&token=0ba98235-c53b-4da5-92cb-d5f27307b3e3",
    phone: "9987123054",
    gender: "Male",
    skills: ["react", "node"],
    about:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer ullamcorper lobortis faucibus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi volutpat venenatis ultrices. Ut at sapien euismod, ornare est sed, vestibulum neque. Nam venenatis eget odio quis rhoncus. Proin nulla risus, porta at convallis et, posuere ut turpis.",
  },
  {
    id: 5,
    name: "Ram",
    email: "ram@gmail.com",
    profile:
      "https://firebasestorage.googleapis.com/v0/b/beyond-education.appspot.com/o/files%2FClass%2010.png?alt=media&token=0ba98235-c53b-4da5-92cb-d5f27307b3e3",
    phone: "9912729567",
    gender: "Male",
    languages: ["English", "German"],
    file: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    multipleFiles: [
      "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
      "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
      "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    ],
    skills: ["data science", "python"],
    about:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer ullamcorper lobortis faucibus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi volutpat venenatis ultrices. Ut at sapien euismod, ornare est sed, vestibulum neque. Nam venenatis eget odio quis rhoncus. Proin nulla risus, porta at convallis et, posuere ut turpis.",
  },
  {
    id: 6,
    name: "Vishal",
    email: "vishal@gmail.com",
    profile:
      "https://firebasestorage.googleapis.com/v0/b/beyond-education.appspot.com/o/files%2FClass%2010.png?alt=media&token=0ba98235-c53b-4da5-92cb-d5f27307b3e3",
    phone: "9965120867",
    gender: "Male",
    languages: ["French", "German"],
    file: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    multipleFiles: [
      "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
      "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
      "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    ],
    skills: ["react", "python"],
    about:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer ullamcorper lobortis faucibus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi volutpat venenatis ultrices. Ut at sapien euismod, ornare est sed, vestibulum neque. Nam venenatis eget odio quis rhoncus. Proin nulla risus, porta at convallis et, posuere ut turpis.",
  },
  {
    id: 7,
    name: "Megha",
    email: "megha@gmail.com",
    profile:
      "https://firebasestorage.googleapis.com/v0/b/beyond-education.appspot.com/o/files%2FClass%2010.png?alt=media&token=0ba98235-c53b-4da5-92cb-d5f27307b3e3",
    phone: "6787621234",
    languages: ["English", "Hindi"],
    file: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    multipleFiles: [
      "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
      "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
      "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    ],
    gender: "Female",
    skills: ["php", "ruby"],
    about:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer ullamcorper lobortis faucibus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi volutpat venenatis ultrices. Ut at sapien euismod, ornare est sed, vestibulum neque. Nam venenatis eget odio quis rhoncus. Proin nulla risus, porta at convallis et, posuere ut turpis.",
  },
  {
    id: 8,
    name: "Rishabh",
    email: "rishabh@gmail.com",
    profile:
      "https://firebasestorage.googleapis.com/v0/b/beyond-education.appspot.com/o/files%2FClass%2010.png?alt=media&token=0ba98235-c53b-4da5-92cb-d5f27307b3e3",
    phone: "9986120952",
    languages: ["Hindi"],
    file: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    multipleFiles: [
      "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
      "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
      "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    ],
    gender: "Male",
    skills: ["java", "C++"],
    about:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer ullamcorper lobortis faucibus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi volutpat venenatis ultrices. Ut at sapien euismod, ornare est sed, vestibulum neque. Nam venenatis eget odio quis rhoncus. Proin nulla risus, porta at convallis et, posuere ut turpis.",
  },
  {
    id: 9,
    name: "Prashant",
    email: "prashant@gmail.com",
    profile:
      "https://firebasestorage.googleapis.com/v0/b/beyond-education.appspot.com/o/files%2FClass%2010.png?alt=media&token=0ba98235-c53b-4da5-92cb-d5f27307b3e3",
    phone: "9987123054",
    languages: ["English", "German"],
    file: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    multipleFiles: [
      "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
      "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
      "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    ],
    gender: "Male",
    skills: ["Excel", "GoLang"],
    about:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer ullamcorper lobortis faucibus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi volutpat venenatis ultrices. Ut at sapien euismod, ornare est sed, vestibulum neque. Nam venenatis eget odio quis rhoncus. Proin nulla risus, porta at convallis et, posuere ut turpis.",
  },
  {
    id: 10,
    name: "Mannu",
    email: "Mannu@gmail.com",
    profile:
      "https://firebasestorage.googleapis.com/v0/b/beyond-education.appspot.com/o/files%2FClass%2010.png?alt=media&token=0ba98235-c53b-4da5-92cb-d5f27307b3e3",
    phone: "9912729567",
    languages: ["English", "Hindi"],
    file: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    multipleFiles: [
      "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
      "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
      "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    ],
    gender: "Male",
    skills: ["java", "python"],
    about:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer ullamcorper lobortis faucibus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi volutpat venenatis ultrices. Ut at sapien euismod, ornare est sed, vestibulum neque. Nam venenatis eget odio quis rhoncus. Proin nulla risus, porta at convallis et, posuere ut turpis.",
  },
];

export default function ViewSubmodule() {
  const navigateTo = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCodeModalOpen, setIsCodeModalOpen] = useState(false);
  const [modalData, setModalData] = React.useState();
  const [showLoading, setShowLoading] = useState(true);
  const location = useLocation();
  const { applicationName, moduleName, submoduleName } = useParams();
  const [rows, setRows] = [];
  const [newRows, setNewRows] = useState([]);
  const [formElementsList, setFormElementsList] = useState([]);
  const [responseData, setResponseData] = React.useState([]);
  const subModuleDetail = useSelector((state) => {
    return state.application.submoduleArray;
  });

  const modifyURL = (url) => {
    const urlParts = url.split("/");

    for (let i = 3; i < urlParts.length; i++) {
      urlParts[i] = encodeURI(urlParts[i]);
    }

    urlParts.push("read");

    return urlParts.join("/");
  };

  const encodedRestUrl = modifyURL(
    `${baseUrl}/${applicationName}/${moduleName}/${submoduleName}`
  );

  console.log("Base urllllll modify", encodedRestUrl);

  const handleView = (row) => {
    console.log("Row", row);
    navigateTo(
      `/admin/view/${applicationName}/${moduleName}/${submoduleName}/view`,
      { state: { data: row, isView: true } }
    );
  };
  const handleEdit = (row) => {
    console.log("Row", row);
    navigateTo(
      `/admin/view/${applicationName}/${moduleName}/${submoduleName}/edit`,
      { state: { data: row, isView: false } }
    );
  };

  console.log("formElementsList --->", formElementsList);

  const convertFormElements = (arr) => {
    const convertedForm = arr.map((item) => {
      let multipleDetails = { ...item };
      if (item.fullName) {
        const fullName = `${item.fullName.firstName} ${item.fullName.secondName}`;
        multipleDetails = { ...multipleDetails, fullName };
      }
      if (item.address) {
        const address = `${item.address.streetAddress}, ${item.address.streetAddressLine2}, ${item.address.city}, ${item.address.state}, ${item.address.zipCode}`;
        multipleDetails = { ...multipleDetails, address };
      }
      return multipleDetails;
    });

    return convertedForm;
  };

  const transformData = (data) => {
    return data.map(function (item) {
      // Create a copy of the object
      var transformedItem = { ...item };

      // Remove __v property
      delete transformedItem.__v;

      // Change _id to id
      if (transformedItem._id) {
        transformedItem.id = transformedItem._id;
        delete transformedItem._id;
      }

      return transformedItem;
    });
  };

  function formatDateTime(dateTime) {
    const date = new Date(dateTime.toString());
    return `${
      date.getDate().toString().length === 1
        ? "0" + date.getDate().toString()
        : date.getDate().toString()
    }-${date.getMonth() + 1}-${date.getFullYear()} | ${date.getHours()}:${
      date.getMinutes().toString().length === 1
        ? "0" + date.getMinutes().toString()
        : date.getMinutes().toString()
    }`;
  }

  const handleDelete = async (row) => {
    try {
      const res = await deleteProperties({
        application: applicationName,
        module: moduleName,
        submodule: submoduleName,
        id: row.id,
        data: formElementsList,
      });

      console.log(res);

      if (res.status === 200) {
        alert("Deleted Successfully!!!");
      } else {
        // Handle other status codes or errors as needed
        alert("Deletion failed.");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setShowLoading(true);

      try {
        const subModuleResponse = await fetchSubModuleListById({
          id: subModuleDetail._id,
        });
        const viewPropertiesResponse = await viewProperties({
          application: applicationName,
          module: moduleName,
          submodule: submoduleName,
          read: "read",
        });

        console.log(
          "Submodule Api Data",
          subModuleResponse.data[0].subModuleProperties
        );
        const formObj = subModuleResponse.data[0].subModuleProperties;
        setFormElementsList(formObj);

        const newData = {};

        formObj.forEach((item) => {
          newData[item.field] = item.dataType;
        });

        setResponseData(newData);
        const newArray = transformData(viewPropertiesResponse.data);
        console.log("Old Array--->", newArray);
        const finalRows = convertFormElements(newArray);
        console.log("New Array--->", finalRows);
        setNewRows(finalRows);
      } catch (error) {
        console.error("An error occurred:", error);
      } finally {
        setShowLoading(false);
      }
    };

    fetchData();
  }, [location]);

  console.log("Formmm Elements --->", formElementsList);

  const columns = formElementsList.map((item, index) => {
    return {
      key: item.key,
      field: item.field,
      width: 150,
      headerName: item.label,
      sortable: true,
      renderCell: (props) => {
        if (item.type === "image") {
          return (
            <img
              src={props.value}
              style={{ width: "100px", height: "65px", cursor: "pointer" }}
              alt=""
              onClick={() => {
                setIsModalOpen(true);
                setModalData({ title: item.label, image: props.value });
              }}
            />
          );
        } else if (item.type === "text") {
          if (props?.value.length > 20) {
            const output = props.value.slice(0, 17);
            return (
              <div>
                <div>{`${output}...`}</div>
                <div
                  style={{ cursor: "pointer", color: "#5bc0deff" }}
                  onClick={() => {
                    setIsModalOpen(true);
                    setModalData({ title: item.label, fullText: props.value });
                  }}
                >
                  View More
                </div>
              </div>
            );
          } else {
            return <p>{props.value}</p>;
          }
        } else if (item.type === "singleChoice") {
          const output = props.value;

          if (props?.value.length > 20) {
            const output = props.value.slice(0, 17);
            return (
              <div>
                <div>{`${output}...`}</div>
                <div
                  style={{ cursor: "pointer", color: "#5bc0deff" }}
                  onClick={() => {
                    setIsModalOpen(true);
                    setModalData({ title: item.label, fullText: props.value });
                  }}
                >
                  View More
                </div>
              </div>
            );
          } else {
            return <p>{props.value}</p>;
          }
        } else if (item.type === "email") {
          if (props?.value.length > 20) {
            const output = props.value.slice(0, 17);
            return (
              <div>
                <div>{`${output}...`}</div>
                <div
                  style={{ cursor: "pointer", color: "#5bc0deff" }}
                  onClick={() => {
                    setIsModalOpen(true);
                    setModalData({ title: item.label, fullText: props.value });
                  }}
                >
                  View More
                </div>
              </div>
            );
          } else {
            return <p>{props.value}</p>;
          }
        } else if (item.type === "phone") {
          if (props?.value.length > 11) {
            const output = props.value.slice(0, 10);
            return (
              <div>
                <div>{`${output}...`}</div>
                <div
                  style={{ cursor: "pointer", color: "#5bc0deff" }}
                  onClick={() => {
                    setIsModalOpen(true);
                    setModalData({ title: item.label, fullText: props.value });
                  }}
                >
                  View More
                </div>
              </div>
            );
          } else {
            return <p>{props.value}</p>;
          }
        } else if (item.type === "fullName") {
          if (props?.value.length > 20) {
            const output = props.value.slice(0, 17);
            return (
              <div>
                <div>{`${output}...`}</div>
                <div
                  style={{ cursor: "pointer", color: "#5bc0deff" }}
                  onClick={() => {
                    setIsModalOpen(true);
                    setModalData({ title: item.label, fullText: props.value });
                  }}
                >
                  View More
                </div>
              </div>
            );
          } else {
            return <p>{props.value}</p>;
          }
        } else if (item.type === "address") {
          if (props?.value.length > 20) {
            const output = props.value.slice(0, 17);
            return (
              <div>
                <div>{`${output}...`}</div>
                <div
                  style={{ cursor: "pointer", color: "#5bc0deff" }}
                  onClick={() => {
                    setIsModalOpen(true);
                    setModalData({ title: item.label, fullText: props.value });
                  }}
                >
                  View More
                </div>
              </div>
            );
          } else {
            return <p>{props.value}</p>;
          }
        } else if (item.type === "number") {
          return <p>{props.value}</p>;
        } else if (item.type === "description") {
          if (props?.value.length > 20) {
            const output = props.value.slice(0, 17);
            return (
              <div>
                <div>{`${output}...`}</div>
                <div
                  style={{ cursor: "pointer", color: "#5bc0deff" }}
                  onClick={() => {
                    setIsModalOpen(true);
                    setModalData({ title: item.label, fullText: props.value });
                  }}
                >
                  View More
                </div>
              </div>
            );
          } else {
            return <p>{props.value}</p>;
          }
        } else if (item.type === "multiselect") {
          let outputArray = props.value.map((item) => item.label);
          const output = outputArray.join(", ");

          if (output.length > 20) {
            return (
              <div>
                <div>{`${output.slice(0, 17)}...`}</div>
                <div
                  style={{ cursor: "pointer", color: "#5bc0deff" }}
                  onClick={() => {
                    setIsModalOpen(true);
                    setModalData({ title: item.label, fullText: output });
                  }}
                >
                  View More
                </div>
              </div>
            );
          } else {
            return <p>{output}</p>;
          }
        } else if (item.type === "dropdown") {
          return <p>{props.value}</p>;
        } else if (item.type === "checkbox") {
          const output = props.value.join(item.separator);

          if (output.length > 20) {
            return (
              <div>
                <div>{`${output.slice(0, 17)}...`}</div>
                <div
                  style={{ cursor: "pointer", color: "#5bc0deff" }}
                  onClick={() => {
                    setIsModalOpen(true);
                    setModalData({ title: item.label, fullText: output });
                  }}
                >
                  View More
                </div>
              </div>
            );
          } else {
            return <p>{output}</p>;
          }
        } else if (item.type === "file") {
          return (
            <Button
              variant="outlined"
              size="small"
              endIcon={<DownloadIcon />}
              onClick={() => window.open(props.value)}
            >
              Download
            </Button>
          );
        } else if (item.type === "multipleFiles") {
          return (
            <Button
              variant="outlined"
              size="small"
              endIcon={<DownloadIcon />}
              onClick={() => {
                setIsModalOpen(true);
                setModalData(props.value);
              }}
            >
              Download
            </Button>
          );
        }
      },
    };
  });

  columns.unshift({
    field: "serialNumber",
    headerName: "S. No",
    width: 40,
    renderCell: (props) => {
      const rowIndex = newRows.findIndex((row) => row.id === props.row.id);
      return (
        <div style={{ display: "flex", justifyContent: "center" }}>
          {rowIndex + 1}
        </div>
      );
    },
  });

  columns.unshift({
    field: "action",
    headerName: "Actions",
    width: 90,
    sortable: true,
    renderCell: ({ row }) => {
      return (
        <div style={{ justifyContent: "space-between" }}>
          <VisibilityIcon
            style={{ color: "#50C878", cursor: "pointer" }}
            onClick={() => handleView(row)}
          />
          <EditIcon
            style={{ color: "#4169E1", cursor: "pointer" }}
            onClick={() => handleEdit(row)}
          ></EditIcon>
          <DeleteIcon
            style={{ color: "#D22B2B" }}
            onClick={() => handleDelete(row)}
          ></DeleteIcon>
        </div>
      );
    },
  });

  columns.push({
    field: "createdBy",
    headerName: "Created By",
    width: 150,
    renderCell: ({ row }) => {
      if (row.createdBy.length > 16) {
        const output = row.createdBy.slice(0, 14);
        return (
          <div>
            <div>{`${output}...`}</div>
            <div
              style={{ cursor: "pointer", color: "#5bc0deff" }}
              onClick={(event) => {
                event.preventDefault();
                setIsModalOpen(true);
                setModalData({ title: "Created By", fullText: row.createdBy });
              }}
            >
              View More
            </div>
          </div>
        );
      } else {
        return <p>{row.createdBy}</p>;
      }
    },
  });

  columns.push({
    field: "createdAt",
    headerName: "Created At",
    width: 170,
    renderCell: ({ row }) => (
      <div style={{ display: "flex", justifyContent: "center" }}>
        {`${formatDateTime(row.createdAt)}`}
      </div>
    ),
  });

  return (
    <>
      {showLoading ? (
        <LoadingModal />
      ) : (
        <Card style={{ padding: "15px", margin: "15px" }}>
          {/* <div className="ViewSubmodule_user">{currentSubModule.subModuleName}</div> */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography>{submoduleName}</Typography>
            <IconButton onClick={() => setIsCodeModalOpen(true)}>
              <Code />
            </IconButton>
          </Box>
          <div style={{ height: "74vh", marginTop: "10px" }}>
            <DataGrid
              rows={newRows}
              columns={columns}
              pageSize={5}
              rowsPerPageOptions={[5]}
              rowHeight={80}
            />
          </div>
          {isModalOpen && (
            <PopUpModal
              dataObject={modalData}
              openedStatus={isModalOpen}
              toggleHandler={setIsModalOpen}
            />
          )}
          {isCodeModalOpen && (
            <CodePopUpModal
              toggleHandler={setIsCodeModalOpen}
              openedStatus={isCodeModalOpen}
              dataUrl={encodedRestUrl}
              subMod={submoduleName}
              response={JSON.stringify(responseData, null, 2)}
            />
          )}
        </Card>
      )}
      {/* <Footer subModuleFooter={true}  /> */}
    </>
  );
}
