import React, { useEffect } from "react";
import "./Dashboard.css";
import submodules from "../utils/subModule.json";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { submodule } from "../Store/Slices/UserSlice";
import { fetchSubModuleByModuleId } from "../services/RestApi";
import LoadingModal from "./LoadingModal";
import ContentHeader from "./ContentHeader";
import CardModel from "./CardModel";
import { AddOutlined } from "@mui/icons-material";
import { Card, CardContent, Grid } from "@mui/material";
import { v4 } from "uuid";
import Footer from "./Footer";

function Dashboard() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { applicationName, moduleName } = useParams();
  const currentModule = useSelector((state) => {
    return state.application.moduleArray;
  });

  const [subModules, setSubModules] = useState([]);
  const [subModuleActive, setSubModuleActive] = useState(0);
  const [subModuleInactive, setSubmoduleInactive] = useState(0);
  const [state, setState] = useState(false);
  const [showLoading, setShowLoading] = useState(true);
  useEffect(() => {
    fetchSubModuleByModuleId({ moduleId: currentModule.moduleId })
      .then((res) => {
        if (res.status === 201) {
          setSubModules(res.data);
        }
        let activeCount = 0,
          inactiveCount = 0;
        res.data.map((ele, index) => {
          if (ele.status === 1) {
            activeCount++;
          } else if (ele.status === 0) {
            inactiveCount++;
          }
        });
        setSubModuleActive(activeCount);
        setSubmoduleInactive(inactiveCount);
        setShowLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const handleOnClickSubModule = (item, state) => {
    setState(true);
    if (state) {
      dispatch(submodule(item));
      let path = `/${currentModule.moduleName}/${item.subModuleName}/${state}`;
      navigate(path);
    }
  };
  return (
    <>
      {showLoading ? (
        <LoadingModal />
      ) : (
        <>
          <ContentHeader appId={currentModule} applicationName={moduleName} />
          <div style={{ padding: "1.1rem" }}>
            <div className="Dashboard">
              <Grid container spacing={2} style={{ padding: "1rem" }}>
                {subModules.map((item, index) => {
                  return (
                    <>
                      <div key={index} className="submodules">
                        <CardModel
                          item={item}
                          Name={item.subModuleName}
                          updatedDate={item.updated_at}
                          id={item._id}
                          Icon={item.subModuleIcon}
                          Application={true}
                          EditMode={false}
                        />
                      </div>
                    </>
                  );
                })}
              </Grid>
            </div>
          </div>
        </>
      )}
      {/* <Footer subModuleFooter={true}  /> */}
    </>
  );
}

export default Dashboard;
