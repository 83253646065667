import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Button, CardActionArea, CardActions } from "@mui/material";
import { useNavigate } from "react-router-dom/dist";
import DeleteIcon from "@mui/icons-material/Delete";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { deleteApplication } from "../services/RestApi";
import Snackbar from "@mui/material/Snackbar/Snackbar";
import { horizontal, vertical } from "../Login";
import { useDispatch, useSelector } from "react-redux";
import { getAccessFromRole } from "../utils/previlige";
import moment from "moment/moment";

function CardModel({
  Name,
  updatedDate,
  Icon,
  navigate,
  id,
  edit,
  item,
  tags,
  BottomClicked,
  ReducerFunction,
  Application,
  EditMode,
}) {
  const [message, setMessage] = useState("");
  const dispatch = useDispatch();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [tagString, setTagString] = useState("");
  const deleteHandler = () => {
    deleteApplication({ id: id }).then((res) => {
      if (res.status == 200) {
        setOpenSnackbar(true);
        setMessage(res.message);
      }
    });
  };

  useEffect(() => {
    const tagStr = tags?.join(", ");
    setTagString(tagStr);
  }, []);

  const navigateTo = useNavigate();
  const date = new Date(updatedDate);
  const editHandler = () => {
    if (BottomClicked && !Application) {
      BottomClicked(true, item);
    }
  };
  const clickHanlder = () => {
    if (BottomClicked && Application) {
      BottomClicked(true, item);
    } else {
      console.log("Navvv-->", navigate);
      navigate && navigateTo(navigate);
      ReducerFunction && dispatch(ReducerFunction(item));
    }
  };

  return (
    <Card style={{minHeight:"290px"}} sx={{ maxWidth: 300, borderRadius: "13px" }}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={openSnackbar}
        autoHideDuration={6000}
        message={message}
        key={vertical + horizontal}
      />
      <CardActionArea 
        onClick={() => {
          clickHanlder();
        }}
      >
        <CardMedia 
          component="img"
          height={130}
          image={
            Icon
              ? Icon
              : `https://ui-avatars.com/api/?background=03bfeb&color=fff&name=${Name}`
          }
          alt="Name"
        />
        <CardContent style={{ textAlign: "left" }}>
          {/* <div style={{ display: "flex" }}> */}
          <Typography variant="body2" color="text.secondary">
            {tagString}
          </Typography>
          {/* </div> */}

          <Typography style={{height:"64px"}} gutterBottom variant="h5" component="div">
            {Name}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {moment(updatedDate).format("MMMM Do YYYY, h:mm:ss a")}
          </Typography>
        </CardContent>
      </CardActionArea>

      {!Application && EditMode ? (
        <CardActions
          style={{
            height: "35px",
            justifyContent: "flex-end",
            alignItems: "center",
            padding: "0px",
            cursor: "pointer",
          }}
        >
          <Button
            size="small"
            style={{ margin: "1rem 0rem" }}
            onClick={editHandler}
          >
            <EditOutlinedIcon />
          </Button>
        </CardActions>
      ) : !Application && !EditMode ? (
        <CardActions
          style={{
            height: "35px",
            justifyContent: "flex-end",
            alignItems: "center",
            cursor: "pointer",
            padding: "0px",
          }}
        >
          <Button
            size="small"
            style={{ margin: "1rem 0rem" }}
            onClick={editHandler}
          >
            <InfoOutlinedIcon />
          </Button>
        </CardActions>
      ) : null}
    </Card>
  );
}

export default CardModel;
