import React from "react";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import Dynamic_panel from "./Dynamic_panel";
import Login from "./Login";
import Register from "./Register";
import Profile from "./Profile";
import Dashboard from "./components/Dashboard";
import New_project from "./New_project";
import ModuleDashboard from "./ModuleDashboard";
import ViewSubmodule from "./subModule/ViewSubmodule";
import FormFile from "./FormInputs/FormFile";
import AdminPanel from "./admin/AdminPanel";
import ApplicationForm from "./admin/ApplicationForm";
import AdminOutlet from "./admin/AdminOutlet";
import AdminDashboard from "./admin/AdminDashboard";
import NewModuleForm from "./admin/NewModuleForm";
import AdminModuleName from "./admin/AdminModuleName";
import NewSubmoduleForm from "./admin/NewSubmoduleForm";
import StepperForm from "./admin/StepperForm";
import NewModuleFromScratch from "./admin/NewModuleFromScratch";
import NewSubModuleFromScratch from "./admin/NewSubModuleFromScratch";
import FormBuilderSidebar from "./admin/FormBuilder/FormBuilderSidebar";
import LoadingModal from "./components/LoadingModal";
import PrivateComponent from "./components/PrivateComponent";
import TeamManage from "./admin/TeamManage"
import ViewEditDetails from "./subModule/ViewEditDetails";
import Settings from "./admin/Settings";
import MemberScreen from "./admin/MemberScreen";
import UserProfile from "./admin/UserProfile";



function Routing_components() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<PrivateComponent />}>
            <Route path="/admin" element={<AdminOutlet />}>
              <Route path="" element={<AdminPanel />} />
              <Route path="team" element={<TeamManage />} />
              <Route path="settings" element={<Settings />} />
              <Route path="memberscreen" element={<MemberScreen />} />
              <Route path="userprofile" element={<UserProfile />} />
              <Route path="add-new-application" element={<StepperForm />} />
              <Route path="view/:applicationName" element={<Dynamic_panel />} />
              <Route path="edit/:applicationName" element={<Dynamic_panel />} />
              <Route
                path="edit/:applicationName/add-new-module"
                element={<NewModuleForm />}
              />
              <Route
                path="view/:applicationName/:moduleName/dashboard"
                element={<Dashboard />}
              />
              <Route
                path="view/:applicationName/:moduleName/:submoduleName/list"
                element={<ViewSubmodule />}
              />
              <Route
                path="view/:applicationName/:moduleName/:submoduleName/add"
                element={<FormFile />}
              />
              <Route
                path="view/:applicationName/:moduleName/:submoduleName/view"
                element={<ViewEditDetails />}
              />
              <Route
                path="view/:applicationName/:moduleName/:submoduleName/edit"
                element={<ViewEditDetails />}
              />
              <Route
                path="edit/:applicationName/add-new-module/from-scratch"
                element={<NewModuleFromScratch />}
              />
              <Route
                path="edit/:applicationName/:moduleName/dashboard"
                element={<AdminModuleName />}
              />
              <Route
                path="edit/:applicationName/:moduleName/add-new-submodule"
                element={<NewSubmoduleForm />}
              />
              <Route
                path="edit/:applicationName/:moduleName/add-new-submodule/from-scratch"
                element={<NewSubModuleFromScratch />}
              />
              <Route
                path="edit/:applicationName/:moduleName/:submoduleName/FormBuilder"
                element={<FormBuilderSidebar />}
              />
            </Route>
          </Route>
          <Route path="/Login" element={<Login />} />
          <Route path="/Register" element={<Register />} />
          <Route path="/Profile" element={<Profile />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default Routing_components;
