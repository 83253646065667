import React from 'react'
import { useNavigate } from 'react-router-dom'

function RoutesButton() {
    const nav = useNavigate(); 
  return (
    <div>
        <button onClick={()=>{nav('/Dynamic_panel')}}>Dynamic panel</button>
        <button onClick={()=>{nav('/Login')}}>Login</button>
        <button onClick={()=>{nav('/Registration')}}>Registration</button>
        <button onClick={()=>{nav('/Profile')}}>Profile</button>
        <button onClick={()=>{nav('/Dashboard')}}>Dashboard</button>
        <button onClick={()=>{nav('/Dashboard/New_Project')}}> Dashboard New Project</button>
    </div>
  )
}

export default RoutesButton