import React from 'react'
import navbar_logo from '../Images/navbar_logo.png'
import Bird from '../Images/Bird.png'
import user1 from '../Images/user1.png'
import user2 from '../Images/user2.png'
import user3 from '../Images/user3.png'
import user4 from '../Images/user4.png'
import user5 from '../Images/user5.png'
import user6 from '../Images/user6.png'
import user7 from '../Images/user7.png'
import user8 from '../Images/user8.png'
import flying_bird from '../Images/flying_bird.gif'
import skyhigh from '../Images/skyhigh.jpg'
import study_table from '../Images/study_table.jpg'
import google_logo from '../Images/google.png' 


const all_images = { navbar_logo, Bird, user1,user2,user3,user4,user5,user6,user7,user8,flying_bird,
    skyhigh,study_table,google_logo };

export default {all_images}
