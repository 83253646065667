import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "./AdminDashboard.css";
import "./AdminModuleName.css";
import subModules from "../utils/subModule.json";
import modules from "../utils/module.json";
import noModules from "../assets/noModules.png";
import { Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteSubModuleData,
  fetchSubModuleByModuleId,
} from "../services/RestApi";
import { moduleAdmin, submoduleAdmin } from "../Store/Slices/AdminSlice";
import CardModel from "../components/CardModel";
import LoadingModal from "../components/LoadingModal";
import { AddOutlined, ContentCopy } from "@mui/icons-material";
import ContentHeader from "../components/ContentHeader";
import {
  Button,
  Card,
  CardContent,
  Grid,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { BottomSheet } from "react-spring-bottom-sheet-updated";
import moment from "moment";
import { horizontal, vertical } from "../Login";
import { v4 } from "uuid";
import BottomSheets from "./BottomSheets";
import Footer from "../components/Footer";
import SvgIcons from "../components/SvgIcons";
import { getThemeGradient } from "../utils/Theme";
import RightSideBar from "./RightSideBar";

function AdminModuleName() {
  const navigateTo = useNavigate();
  const dispatch = useDispatch();
  const { applicationName, moduleName } = useParams();
  const [subModules, setSubModules] = useState([]);
  const [subModuleActive, setSubModuleActive] = useState(0);
  const [subModuleInactive, setSubmoduleInactive] = useState(0);
  const [showLoading, SetShowLoading] = useState(true);
  const [snackbar, setSnackbar] = useState(false);
  const [message, setMessage] = useState("");
  const [currentState, setCurrentState] = useState({});
  const [bottomClicked, setBottomClicked] = useState(false);

  const moduleArray = useSelector((state) => {
    return state.admin.modArray;
  });

  const moduleId = moduleArray.find((ele) => {
    return ele.moduleName === moduleName;
  });
  useEffect(() => {
    setTimeout(() => {
      subModuleDetails();
    }, 500);
    const interval = setInterval(() => {
      subModuleDetails();
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, []);
  const subModuleDetails = () => {
    fetchSubModuleByModuleId({ moduleId: moduleId.moduleId })
      .then((res) => {
        if (res.status === 201) {
          setSubModules(res.data);
          SetShowLoading(false);
        }
        let activeCount = 0,
          inactiveCount = 0;
        res.data.map((ele, index) => {
          if (ele.status === 1) {
            activeCount++;
          } else if (ele.status === 0) {
            inactiveCount++;
          }
        });
        setSubModuleActive(activeCount);
        setSubmoduleInactive(inactiveCount);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const subModuleItems = subModules;
  const editiorHandler = () => {
    let item = currentState;
    dispatch(submoduleAdmin(item));
    navigateTo(
      `/admin/edit/${applicationName}/${moduleName}/${item.subModuleName}/FormBuilder`
    );
  };

  const bottomClickHandler = (value, item) => {
    setBottomClicked(value);
    setCurrentState(item);
  };

  const deleteHandler = async () => {
    let item = currentState;
    await deleteSubModuleData({ id: item._id }).then((res) => {
      console.log(res);
      if (res.status === 200) {
        setBottomClicked(false);
        setMessage("Module deleted successfully ");
        setSnackbar(true);
      } else {
        setMessage("Something went wrong!!!! ");
        setSnackbar(true);
        setBottomClicked(false);
      }
    });
  };

  return (
    <>
      {showLoading ? (
        <LoadingModal />
      ) : (
        <div className="bodies">
          <ContentHeader appId={moduleId} applicationName={moduleName} />

          {subModuleItems.length === 0 ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                flexDirection: "column",
                marginTop: "1rem",
              }}
            >
              <SvgIcons icon="addForm" height={320} width={320} />
              <Typography variant="h6">
                You do not have any sub module yet
              </Typography>
              <Typography variant="h7">
                Your sub module will appear here
              </Typography>
              <Button
                variant="outlined"
                size="large"
                style={{
                  borderColor: getThemeGradient(),
                  borderWidth: "2px",
                  borderRadius: "4px",
                  fontSize: "22px",
                  padding: "0px 60px",
                  color: getThemeGradient(),
                  marginTop: "20px",
                }}
                onClick={() =>
                  navigateTo(
                    `/admin/edit/${applicationName}/${moduleName}/add-new-submodule`
                  )
                }
              >
                CREATE SUBMODULE
              </Button>
            </div>
          ) : (
            <div style={{ paddingLeft: "2rem" }}>
              <Grid container spacing={2} style={{ marginTop: "1rem" }}>
                <Grid item xs={5} sm={4} md={2}>
                  <Card
                    style={{
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      borderRadius: "13px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      dispatch(moduleAdmin(...moduleArray));
                      navigateTo(
                        `/admin/edit/${applicationName}/${moduleName}/add-new-submodule`
                      );
                    }}
                  >
                    <CardContent style={{ textAlign: "center" }}>
                      <AddOutlined
                        style={{ color: "#03bfeb", fontSize: "4rem" }}
                      />
                      <Typography variant="h5" color="#03bfeb">
                        Add Submodules
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>

                {subModuleItems.map((item, index) => (
                  <Grid item xs={5} sm={4} md={2} key={index}>
                    <>
                      <CardModel
                        ReducerFunction={submoduleAdmin}
                        item={item}
                        Name={item.subModuleName}
                        updatedDate={item.updated_at}
                        id={item._id}
                        navigate={`/admin/edit/${applicationName}/${moduleName}/${item.subModuleName}/FormBuilder`}
                        Icon={item.subModuleIcon}
                        BottomClicked={bottomClickHandler}
                        Application={false}
                        EditMode={true}
                      />

                      <RightSideBar
                        Details={currentState}
                        bottomClicked={bottomClicked}
                        ReducerFunction={submoduleAdmin}
                        bottomClickHandler={bottomClickHandler}
                        editorLink={`/admin/edit/${applicationName}/${moduleName}/${item.subModuleName}/FormBuilder`}
                        UserLink={null}
                        Inside={"SubModule"}
                        deleteApi={deleteSubModuleData}
                        EditMode={true}
                      />
                    </>
                  </Grid>
                ))}
              </Grid>
            </div>
          )}
          {/* <Footer subModuleFooter={true} /> */}
        </div>
      )}
    </>
  );
}

export default AdminModuleName;
