import React from "react";
import Routing_components from "./Routing_components";

function App() {
  return (
    <div>
      <Routing_components />
    </div>
  );
}

export default App;
