import React from "react";

const Footer = ({sidebar}) => {
  // {console.log(sidebar)}
  return (
    <div style={sidebar ? {width:"83%"} : {width:"100%"} } className="dynamic_panel_footer">
      <p className="dynamic_panel_footer_para">
        Copyright @ 2023 Alphech Global
      </p>
    </div>
  );
};

export default Footer;
