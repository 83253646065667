import React, { useEffect, useState } from "react";
import { BottomSheet } from "react-spring-bottom-sheet-updated";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  Input,
  MenuItem,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Theme } from "../utils/Theme";
import moment from "moment";
import { v4 } from "uuid";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import Select from '@mui/material/Select';
import SaveAsOutlinedIcon from "@mui/icons-material/SaveAsOutlined";
import {
  updateApplicationData,
  updateModuleDataById,
  updateSubModuleData,
  uploadImage,
} from "../services/RestApi";
import { horizontal, vertical } from "../Login";


function BottomSheets({
  bottomClicked,
  EditMode,
  editorLink,
  UserLink,
  Inside,
  Details,
  deleteApi,
  ReducerFunction,
  bottomClickHandler,
}) {
  const dispatch = useDispatch();
  const navigateTo = useNavigate();
  const [bottomClick, setBottomClicked] = useState(bottomClicked);
  const [snackbar, setSnackbar] = useState(false);
  const [message, setMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  console.log("Inside--->", Inside);
  console.log("Details App--->", Details);

  const CloseHandler = () => {
    setOpen(false);
  };

  useEffect(() => {
    setEditData((prevValues) => ({
      ...prevValues,
      logo:
        Inside === "Appication"
          ? Details?.aplLogo
          : Inside === "Module"
            ? Details?.moduleIcon
            : Details?.subModuleIcon,
      name:
        Inside === "Application"
          ? Details.aplName
          : Inside === "Module"
            ? Details.moduleName
            : Details.subModuleName,
      description:
        Details && Inside === "Application"
          ? Details.aplDescription
          : Inside === "Module"
            ? Details.moduleDescription
            : Details.subModuleDescription,
      tags: Details && Details.tags,
      slug:
        Details && Inside === "Application"
          ? Details.aplPath
          : Inside === "Module"
            ? Details.modulePath
            : Details.subModulePath,
      status: Details && Details.status === 1 ? "ACTIVE" : "INACTIVE",
    }));
    setModuleName(Inside === "Application"
      ? Details.aplName
      : Inside === "Module"
        ? Details.moduleName
        : Details.subModuleName)
    setModuleDescription(Inside === "Application"
      ? Details.aplDescription
      : Inside === "Module"
        ? Details.moduleDescription
        : Details.subModuleDescription)
    setModulePath(Inside === "Application"
      ? Details.aplPath
      : Inside === "Module"
        ? Details.modulePath
        : Details.subModulePath)
    setModuleStatus(Details.status === 1 ? "ACTIVE" : "INACTIVE")
    Details.tags && setTags(Details.tags.map((item, index) => {
      return { key: v4(), value: item }
    }))

  }, [Details, Inside]);


  const [moduleName, setModuleName] = useState('');
  const [modulePath, setModulePath] = useState('');
  const [moduleStatus, setModuleStatus] = useState('');
  const [moduleDescription, setModuleDescription] = useState('');
  const [editData, setEditData] = useState({
    logo: "",
    name: "",
    description: "",
    tags: "",
    slug: "",
    status: "",
  });
  const [tag, setTag] = useState('');
  const [tags, setTags] = useState([]);
  const [apiTags, setApiTags] = useState([]);
  const [previewUrl, setPreviewUrl]=useState('')
  const handlemoduleStatus = (event) => {
    setModuleStatus(event.target.value)
  };

  console.log("Module Path-->", modulePath);

  const handlemoduleName = (event) => {
    setModuleName(event.target.value);
    let slug = "";
    const mod = event.target.value;
    for (let i in mod) {
      if (mod[i] !== " ") {
        slug += mod[i];
      } else if (mod[i] === " ") {
        slug += "-";
      }
    }
    setModulePath(slug.toLowerCase());
  };

  const handlemoduleDescription = (event) => {
    setModuleDescription(event.target.value);
  };



  const handleClose = () => {
    setOpenSnackbar(false);
  };
  const editorHandler = () => {
    let item = Details;
    dispatch(ReducerFunction(item));
    navigateTo(editorLink);
  };
  const userHandler = () => {
    let item = Details;
    dispatch(ReducerFunction(item));
    navigateTo(UserLink);
  };
  const deleteHandler = async () => {
    let item = Details;
    await deleteApi({ id: item._id }).then((res) => {
      if (res.status === 200) {
        setOpenSnackbar(true);
        setMessage("Deleted successfully ");
        bottomClickHandler(false, Details);
      } else {
        setMessage("Something went wrong!!!! ");
        setOpenSnackbar(true);
        bottomClickHandler(false, Details);
      }
    });
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      {
        setTags((prev) => [...prev, { key: v4(), value: tag }]);
        setApiTags((prev) => [...prev, tag]);
      }
      setTag("");
    }
  };

  const handleTag = (event) => {
    setTag(event.target.value);
  };

  const handleDeleteTag = (keyToRemove) => {
    const newArray = tags.filter((item) => item.key !== keyToRemove);
    setTags(newArray);
    setApiTags(newArray)
  };


  const clickhandler = () => {
    bottomClickHandler(false, Details);
  };
  // const handleInputChange = (event) => {
  //   const { name, value } = event.target;
  //   setEditData((prevValues) => ({
  //     ...prevValues,
  //     [name]: value,
  //   }));
  // };
  const submitHandler = () => {
    let apiTags = tags.map(item => item.value)
    if (Inside === "Application") {
      updateApplicationData({
        id: Details._id,
        aplName: moduleName,
        aplDescription: moduleDescription,
        aplLogo: LogoIcon(),
        aplPath: modulePath,
        tags: apiTags,
        status: moduleStatus === "ACTIVE" ? 1 : 0,
        is_active: moduleStatus === "ACTIVE" ? true : false,
      })
        .then((res) => {
          if (res.status === 200) {
            setMessage("Information Updated!!!");
            setOpenSnackbar(true);
            bottomClickHandler(false, Details);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (Inside === "Module") {
      updateModuleDataById({
        id: Details._id,
        moduleName: moduleName,
        moduleDescription: moduleDescription,
        modulePath: modulePath,
        tags: apiTags,
        moduleIcon: LogoIcon(),
        status: moduleStatus === "ACTIVE" ? 1 : 0,
        is_active: moduleStatus === "ACTIVE" ? true : false,
      })
        .then((res) => {
          if (res.status === 200) {
            setMessage("Information Updated!!!");
            setOpenSnackbar(true);
            bottomClickHandler(false, Details);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (Inside === "SubModule") {
      updateSubModuleData({
        subModuleId: Details._id,
        subModuleName: moduleName,
        subModuleDescription: moduleDescription,
        subModulePath: modulePath,
        tags: apiTags,
        subModuleIcon: LogoIcon(),
        status: moduleStatus === "ACTIVE" ? 1 : 0,
        is_active: moduleStatus === "ACTIVE" ? true : false,
      })
        .then((res) => {
          if (res.status === 200) {
            setMessage("Information Updated!!!");
            setOpenSnackbar(true);
            bottomClickHandler(false, Details);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const LogoIcon = () => {
    if (previewUrl !== '') {
      return previewUrl
    }
    else {
      return Inside === "Application"
        ? Details?.aplLogo
        : Inside === "Module"
          ? Details?.moduleIcon
          : Details?.subModuleIcon;
    }
  };
  const nameFunction = () => {
    return Inside === "Application"
      ? Details.aplName
      : Inside === "Module"
        ? Details.moduleName
        : Details.subModuleName;
  };
  const handleFileSelect = (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    const formData = new FormData();
    formData.set('image', file, file.name);

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    uploadImage(formData, config).then((res) => {
      if (res.status === 200) {
        setPreviewUrl(res.data?.url)
      }
    })
  }

  return (
    <div style={{ backgroundColor: "black" }}>
      <BottomSheet
        open={bottomClicked}
        onDismiss={() => {
          clickhandler();
        }}
        snapPoints={({ minHeight }) => minHeight}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            padding: "10px 30px",
            borderTopRightRadius: "25px",
            borderTopLeftRadius: "25px",
            overflow: "hidden",
            backgroundColor: "white"
          }}
        >
          <div className="Btm-sheet-up-btn">
            {Inside === "Application" ? (
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  editorHandler();
                }}
              >
                <EditOutlinedIcon /> Editor
              </Button>
            ) : // ) : EditMode ? (
              //   <Button
              //     variant="outlined"
              //     color="primary"
              //     onClick={() => {
              //       editorHandler();
              //     }}
              //   >
              //     Editor <EditOutlinedIcon />
              //   </Button>
              null}
            {Inside === "Application" ? (
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  userHandler();
                }}
              >
                <AccountCircleOutlinedIcon /> As a User
              </Button>
            ) : // ) : !EditMode ? (
              //   <Button
              //     variant="outlined"
              //     color="primary"
              //     onClick={() => {
              //       userHandler();
              //     }}
              //   >
              //     As a User <AccountCircleOutlinedIcon />
              //   </Button>
              null}
          </div>
          <div>
            {EditMode ? (
              <TableContainer style={{ overflow: "auto" }}>
                <Table>
                  <TableBody>
                    <TableRow>
                      <div style={{ display: "flex" }}>
                        <TableCell>
                          <img
                            height={120}
                            width={100}
                            src={
                              LogoIcon()
                                ? LogoIcon()
                                : `https://ui-avatars.com/api/?background=03bfeb&color=fff&name=${editData.name}`
                            }
                            alt="Logo"
                            style={{
                              border: "1px solid black",
                              borderRadius: "10px",
                              width: "8vw",
                              objectFit: "cover",
                            }}
                          />
                          <TextField
                            variant="outlined"
                            type="file"
                            onChange={handleFileSelect}
                            style={{ width: "7.7vw", marginTop: "1rem" }}
                          />
                        </TableCell>

                        <div
                          style={{ display: "flex", flexDirection: "column", width: "100%" }}
                        >
                          <TableCell>
                            <div className="textFields flexColumnDirection">
                              <TextField
                                label="Module Name"
                                variant="outlined"
                                name="name"
                                value={moduleName}
                                onChange={handlemoduleName}
                              />
                            </div>
                          </TableCell>
                          <TableCell>
                            <div>
                              <label htmlFor="AFmodulePath">Tags</label>
                              {tags && tags.map((item) => {
                                return (
                                  <button key={item.key} className="AFtagButton">
                                    {item.value}{" "}
                                    <i
                                      id="AFtagCloseIcon"
                                      className="fa-solid fa-xmark"
                                      onClick={() => handleDeleteTag(item.key)}
                                    />
                                  </button>
                                );
                              })}
                              <div className="textFields flexColumnDirection">
                                <TextField
                                  label="Tags"
                                  variant="outlined"
                                  value={tag}
                                  onKeyDown={handleKeyDown}
                                  onChange={handleTag}
                                />
                              </div>
                            </div>
                          </TableCell>
                        </div>
                      </div>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <div className="textFields flexColumnDirection">
                          <TextField
                            label="Module Description"
                            variant="outlined"
                            name="description"
                            value={moduleDescription}
                            onChange={handlemoduleDescription}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                    <TableRow></TableRow>
                    <TableRow>
                      <TableCell>
                        <div className="textFields flexColumnDirection">
                          <TextField
                            label="Module Slug"
                            variant="outlined"
                            name="slug"
                            value={modulePath}
                            onChange={handlemoduleName}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <div className="textFields flexColumnDirection">
                          {/* <InputLabel id="dropdown-label">Select an option</InputLabel> */}
                          <Select
                            labelId="dropdown-label"
                            id="dropdown"
                            name="status"
                            value={moduleStatus}
                            label="Select an option"
                            onChange={handlemoduleStatus}
                            variant="outlined"
                          >
                            <MenuItem value="ACTIVE">Active</MenuItem>
                            <MenuItem value="INACTIVE">Inactive</MenuItem>
                          </Select>
                        </div>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        Created Date:{" "}
                        <b>
                          {Details &&
                            moment(Details.created_at).format(
                              "MMMM Do YYYY, h:mm:ss a"
                            )}
                        </b>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        Last Updated:{" "}
                        <b>
                          {Details &&
                            moment(Details.updated_at).format(
                              "MMMM Do YYYY, h:mm:ss a"
                            )}
                        </b>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <TableContainer style={{ overflow: "auto" }}>
                <Table>
                  <TableBody>
                    <TableRow>
                      <div style={{ display: "flex", }}>
                        <TableCell>
                          <img
                            height={120}
                            width={100}

                            src={
                              LogoIcon()
                                ? LogoIcon()
                                : `https://ui-avatars.com/api/?background=03bfeb&color=fff&name=${nameFunction()}`
                            }
                            alt="Logo"
                            style={{
                              border: "1px solid black",
                              borderRadius: "10px",
                              width: "8vw",
                              objectFit: "cover",
                            }}
                          />
                        </TableCell>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <TableCell>
                            <p style={{ fontSize: "18px", margin: "0" }}>
                              {nameFunction()}
                            </p>
                          </TableCell>
                          <TableCell>
                            <>
                              {Details &&
                                Details.tags?.map((ele, index) => {
                                  return (
                                    <Button
                                      variant="outlined"
                                      style={{
                                        marginRight: "10px",
                                        color: Theme.highlight,
                                        padding: "4px",
                                      }}
                                    >
                                      {ele}
                                    </Button>
                                  );
                                })}
                            </>
                          </TableCell>
                        </div>
                      </div>
                    </TableRow>
                    <TableRow></TableRow>
                    <TableRow>
                      <TableCell>
                        <label> Description - </label>
                        {Inside === "Application"
                          ? Details.aplDescription
                          : Inside === "Module"
                            ? Details.moduleDescription
                            : Details.subModuleDescription}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <label> Slug - </label>
                        {Inside === "Application"
                          ? Details.aplPath
                          : Inside === "Module"
                            ? Details.modulePath
                            : Details.subModulePath}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <label> Status -</label> 
                        {Details.status === 1 ? "Active" : "Inactive"}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell >
                          Created Date:{" "} 

                           {Details &&
                              moment(Details.created_at).format(
                                "MMMM Do YYYY, h:mm:ss a"
                              )}
          
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        Last Updated:{" "}
                      
                          {Details &&
                            moment(Details.updated_at).format(
                              "MMMM Do YYYY, h:mm:ss a"
                            )}
                      
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              margin: "10px",
            }}
          >

            {EditMode ? (<Button
              variant="outlined"
              className="w-full"
              onClick={submitHandler}
              style={{ margin: "10px" }}
            >
              <SaveAsOutlinedIcon /> Update
            </Button>) : ("")}

            
            <div className="btm-sheet-lwr-btn">
              {EditMode ? (<Button
                variant="outlined"
                onClick={() => {
                  clickhandler();
                }}
                className="w-full"
                style={{ marginRight: "7px" }}
              >
                Dismiss
              </Button>) : (<Button
                variant="outlined"
                onClick={() => {
                  clickhandler();
                }}
                className="w-full-left"
                style={{ marginRight: "7px", marginLeft: "" }}
              >
                Dismiss
              </Button>)}
              {/* <Button
                variant="outlined"
                onClick={() => {
                  clickhandler();
                }}
                className="w-full"
                style={{ marginRight: "7px" }}
              >
                Dismiss
              </Button> */}
              {Inside === "Application" ? (
                <Button
                  variant="outlined"
                  onClick={() => {
                    // deleteHandler();
                    handleClickOpen()
                  }}
                  className="w-full"
                  style={{
                    marginleft: "7px",
                    color: "red",
                    border: "1px solid red",
                  }}
                >
                  <DeleteOutlineIcon /> Delete Application
                </Button>
              ) : EditMode ? (
                <Button
                  variant="outlined"
                  onClick={() => {
                    // deleteHandler();
                    handleClickOpen()
                  }}
                  className="w-full"
                  style={{
                    marginleft: "7px",
                    color: "red",
                    border: "1px solid red",
                  }}
                >
                  <DeleteOutlineIcon /> Delete Application
                </Button>
              ) : null}
            </div>
          </div>
        </div>
      </BottomSheet>
      <Dialog 
        open={open}
        onClose={CloseHandler}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {" Are you sure you want to delete?"}
        </DialogTitle>
      
        <DialogActions>
          <Button variant="outlined" color="error" onClick={CloseHandler}>No</Button>
          <Button variant="contained" onClicked={()=>deleteHandler()} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={openSnackbar}
        onClose={handleClose}
        autoHideDuration={3000}
        message={message}
        key={vertical + horizontal}
      />
    </div>
  );
}

export default BottomSheets; 
