import {
  Button,
  Checkbox,
  Drawer,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  makeStyles,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "./FormBuilderSidebar.css";
import thankYou from "../../assets/thankYou.png";
import { v4 } from "uuid";
import {
  AccessTimeOutlined,
  Add,
  ArrowDropDown,
  ArrowDropDownOutlined,
  CalendarMonth,
  Call,
  CallOutlined,
  CheckBox,
  CheckBoxOutlineBlank,
  CheckBoxOutlined,
  Clear,
  ClearAllRounded,
  ClearOutlined,
  Delete,
  DeleteOutlined,
  DriveFileRenameOutlineOutlined,
  EmailOutlined,
  FileUpload,
  FileUploadOutlined,
  FormatColorText,
  FormatColorTextOutlined,
  FormatPaint,
  FormatPaintOutlined,
  Image,
  ImageOutlined,
  LocationCity,
  LocationCityOutlined,
  LockClockOutlined,
  Looks3,
  Looks3Outlined,
  Notes,
  NotesOutlined,
  OpenWith,
  PlusOne,
  RadioButtonChecked,
  RadioButtonCheckedOutlined,
  Settings,
  ShortText,
  ShortTextOutlined,
} from "@mui/icons-material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { display } from "@mui/system";
import {
  fetchSubModuleByModuleId,
  fetchSubModuleListById,
  updateSubModuleData,
} from "../../services/RestApi";
import { LoadingButton } from "@mui/lab";
import { getThemeGradient } from "../../utils/Theme";
import MiddleForm from "./MiddleForm";
import Footer from "../../components/Footer";

const useStyles = makeStyles({
  drawer: {
    height: "100%",
  },
  drawerPaper: {
    position: "static",
    width: "100%",
    height: "100%",
    background: "#3e4652",
    color: "white",
  },
});

const FormBuilderSidebar = () => {
  const navigateTo = useNavigate();
  const classes = useStyles();
  const { applicationName, moduleName, submoduleName } = useParams();

  const [formData, setFormData] = useState({
    firstName: "",
    secondName: "",
    streetAddress: "",
    email: "",
    streetAddressLine2: "",
    city: "",
    state: "",
    zipCode: "",
    phone: "",
    streetAddressLine2: "",
    time: "",
  });

  const [date, setDate] = useState(dayjs(new Date()));

  const [drawerType, setDrawerType] = useState("temporary");
  const [drawerType2, setDrawerType2] = useState("temporary");
  const [formElementsList, setFormElementsList] = useState([]);
  const [selectedElement, setSelectedElement] = useState();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const [checked, setChecked] = useState(false);

  const subModuleDetail = useSelector((state) => {
    return state.admin.subModArray;
  });

  const subModule = subModuleDetail.find((ele) => {
    return ele.subModuleName === submoduleName;
  });

  const handlePreviewChange = () => {
    setChecked((prev) => !prev);
    if (formSubmitted) {
      setFormSubmitted(false);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      fetchSubModuleListById({ id: subModule._id }).then((res) => {
        setFormElementsList(res.data[0].subModuleProperties);
      });
    }, 500);
  }, []);

  const handleInputChange = (event, item) => {
    const { value } = event.target;
    setFormElementsList((prev) => [
      ...prev.map((val) => {
        if (val.key === item.key) {
          val.value = value;
        }
        return val;
      }),
    ]);
  };

  const handleOnLabelChange = (event, item) => {
    const { value } = event.target;
    setFormElementsList((prev) => [
      ...prev.map((val) => {
        if (val.key === item.key) {
          val.label = value;
        }
        return val;
      }),
    ]);
  };

  const handleMultipleInputChange = (event, item, subfield) => {
    const { value } = event.target;
    setFormElementsList((prev) => [
      ...prev.map((val) => {
        if (val.key === item.key) {
          if (val.value) {
            val.value = { ...val.value, [subfield]: value };
          } else {
            val.value = { [subfield]: value };
          }
        }
        return val;
      }),
    ]);
  };

  const toggleEditOptionsForDropDown = (item) => {
    setFormElementsList((prev) => [
      ...prev.map((val) => {
        if (val.key === item.key) {
          val.isAddingOptionsForDropDown = !val.isAddingOptionsForDropDown;
        }
        return val;
      }),
    ]);
  };

  const toggleEditOptionsForSingleChoice = (item) => {
    setFormElementsList((prev) => [
      ...prev.map((val) => {
        if (val.key === item.key) {
          val.isAddingOptionsForSingleChoice =
            !val.isAddingOptionsForSingleChoice;
        }
        return val;
      }),
    ]);
  };

  const handleOnClickBasicElements = (item) => {
    const tempArray = [...formElementsList];
    // tempArray.sort((a, b) => a.id - b.id);

    item.key =
      (tempArray[tempArray.length - 1]
        ? tempArray[tempArray.length - 1].key
        : 0) + 1;
    item.isRequired = false;
    delete item.icon;
    setFormElementsList((prev) => [...prev, item]);
  };

  const isValidated = () => {
    let canSubmit = true;
    for (let item of formElementsList) {
      item.hasError = false;
      if (typeof item.value === "object" && item.isRequired) {
        if (item.value.length > -1) {
          if (item.value.length === 0) {
            item.hasError = true;
            item.errorMsg = `${item.label} is required field`;
            canSubmit = false;
            break;
          }
        } else {
          for (let i of Object.entries(item.value)) {
            if (!i[1]) {
              item.hasError = true;
              item.errorMsg = `Fill all fields of ${item.label}`;
              canSubmit = false;
              break;
            }
          }
        }
      } else if (!item.value.length > 0 && item.isRequired) {
        canSubmit = false;
        item.hasError = true;
        item.errorMsg = `${item.label} is required field`;
      }
    }
    setFormElementsList([...formElementsList]);
    return canSubmit;
  };

  const previewSubmit = () => {
    if (checked) {
      if (isValidated()) {
        setFormSubmitted(true);
      }
    }
  };

  const handleFormSubmit = () => {
    setLoading(true);

    const data = formElementsList.map((ele) => {
      delete ele.icon;
      return ele;
    });

    updateSubModuleData({
      subModuleId: subModule._id,
      subModuleProperties: data,
    })
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          console.log("Sucess");
          setFormSubmitted(true);
          navigateTo(`/admin/edit/${applicationName}/${moduleName}/dashboard`);
        }
      })
      .catch((err) => {
        console.log("Submit error form builder---->", err);
      });
  };

  const getOptionValues = (item) => {
    return item.optionsArray.join("\n");
  };

  const handleAddDropdownOptions = (event, item) => {
    const { value } = event.target;
    const optionsArray = value.split("\n");
    setFormElementsList((prev) => [
      ...prev.map((val) => {
        if (val.key === item.key) {
          val.optionsArray = optionsArray;
        }
        return val;
      }),
    ]);
  };

  const handleDeleteSingleChoiceOptions = (item, key) => {
    setFormElementsList((prev) => [
      ...prev.map((val) => {
        if (val.key === item.key) {
          val.optionsArray = val.optionsArray.filter((t) => t.key != key);
        }
        return val;
      }),
    ]);
  };

  const handleAddSingleChoiceOptions = (item) => {
    let updatedForm = [...formElementsList];

    updatedForm = updatedForm.map((val) => {
      if (val.key === item.key) {
        val.optionsArray.push({
          key: v4(),
          label: `Type option ${val.optionsArray.length + 1}`,
        });
      }
      return val;
    });
    setFormElementsList(updatedForm);
  };

  const handleOnClickDelete = (key) => {
    const filteredArray = formElementsList.filter((obj) => obj.key != key);
    setFormElementsList(filteredArray);
  };

  const handleMultipleChoiceChange = (item, option) => {
    let updatedForm = [...formElementsList];
    updatedForm = updatedForm.map((val) => {
      if (val.key === item.key) {
        if (val.value.includes(option)) {
          //remove option
          const idx = val.value.indexOf(option);
          val.value.splice(idx, 1);
        } else {
          //add option

          val.value.push(option);
        }
      }
      return val;
    });
    setFormElementsList(updatedForm);
  };

  const closeSuccessModal = () => {
    setFormSubmitted(false);
    setChecked((prev) => !prev);
  };

  const formElements = [
    {
      id: 1,
      name: "Full Name",
      icon: <DriveFileRenameOutlineOutlined style={{ color: "white" }} />,
      label: "Name",
      field: "fullName",
      placeholder: "",
      type: "fullName",
      editType: "fullName",
      validation: { isRequired: "", min: "", max: "" },
      widthSize: "",
      value: { firstName: "", secondName: "" },
      errorMsg: { firstName: "", second: "" },
      hasError: false,
      dataType: "string",
    },
    {
      id: 2,
      name: "E-Mail",
      icon: <EmailOutlined style={{ color: "white" }} />,
      label: "Email",
      field: "email",
      placeholder: "",
      type: "email",
      editType: "email",
      validation: { isRequired: "", min: "", max: "" },
      widthSize: "",
      hasError: false,
      errorMsg: "",
      value: "",
      dataType: "string",
    },
    {
      id: 3,
      name: "Address",
      icon: <LocationCityOutlined style={{ color: "white" }} />,
      label: "Address",
      field: "address",
      placeholder: "",
      type: "address",
      editType: "address",
      validation: { isRequired: "", min: "", max: "" },
      value: {
        city: "",
        streetAddress: "",
        streetAddressLine2: "",
        state: "",
        zipCode: "",
      },
      errorMsg: {
        city: "",
        streetAddress: "",
        streetAddressLine2: "",
        state: "",
        zipCode: "",
      },
      hasError: false,
      widthSize: "",
      dataType: "string",
    },
    {
      id: 4,
      name: "Phone",
      icon: <CallOutlined style={{ color: "white" }} />,
      label: "Phone Number",
      field: "phone",
      placeholder: "",
      type: "phone",
      editType: "phone",
      validation: { isRequired: "", min: "", max: "" },
      widthSize: "",
      hasError: false,
      errorMsg: "",
      value: "",
      dataType: "number",
    },
    {
      id: 5,
      name: "Date Picker",
      icon: <AccessTimeOutlined style={{ color: "white" }} />,
      label: "Date",
      field: "date",
      placeholder: "",
      type: "text",
      editType: "datePicker",
      validation: { isRequired: "", min: "", max: "" },
      widthSize: "",
      hasError: false,
      errorMsg: "",
      value: "",
      dataType: "string",
    },
  ];

  const basicElements = [
    {
      id: 1,
      name: "Short Text",
      icon: <ShortTextOutlined style={{ color: "white" }} />,
      label: "Label",
      field: "shortText",
      placeholder: "",
      type: "text",
      editType: "shortText",
      validation: { isRequired: "", min: "", max: "" },
      widthSize: "",
      hasError: false,
      errorMsg: "",
      value: "",
      dataType: "string",
    },
    {
      id: 2,
      name: "Long Text",
      icon: <NotesOutlined style={{ color: "white" }} />,
      label: "Label",
      field: "longText",
      placeholder: "",
      type: "description",
      editType: "longText",
      validation: { isRequired: "", min: "", max: "" },
      widthSize: "",
      hasError: false,
      errorMsg: "",
      value: "",
      dataType: "string",
    },
    {
      id: 3,
      name: "Paragraph",
      icon: <FormatColorTextOutlined style={{ color: "white" }} />,
      label: "Paragraph",
      field: "paragraph",
      placeholder: "",
      type: "description",
      editType: "paragraph",
      validation: { isRequired: "", min: "", max: "" },
      widthSize: "",
      hasError: false,
      errorMsg: "",
      value: "",
      dataType: "string",
    },
    {
      id: 4,
      name: "Dropdown",
      icon: <ArrowDropDownOutlined style={{ color: "white" }} />,
      label: "Label",
      field: "dropdown",
      placeholder: "",
      type: "dropDown",
      editType: "dropDown",
      validation: { isRequired: "", min: "", max: "" },
      widthSize: "",
      optionsArray: ["option1", "option2", "option3", "option4"],
      isAddingOptionsForDropDown: false,
      hasError: false,
      errorMsg: "",
      value: "",
      dataType: "string",
    },
    {
      id: 5,
      name: "Single Choice",
      icon: <RadioButtonCheckedOutlined style={{ color: "white" }} />,
      label: "Label",
      field: "singleChoice",
      placeholder: "",
      type: "singleChoice",
      optionsArray: [
        { key: v4(), label: "Type option 1" },
        { key: v4(), label: "Type option 2" },
        { key: v4(), label: "Type option 3" },
        { key: v4(), label: "Type option 4" },
      ],
      editType: "singleChoice",
      validation: { isRequired: "", min: "", max: "" },
      widthSize: "",
      isAddingOptionsForSingleChoice: false,
      hasError: false,
      errorMsg: "",
      value: "",
      dataType: "string",
    },
    {
      id: 6,
      name: "Multiple Choice",
      icon: <CheckBoxOutlined style={{ color: "white" }} />,
      label: "Label",
      field: "multiselect",
      placeholder: "",
      type: "multiselect",
      editType: "multiSelect",
      validation: { isRequired: "", min: "", max: "" },
      optionsArray: [
        { key: v4(), label: "Type option 1" },
        { key: v4(), label: "Type option 2" },
        { key: v4(), label: "Type option 3" },
        { key: v4(), label: "Type option 4" },
      ],
      widthSize: "",
      hasError: false,
      errorMsg: "",
      value: [],
      dataType: "array",
    },
    {
      id: 7,
      name: "Number",
      icon: <Looks3Outlined style={{ color: "white" }} />,
      label: "Number",
      field: "number",
      placeholder: "",
      type: "number",
      editType: "number",
      validation: { isRequired: "", min: "", max: "" },
      widthSize: "",
      hasError: false,
      errorMsg: "",
      value: "",
      dataType: "number",
    },
    {
      id: 8,
      name: "Image",
      icon: <ImageOutlined style={{ color: "white" }} />,
      label: "Image",
      field: "image",
      placeholder: "",
      type: "image",
      editType: "fileUpload",
      validation: { isRequired: "", min: "", max: "" },
      widthSize: "",
      hasError: false,
      errorMsg: "",
      value: "",
      dataType: "string",
    },
    {
      id: 9,
      name: "File Upload",
      icon: <FileUploadOutlined style={{ color: "white" }} />,
      label: "File Upload",
      field: "files",
      placeholder: "",
      type: "multipleFiles",
      editType: "fileUpload",
      validation: { isRequired: "", min: "", max: "" },
      widthSize: "",
      hasError: false,
      errorMsg: "",
      value: "",
      dataType: "string",
    },
  ];

  return (
    <div>
      <div style={{ display: "flex", height: "100%", background: "#FBFBFB" }}>
        <div style={{ position: "absolute", width: "300px" }}>
          {!checked && (
            <Drawer
              className={classes.drawer}
              variant={drawerType}
              anchor="left"
              classes={{ paper: classes.drawerPaper }}
            >
              <div style={{ overflow: "auto", height: "85vh" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "10px",
                  }}
                >
                  <div
                    style={{
                      background: "#3e4652",
                      color: "white",
                      width: "100%",
                      paddingLeft: "5%",
                      overflow: "auto",
                    }}

                    //   onClick={() => navigateTo(`/admin/${applicationName}/dashboard`)}
                  >
                    <Typography variant="h6">Elements</Typography>
                  </div>
                  <Clear
                    color="black"
                    onClick={() => setDrawerType("temporary")}
                    style={{ marginLeft: "1%", cursor: "pointer" }}
                  />
                </div>

                <List>
                  {formElements.map((item) => (
                    <ListItem
                      key={item.id}
                      button
                      onClick={() => handleOnClickBasicElements(item)}
                    >
                      <ListItemIcon>{item.icon}</ListItemIcon>
                      <ListItemText primary={item.name} />
                    </ListItem>
                  ))}
                </List>

                <div
                  style={{
                    background: "#3e4652",
                    color: "white",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="h6">Basic Elements</Typography>
                </div>
                <List>
                  {basicElements.map((item) => (
                    <ListItem
                      key={item.id}
                      button
                      onClick={() => handleOnClickBasicElements(item)}
                    >
                      <ListItemIcon>{item.icon}</ListItemIcon>
                      <ListItemText primary={item.name} />
                    </ListItem>
                  ))}
                </List>
              </div>
            </Drawer>
          )}
        </div>

        {drawerType === "temporary" && !checked && (
          <div className="leftDrawer">
            <div
              onClick={() => setDrawerType("permanent")}
              color="white"
              className="leftDrawerWrapper"
            >
              <div className="leftDrawerTitle">Add Elements</div>
              <Add />
            </div>
          </div>
        )}

        <div style={{ width: "100%" }}>
          <div className="formBuilderOuter">
            <div className="formBuilderInner">
              <div
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <Typography variant="h5" style={{ fontWeight: "bold" }}>
                  Add Elements
                </Typography>
              </div>
              <MiddleForm
                formSubmitted={formSubmitted}
                formElementsList={formElementsList}
                setFormElementsList={(elements) =>
                  setFormElementsList(elements)
                }
                setFormSubmitted={(boolean) => setFormSubmitted(boolean)}
                selectedElement={selectedElement}
                checked={checked}
                setSelectedElement={(item) => setSelectedElement(item)}
                setDrawerType2={(type) => setDrawerType2(type)}
                handleOnClickDelete={(item) => handleOnClickDelete(item)}
                setDrawerType={(type) => setDrawerType(type)}
                previewSubmit={previewSubmit}
                closeSuccessModal={closeSuccessModal}
              />
            </div>
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            width: "300px",
            right: 0,
            height: "80%",
          }}
        >
          {!checked && (
            <Drawer
              className={classes.drawer}
              variant={drawerType2}
              anchor="right"
              classes={{ paper: classes.drawerPaper }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "10px",
                }}
              >
                <div
                  style={{
                    background: "#3e4652",
                    color: "white",
                    width: "100%",
                    paddingLeft: "5%",
                  }}
                >
                  <Typography variant="h6">Form Designer</Typography>
                </div>
                <Clear
                  color="black"
                  onClick={() => setDrawerType2("temporary")}
                  style={{ marginLeft: "1%", cursor: "pointer" }}
                />
              </div>
              <List>
                <ListItem>
                  <FormControlLabel
                    value="required"
                    control={
                      <Switch
                        color="primary"
                        checked={selectedElement?.isRequired}
                        onChange={(e, checked) => {
                          // setSelectedElement((prev) => ({
                          //   ...prev,
                          //   isRequired: checked,
                          // }));

                          setFormElementsList((prev) => [
                            ...prev.map((val) => {
                              if (val.key === selectedElement.key) {
                                val.isRequired = checked;
                              }
                              return val;
                            }),
                          ]);
                        }}
                      />
                    }
                    label="Required"
                    labelPlacement="start"
                  />
                </ListItem>
              </List>
            </Drawer>
          )}
        </div>

        {drawerType2 === "temporary" && !checked && (
          <div className="rightDrawer">
            <div
              onClick={() => setDrawerType2("permanent")}
              color="white"
              className="rightDrawerWrapper"
            >
              <FormatPaintOutlined
                style={{
                  transform: "rotate(-50deg)",
                  fontSize: "35px",
                }}
              />
            </div>
          </div>
        )}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row-reverse",
          background: getThemeGradient(),
          position: "fixed",
          alignItems: "center",
          bottom: "20px",
          padding: "0.3% 0%",
          width: "100vw",
        }}
      >
        <div
          style={{
            display: "flex",
            margin: "0 1%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            variant="outlined"
            onClick={handlePreviewChange}
            style={{ color: "white", borderColor: "white" }}
          >
            {!checked ? "Preview" : "Back"}
          </Button>

          {/* <FormControlLabel
            value="start"
            control={
              <Switch
                color="primary"
                checked={checked}
                onChange={handlePreviewChange}
              />
            }
            label="Preview Form"
            labelPlacement="start"
          /> */}
        </div>

        <div
          style={{
            display: "flex",
          }}
        >
          {/* <div style={{ cursor: "pointer" }} >
            <Typography style={{ whiteSpace: "nowrap" }}>SAVE DRAFT</Typography>
          </div> */}
          {!checked ? (
            <Button
              variant="outlined"
              onClick={handleFormSubmit}
              style={{ color: "white", borderColor: "white" }}
            >
              SAVE
            </Button>
          ) : null}
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
};

export default FormBuilderSidebar;
