import { Button, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import MainSideBar from "../components/MainSideBar";
import "./AdminDashboard.css";
import noModules from "../assets/noModules.png";
import { useDispatch, useSelector } from "react-redux";
import { fetchModuleByAppId } from "../services/RestApi";
import { application, moduleAdmin } from "../Store/Slices/AdminSlice";
import CardModel from "../components/CardModel";

function AdminDashboard() {
  const navigateTo = useNavigate();
  const dispatch = useDispatch();
  const { applicationName } = useParams();
  const [modules, setModules] = useState([]);

  const appID = useSelector((state) => {
    return state.admin.applicationArray;
  });

  const appId = appID.find((ele) => ele.aplName === applicationName);
  useEffect(() => {
    setTimeout(() => {
      fetchModuleByAppId({ apl_Id: appId.aplId }).then((res) => {
        if (res.status === 201) {
          setModules(res.data);
        }
      }, 1000);
    });
  }, []);

  // const menuItems = modules.filter((module) => module.apl_Id === appId);

  // const menuItems = [];
  const activeModules = modules?.filter((menuItem) => menuItem.status === 1);
  const inactiveModules = modules?.filter((menuItem) => menuItem.status === 0);
  return (
    <div
      style={{
        height: "100%",
        display: "flex",

        height: "calc( 100vh - 60px)",
      }}
    >
      {modules.length === 0 ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            flexDirection: "column",
          }}
        >
          <img
            src={noModules}
            alt="No Module"
            style={{ width: "100%", maxWidth: "500px" }}
          />
          <Typography variant="h6">You Don't Have Any Modules Yet</Typography>
          <Typography variant="h7" style={{ padding: "10px" }}>
            Your Forms Will Appear Here
          </Typography>
          <Button
            variant="outlined"
            size="large"
            style={{
              borderColor: "#e65800",
              borderWidth: "2px",
              borderRadius: "4px",
              fontSize: "22px",
              padding: "0px 60px",
              color: "#e65800",
            }}
            onClick={() => navigateTo(`${applicationName}/add-new-module`)}
          >
            CREATE MODULE
          </Button>
        </div>
      ) : (
        <div className="admindashboardContents">
          <div className="numberedadminapplication">
            <div className="totaladminapplicationModules">
              <p className="adminapplicationpara">{` ${modules?.length} Modules`}</p>
            </div>
            <div className="totaladminapplicationModules">
              <p className="adminapplicationpara">{` ${activeModules.length} Active`}</p>
            </div>
            <div className="totaladminapplicationModules">
              <p className="adminapplicationpara">{` ${inactiveModules.length} Inactive`}</p>
            </div>
          </div>
          <div className="activeadminapplication">
            <a
              onClick={() => {
                dispatch(application(appID));
                navigateTo(`${appId.aplName}/add-new-module`);
              }}
              className="adminapplicationsLink"
            >
              <div className="adminapplications">
                <i id="APplus" className="fa-solid fa-plus"></i>
                <p className="adminapplicationpara"> Add New </p>
                <p className="adminapplicationpara"> Module </p>
              </div>
            </a>

            {modules?.map((item, index) => {
              return (
                <div key={index} className="adminapplications">
                  <CardModel
                    ReducerFunction={moduleAdmin}
                    item={item}
                    Name={item.moduleName}
                    updatedDate={item.updated_at}
                    id={item._id}
                    navigate={`${appId.aplName}/${item.moduleName}/dashboard`}
                    Icon={item.moduleIcon}
                  />
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}

export default AdminDashboard;
