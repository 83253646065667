export const feesRow = [
  {
    id: 1,
    amount: "2743",
    username: "alex",
  },
  {
    id: 2,
    amount: "6321",
    username: "sachin",
  },
  {
    id: 3,
    amount: "2654",
    username: "megha",
  },
  {
    id: 4,
    amount: "5734",
    username: "amit",
  },
  {
    id: 5,
    amount: "7299",
    username: "newton",
  },
  {
    id: 6,
    amount: "7423",
    username: "vishal",
  },
  {
    id: 7,
    amount: "6632",
    username: "mannu",
  },
  {
    id: 8,
    amount: "8324",
    username: "rohan",
  },
  {
    id: 9,
    amount: "4522",
    username: "abhishek",
  },
  {
    id: 10,
    amount: "8724",
    username: "ram",
  },
];

export const videosRow = [
  {
    id: 1,
    videoUrl: "https://www.youtube.com/watch?v=cahO3_JAAoc",
    description:
      "Kinematics is a subfield of physics, developed in classical mechanics, that describes the motion of points, bodies, and systems of bodies without considering the forces that cause them to move.",
  },
  {
    id: 2,
    videoUrl: "https://www.youtube.com/watch?v=cahO3_JAAoc",
    description:
      "Magnetism is the class of physical attributes that are mediated by a magnetic field, which refers to the capacity to induce attractive and repulsive phenomena in other entities.",
  },
  {
    id: 3,
    videoUrl: "https://www.youtube.com/watch?v=cahO3_JAAoc",
    description:
      "Friction is the force resisting the relative motion of solid surfaces, fluid layers, and material elements sliding against each other.",
  },
  {
    id: 4,
    videoUrl: "https://www.youtube.com/watch?v=cahO3_JAAoc",
    description:
      "In physics, motion is the phenomenon in which an object changes its position with respect to time.",
  },
  {
    id: 5,
    videoUrl: "https://www.youtube.com/watch?v=cahO3_JAAoc",
    description:
      "Gravity is a fundamental interaction which causes mutual attraction between all things with mass or energy",
  },
  {
    id: 6,
    videoUrl: "https://www.youtube.com/watch?v=cahO3_JAAoc",
    description:
      "Matter is any substance that has mass and takes up space by having volume",
  },
  {
    id: 7,
    videoUrl: "https://www.youtube.com/watch?v=cahO3_JAAoc",
    description:
      " Energy is the quantitative property that is transferred to a body or to a physical system, recognizable in the performance of work and in the form of heat and light. Energy is a conserved quantity—the law of conservation of energy states that energy can be converted in form, but not created or destroyed",
  },
  {
    id: 8,
    videoUrl: "https://www.youtube.com/watch?v=cahO3_JAAoc",
    description:
      "Work is the energy transferred to or from an object via the application of force along a displacement. In its simplest form, for a constant force aligned with the direction of motion, the work equals the product of the force strength and the distance traveled",
  },
  {
    id: 9,
    videoUrl: "https://www.youtube.com/watch?v=cahO3_JAAoc",
    description:
      "In mathematics and physics, vector is a term that refers colloquially to some quantities that cannot be expressed by a single number, or to elements of some vector spaces",
  },
  {
    id: 10,
    videoUrl: "https://www.youtube.com/watch?v=cahO3_JAAoc",
    description:
      "Inertia is the idea that an object will continue its current motion until some force causes its speed or direction to change. The term is properly understood as shorthand for 'the principle of inertia' as described by Newton in his first law of motion.",
  },
];

export const notesRow = [
  {
    id: 1,
    title: "Kinematics",
    description:
      "Kinematics is a subfield of physics, developed in classical mechanics, that describes the motion of points, bodies, and systems of bodies without considering the forces that cause them to move.",
    file: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
  },
  {
    id: 2,
    title: "Magnetism",
    description:
      "Magnetism is the class of physical attributes that are mediated by a magnetic field, which refers to the capacity to induce attractive and repulsive phenomena in other entities.",
    file: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
  },
  {
    id: 3,
    title: "Friction",
    description:
      "Friction is the force resisting the relative motion of solid surfaces, fluid layers, and material elements sliding against each other.",
    file: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
  },
  {
    id: 4,
    title: "Motion",
    description:
      "In physics, motion is the phenomenon in which an object changes its position with respect to time.",
    file: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
  },
  {
    id: 5,
    title: "Gravity",
    description:
      "Gravity is a fundamental interaction which causes mutual attraction between all things with mass or energy",
    file: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
  },
  {
    id: 6,
    title: "Matter",
    description:
      "Matter is any substance that has mass and takes up space by having volume",
    file: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
  },
  {
    id: 7,
    title: "Energy",
    description:
      " Energy is the quantitative property that is transferred to a body or to a physical system, recognizable in the performance of work and in the form of heat and light. Energy is a conserved quantity—the law of conservation of energy states that energy can be converted in form, but not created or destroyed",
    file: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
  },
  {
    id: 8,
    title: "Work",
    description:
      "Work is the energy transferred to or from an object via the application of force along a displacement. In its simplest form, for a constant force aligned with the direction of motion, the work equals the product of the force strength and the distance traveled",
    file: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
  },
  {
    id: 9,
    title: "Vectors",
    description:
      "In mathematics and physics, vector is a term that refers colloquially to some quantities that cannot be expressed by a single number, or to elements of some vector spaces",
    file: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
  },
  {
    id: 10,
    title: "Inertia",
    description:
      "Inertia is the idea that an object will continue its current motion until some force causes its speed or direction to change. The term is properly understood as shorthand for 'the principle of inertia' as described by Newton in his first law of motion.",
    file: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
  },
];

export const Usersrow = [
  {
    id: 1,
    name: "Alex",
    email: "alex@gmail.com",
    profile:
      "https://firebasestorage.googleapis.com/v0/b/beyond-education.appspot.com/o/files%2FClass%2010.png?alt=media&token=0ba98235-c53b-4da5-92cb-d5f27307b3e3",
    phone: "9865676567",
    gender: "Male",
    languages: ["English", "French", "German"],
    file: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    multipleFiles: [
      "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
      "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
      "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    ],
    about:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer ullamcorper lobortis faucibus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi volutpat venenatis ultrices. Ut at sapien euismod, ornare est sed, vestibulum neque. Nam venenatis eget odio quis rhoncus. Proin nulla risus, porta at convallis et, posuere ut turpis.",
    skills: ["Java", "Python"],
  },
  {
    id: 2,
    name: "Newton",
    email: "newton@gmail.com",
    profile:
      "https://firebasestorage.googleapis.com/v0/b/beyond-education.appspot.com/o/files%2FClass%2010.png?alt=media&token=0ba98235-c53b-4da5-92cb-d5f27307b3e3",
    phone: "6787621234",
    gender: "Male",
    languages: ["English", "Hindi"],
    file: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    multipleFiles: [
      "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
      "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
      "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    ],
    about:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer ullamcorper lobortis faucibus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi volutpat venenatis ultrices. Ut at sapien euismod, ornare est sed, vestibulum neque. Nam venenatis eget odio quis rhoncus. Proin nulla risus, porta at convallis et, posuere ut turpis.",
    skills: ["javascript", "perl"],
  },
  {
    id: 3,
    name: "Sachin",
    email: "sachin@gmail.com",
    gender: "Male",
    profile:
      "https://firebasestorage.googleapis.com/v0/b/beyond-education.appspot.com/o/files%2FClass%2010.png?alt=media&token=0ba98235-c53b-4da5-92cb-d5f27307b3e3",
    phone: "9986120952",
    languages: ["English", "Hindi"],
    file: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    multipleFiles: [
      "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
      "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
      "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    ],
    skills: ["basic", "ruby"],
    about:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer ullamcorper lobortis faucibus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi volutpat venenatis ultrices. Ut at sapien euismod, ornare est sed, vestibulum neque. Nam venenatis eget odio quis rhoncus. Proin nulla risus, porta at convallis et, posuere ut turpis.",
  },
  {
    id: 4,
    name: "Amit",
    email: "amit@gmail.com",
    languages: ["Hindi", "French"],
    file: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    multipleFiles: [
      "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
      "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
      "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    ],
    profile:
      "https://firebasestorage.googleapis.com/v0/b/beyond-education.appspot.com/o/files%2FClass%2010.png?alt=media&token=0ba98235-c53b-4da5-92cb-d5f27307b3e3",
    phone: "9987123054",
    gender: "Male",
    skills: ["react", "node"],
    about:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer ullamcorper lobortis faucibus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi volutpat venenatis ultrices. Ut at sapien euismod, ornare est sed, vestibulum neque. Nam venenatis eget odio quis rhoncus. Proin nulla risus, porta at convallis et, posuere ut turpis.",
  },
  {
    id: 5,
    name: "Ram",
    email: "ram@gmail.com",
    profile:
      "https://firebasestorage.googleapis.com/v0/b/beyond-education.appspot.com/o/files%2FClass%2010.png?alt=media&token=0ba98235-c53b-4da5-92cb-d5f27307b3e3",
    phone: "9912729567",
    gender: "Male",
    languages: ["English", "German"],
    file: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    multipleFiles: [
      "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
      "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
      "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    ],
    skills: ["data science", "python"],
    about:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer ullamcorper lobortis faucibus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi volutpat venenatis ultrices. Ut at sapien euismod, ornare est sed, vestibulum neque. Nam venenatis eget odio quis rhoncus. Proin nulla risus, porta at convallis et, posuere ut turpis.",
  },
  {
    id: 6,
    name: "Vishal",
    email: "vishal@gmail.com",
    profile:
      "https://firebasestorage.googleapis.com/v0/b/beyond-education.appspot.com/o/files%2FClass%2010.png?alt=media&token=0ba98235-c53b-4da5-92cb-d5f27307b3e3",
    phone: "9965120867",
    gender: "Male",
    languages: ["French", "German"],
    file: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    multipleFiles: [
      "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
      "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
      "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    ],
    skills: ["react", "python"],
    about:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer ullamcorper lobortis faucibus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi volutpat venenatis ultrices. Ut at sapien euismod, ornare est sed, vestibulum neque. Nam venenatis eget odio quis rhoncus. Proin nulla risus, porta at convallis et, posuere ut turpis.",
  },
  {
    id: 7,
    name: "Megha",
    email: "megha@gmail.com",
    profile:
      "https://firebasestorage.googleapis.com/v0/b/beyond-education.appspot.com/o/files%2FClass%2010.png?alt=media&token=0ba98235-c53b-4da5-92cb-d5f27307b3e3",
    phone: "6787621234",
    languages: ["English", "Hindi"],
    file: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    multipleFiles: [
      "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
      "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
      "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    ],
    gender: "Female",
    skills: ["php", "ruby"],
    about:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer ullamcorper lobortis faucibus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi volutpat venenatis ultrices. Ut at sapien euismod, ornare est sed, vestibulum neque. Nam venenatis eget odio quis rhoncus. Proin nulla risus, porta at convallis et, posuere ut turpis.",
  },
  {
    id: 8,
    name: "Rishabh",
    email: "rishabh@gmail.com",
    profile:
      "https://firebasestorage.googleapis.com/v0/b/beyond-education.appspot.com/o/files%2FClass%2010.png?alt=media&token=0ba98235-c53b-4da5-92cb-d5f27307b3e3",
    phone: "9986120952",
    languages: ["Hindi"],
    file: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    multipleFiles: [
      "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
      "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
      "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    ],
    gender: "Male",
    skills: ["java", "C++"],
    about:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer ullamcorper lobortis faucibus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi volutpat venenatis ultrices. Ut at sapien euismod, ornare est sed, vestibulum neque. Nam venenatis eget odio quis rhoncus. Proin nulla risus, porta at convallis et, posuere ut turpis.",
  },
  {
    id: 9,
    name: "Prashant",
    email: "prashant@gmail.com",
    profile:
      "https://firebasestorage.googleapis.com/v0/b/beyond-education.appspot.com/o/files%2FClass%2010.png?alt=media&token=0ba98235-c53b-4da5-92cb-d5f27307b3e3",
    phone: "9987123054",
    languages: ["English", "German"],
    file: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    multipleFiles: [
      "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
      "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
      "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    ],
    gender: "Male",
    skills: ["Excel", "GoLang"],
    about:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer ullamcorper lobortis faucibus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi volutpat venenatis ultrices. Ut at sapien euismod, ornare est sed, vestibulum neque. Nam venenatis eget odio quis rhoncus. Proin nulla risus, porta at convallis et, posuere ut turpis.",
  },
  {
    id: 10,
    name: "Mannu",
    email: "Mannu@gmail.com",
    profile:
      "https://firebasestorage.googleapis.com/v0/b/beyond-education.appspot.com/o/files%2FClass%2010.png?alt=media&token=0ba98235-c53b-4da5-92cb-d5f27307b3e3",
    phone: "9912729567",
    languages: ["English", "Hindi"],
    file: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    multipleFiles: [
      "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
      "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
      "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    ],
    gender: "Male",
    skills: ["java", "python"],
    about:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer ullamcorper lobortis faucibus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi volutpat venenatis ultrices. Ut at sapien euismod, ornare est sed, vestibulum neque. Nam venenatis eget odio quis rhoncus. Proin nulla risus, porta at convallis et, posuere ut turpis.",
  },
];
