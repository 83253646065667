import { Box, CircularProgress } from '@mui/material'
import React from 'react'
import { Theme } from '../utils/Theme'

function LoadingModal() {
  return (
    <>
    <div style={{display:"flex",
    justifyContent:"center",
    alignItems:"center",
    height:"100vh",
    width:"100vw",
    position:"fixed",
    top:0,
    left:0,
    bottom:0,
    right:0,
    zIndex:9999
    }}>
    <Box>
        <CircularProgress style={{height:"70px",width:"70px", color:Theme.primary}}/>
    </Box>
    </div>
    </>
  )
}

export default LoadingModal