import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Button, CardActionArea, CardActions } from "@mui/material";
import { useNavigate } from "react-router-dom/dist";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { deleteApplication } from "../services/RestApi";
import Snackbar from "@mui/material/Snackbar/Snackbar";
import { horizontal, vertical } from "../Login";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment/moment";

function CardSetting({
  Name,
  updatedDate,
  Icon,
  navigate,
  id,
  edit,
  item,
  tags,
  BottomClicked,
  ReducerFunction,
  Application,
  EditMode,
}) {
  const [message, setMessage] = useState("");
  const dispatch = useDispatch();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [tagString, setTagString] = useState("");
  const deleteHandler = () => {
    deleteApplication({ id: id }).then((res) => {
      if (res.status == 200) {
        setOpenSnackbar(true);
        setMessage(res.message);
      }
    });
  };

  useEffect(() => {
    const tagStr = tags?.join(", ");
    setTagString(tagStr);
  }, []);

  const navigateTo = useNavigate();
  const date = new Date(updatedDate);
  const editHandler = () => {
    if (BottomClicked && !Application) {
      BottomClicked(true, item);
    }
  };
  const clickHanlder = () => {
    if (BottomClicked && Application) {
      BottomClicked(true, item);
    } else {
      console.log("Navvv-->", navigate);
      navigate && navigateTo(navigate);
      ReducerFunction && dispatch(ReducerFunction(item));
    }
  };

  return (
    <Card sx={{ maxWidth: 200, borderRadius: "13px" }}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={openSnackbar}
        autoHideDuration={6000}
        message={message}
        key={vertical + horizontal}
      />
      <CardActionArea 
        onClick={() => {
          clickHanlder();
        }}
      >
        <CardMedia 
          component="img"
          height={150}
          image={
            Icon
              ? Icon
              : `https://ui-avatars.com/api/?background=03bfeb&color=fff&name=${Name}`
          }
          alt="Name"
        />
        <CardContent style={{ textAlign: "center" }}>
          <Typography style={{height:"30px"}} gutterBottom variant="h5" component="div">
            {Name}
          </Typography>
          <div style={{width:"100%" , height:"2px" ,  backgroundColor:"#03bfeb"}}></div>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export default CardSetting;
