import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "16px",
  p: 4,
};

const styleImage = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "16px",
  p: 4,
};

const styleMultiple = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "16px",
  padding: "60px 30px 30px 30px",
};

export default function PopUpModal({
  dataObject,
  openedStatus,
  toggleHandler,
}) {
  const toggleModal = () => {
    toggleHandler(false);
  };
  return (
    <div>
      <Modal
        open={openedStatus}
        onClose={toggleModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {dataObject.image ? (
          <Box sx={styleImage}>
            <CloseIcon
              fontSize="large"
              onClick={toggleModal}
              style={{
                cursor: "pointer",
                position: "absolute",
                right: "15px",
                top: "15px",
              }}
            ></CloseIcon>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {dataObject.title}
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <img src={dataObject.image} alt="" />
            </Box>
          </Box>
        ) : dataObject.fullText ? (
          <Box sx={style}>
            <CloseIcon
              onClick={toggleModal}
              fontSize="large"
              style={{
                cursor: "pointer",
                position: "absolute",
                right: "15px",
                top: "15px",
              }}
            ></CloseIcon>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {dataObject.title}
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              {dataObject.fullText}
            </Typography>
          </Box>
        ) : (
          <Box sx={styleMultiple}>
            <CloseIcon
              onClick={toggleModal}
              fontSize="large"
              style={{
                cursor: "pointer",
                position: "absolute",
                right: "15px",
                top: "15px",
              }}
            ></CloseIcon>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{ background: "#0096FF", color: "white" }}
                    >
                      File Name
                    </TableCell>
                    <TableCell
                      style={{ background: "#0096FF", color: "white" }}
                    >
                      Download Link
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataObject.map((row) => (
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row.split("/").pop()}
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="outlined"
                          size="small"
                          endIcon={<DownloadIcon />}
                          onClick={() => window.open(row)}
                        >
                          Download
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}
      </Modal>
    </div>
  );
}
