import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import GroupsIcon from '@mui/icons-material/Groups';
import { HomeOutlined } from "@mui/icons-material";
import "./SettingSidebar.css"
import ContentHeader from '../components/ContentHeader';
import {
    Avatar,
    Button,
    Modal,
    Paper,
    Input,
    Container,
    IconButton,
    Typography,
} from "@mui/material";
import { EditOutlined, DeleteOutline } from "@mui/icons-material";
import "./UserProfile.css"; // Import your custom CSS for styling

const UserProfile = () => {
    let navigate = useNavigate();

    const appName = "User Profile";

    const [appId, setAppId] = useState({
        aplName: '',
        moduleName: appName,
        aplDescription: '',
        moduleDescription: '',
    });

    const [avatar, setAvatar] = useState("path_to_default_avatar");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const fileInputRef = useRef(null);
    const [hide, setHide] = useState(false);

    console.log("Hide--->", hide)

    const handleEdit = () => {
        setHide(true)
    };

    const handleSave = () => {
        setHide(true);
    };

    const handleAvatarChange = (imageLink) => {
        setAvatar(imageLink);
        setIsModalOpen(false);
    };

    const handleRemove = () => {
        // Reset the avatar to default when removing
        setAvatar("path_to_default_avatar");
        setIsModalOpen(false);
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            // Handle image upload logic here
            // Replace this with actual image upload logic
            const imageLink = "path_to_uploaded_image";
            handleAvatarChange(imageLink);
        }
    };


    return (
        <>
            <div style={{ display: 'flex', alignItems: 'top', justifyContent: 'top' }}>

                <div className="sidebar_settings">

                    <div className="sidebar_flex" onClick={() => navigate("/admin")} >
                        <HomeOutlined style={{ marginRight: "5px" }} fontSize="small" />
                        <p className="sidebar_profile_para">Home</p>
                    </div>

                    <div className="sidebar_flex" style={{ backgroundColor: "rgb(91, 192, 222)", color: "white" }}>
                        <GroupsIcon style={{ marginRight: "5px" }} fontSize="small" />
                        <p className="sidebar_profile_para">Profile</p>
                    </div>

                </div>
                <div style={{ display: "flex", flexDirection: "column", width: "85%" }}>

                    <div>
                        <ContentHeader appId={appId} applicationName={"User Profile"} />
                    </div>

                    <div
                        style={{ alignItems: 'center', justifyContent: 'center', padding: "36px  ", backgroundColor: "rgb(250 251 252)", }}>

                        <div style={{ display: "flex", justifyContent: "space-between" }} >
                            <Container className="account-settings-container">
                                <div className="avatar-container position">

                                    <Avatar alt="User Avatar" src={avatar} sx={{ width: 120, height: 120 }} />
                                    <div style={{ width: "20px", height: "20p" }} className="avatar-edit-button absolute" onClick={handleEdit}>
                                        <EditOutlined fontSize="small" />
                                    </div>
                                    <input
                                        type="file"
                                        accept="image/*"
                                        ref={fileInputRef}
                                        style={{ display: "none" }}
                                        onChange={handleFileChange}
                                    />


                                </div>
                                <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
                                    <Paper className="avatar-upload-modal">
                                        <div className="edit-remove-options">
                                            <IconButton onClick={handleRemove}>
                                                <DeleteOutline fontSize="small" />
                                            </IconButton>
                                        </div>
                                    </Paper>
                                </Modal>
                            </Container>

                            <Button style={{height:"42px" , width:"12%"}} variant="contained" onClick={() => {
                                handleEdit(); // Trigger edit mode when clicking "Edit Profile"
                            }} >
                                Edit Profile</Button>
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", width: "40%" }} >
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <label htmlFor="">First Name </label>
                                <input type="text" />
                            </div>
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <label htmlFor="">Last Name </label>
                                <input type="text" />
                            </div>
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <label htmlFor="">Email Address </label>
                                <input type="text" />
                            </div>
                        </div>
                        {hide &&
                            <div style={{ marginTop: "10px" }}>
                                <Button color="secondary" variant="contained" onClick={() => {
                                    setHide(false);
                                    // Trigger edit mode when clicking "Edit Profile"
                                }} >
                                    Save
                                </Button>
                            </div>}
                    </div>
                </div>
            </div>
        </>
    );

}
export default UserProfile;
