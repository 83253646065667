import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams, Link } from "react-router-dom";
import "./Sidebar.css";
import Application_logo from "../utils/application.json";
import { useState } from "react";
import { windows } from "fontawesome";
import { useDispatch } from "react-redux";
import { submodule } from "../Store/Slices/UserSlice";
import { fetchSubModuleByModuleId } from "../services/RestApi";
import { HomeOutlined } from "@mui/icons-material";
import alphechLogo from "../../src/assets/logo.png";
import logoHorizontal from "../Images/logo_horizontal.png";

function Sidebar({ currentModule, isSidebarOpen }) {
  const { applicationName, moduleName } = useParams();
  const [toggle, setToggle] = useState("");
  const [subModules, setSubModules] = useState([]);
  const [state, setState] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();
  const [addState, setAddState] = useState(false);
  const [viewState, setViewState] = useState(false);
  const [dashboardState, setDashboardState] = useState(true);
  const isInEditMode = location.pathname.split("/")[2] === "edit";
  const isInModules = params.moduleName ? true : false;

  let navigate = useNavigate();

  useEffect(() => {
    fetchSubModuleByModuleId({
      moduleId: isInEditMode
        ? currentModule[0].moduleId
        : currentModule.moduleId,
    })
      .then((res) => {
        if (res.status === 201) {
          setSubModules(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [location]);

  const handleOnClickSubModule = (item, state) => {
    if (state === "add") {
      setAddState(true);
      setViewState(false);
      setDashboardState(false);
    } else if (state === "list") {
      setViewState(true);
      setAddState(false);
      setDashboardState(false);
    }

    setState(true);
    if (state) {
      dispatch(submodule(item));

      let path = `/admin/view/${applicationName}/${moduleName}/${item.subModuleName}/${state}`;

      navigate(path);
    }
  };

  const OnSwitchHandler = (e) => {
    toggle === e ? setToggle("") : setToggle(e);
  };

  return (
    <>
      {/* Sidebar */}

      <div className="sidebar">
        {/* <div
          className="sidebar_application_div"
          onClick={() => {
            navigate(`/`, { state: { currentModule } });
          }}
        >
          <img
            src={Application_logo.applicationIcon}
            className="sidebar_brand_logo"
          />
        </div> */}
        {/* <div
          className="sidebar_flex"
          onClick={() => {
            navigate(`/`, { state: { currentModule } });
          }}
        >
          <i
            className="fa-solid fa-chalkboard-user"
            id="sidebar_dashboard_icon_appicon"
          ></i>
          <p className="sidebar_profile_para_appname">
            {Application_logo.applicationName}
          </p>
        </div> */}

        <img
          src={logoHorizontal}
          style={{
            width: "200px",
            cursor: "pointer",
            visibility: isInModules && isSidebarOpen ? "" : "hidden",
            paddingLeft: "25px",
          }}
          onClick={() => navigate("/admin")}
        />

        <div className="sidebar_flex" onClick={() => navigate("/admin")}>
          <HomeOutlined style={{ marginRight: "5px" }} fontSize="small" />
          <p className="sidebar_profile_para">Home</p>
        </div>
        <div
          style={
            dashboardState
              ? { backgroundColor: "#5bc0deff", color: "white" }
              : null
          }
          // className={
          //   window.location.pathname === "/LMS/dashboard"
          //     ? "sidebar_highlight_flex"
          //     : "sidebar_flex"
          // }
          className="sidebar_flex"
          onClick={(e) => {
            setDashboardState(true);
            setAddState(false);
            setViewState(false);

            navigate(
              isInEditMode
                ? `/admin/edit/${applicationName}/${moduleName}/dashboard`
                : `/admin/view/${applicationName}/${moduleName}/dashboard`,
              {
                state: { currentModule },
              }
            );
          }}
        >
          <i
            className="fa-solid fa-chalkboard-user"
            id="sidebar_dashboard_icon"
          ></i>
          <p className="sidebar_profile_para">Dashboard</p>
        </div>

        {subModules.map((item, index) => {
          return (
            <React.Fragment key={index}>
              <div
                className="sidebar_flex"
                value={item?.subModuleName}
                style={
                  item?.subModuleName == window.location.pathname.split("/")[2]
                    ? { backgroundColor: "#f0f4fcff" }
                    : {}
                }
                onClick={(e) => {
                  OnSwitchHandler(item.subModuleName);
                }}
              >
                <i className="fa-solid fa-layer-group" id="sidebar_icons"></i>

                <p className="sidebar_profile_para">{item.subModuleName}</p>
                <i
                  className={
                    toggle !== item.subModuleName
                      ? "fa-solid fa-caret-down"
                      : "fa-solid fa-caret-up"
                  }
                  id="sidebar_profile_child_icon"
                ></i>
              </div>

              <div
                style={
                  addState
                    ? { backgroundColor: "#5bc0deff", color: "white" }
                    : null
                }
                className={
                  toggle !== item.subModuleName
                    ? "sidebar_flex_submodule_hide"
                    : "sidebar_flex_submodule_show"
                }
                onClick={() => handleOnClickSubModule(item, "add")}
              >
                <p className="sidebar_flex_submodule_add">
                  {" "}
                  <i
                    className="fa-solid fa-file-circle-plus"
                    id="sidebar_flex_submodule_add_icon"
                  ></i>
                  Add
                </p>
              </div>

              <div
                style={
                  viewState
                    ? { backgroundColor: "#5bc0deff", color: "white" }
                    : null
                }
                className={
                  toggle !== item.subModuleName
                    ? "sidebar_flex_submodule_hide"
                    : "sidebar_flex_submodule_show"
                }
                onClick={() => handleOnClickSubModule(item, "list")}
              >
                <p className="sidebar_flex_submodule_view">
                  {" "}
                  <i
                    className="fa-solid fa-eye"
                    id="sidebar_flex_submodule_add_icon"
                  ></i>
                  View
                </p>
              </div>
            </React.Fragment>
          );
        })}
      </div>
    </>
  );
}

export default Sidebar;
