import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
// import Header from "../components/Header";
import MainSideBar from "../components/MainSideBar";
import "./AdminOutlet.css";
import { Breadcrumbs, Link } from "@mui/material";

import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
// import SidebarIcon from "../components/SidebarIcon";
import { useSelector } from "react-redux";
import { fetchSubModuleByModuleId } from "../services/RestApi";
import Footer from "../components/Footer";

function AdminOutlet() {
  const location = useLocation();
  const params = useParams();

  const isInEditMode = useLocation().pathname.split("/")[2] === "edit";

  // const [subModules, setSubModules] = useState([]);
  // const navigate = useNavigate();
  const [state, setState] = useState(false);
  const [sidebar, setSidebar] = useState(true);

  const currentModule = useSelector((state) => {
    return isInEditMode ? state.admin.modArray : state.application.moduleArray;
  });

  // useEffect(() => {
  //   console.log("Runiinnggg");
  //   if (!isInEditMode) {
  //     setSidebar(true);
  //   } else {
  //     setSidebar(false);
  //   }
  // }, [location]);

  useEffect(() => {
    checkForm();
  }, [location]);
  const checkForm = () => {
    if (location.pathname?.includes("FormBuilder")) {
      setState(true);
    } else {
      setState(false);
    }
  };

  const collapse = () => {
    setSidebar(!sidebar);
  };

  // useEffect(() => {
  //   fetchSubModuleByModuleId({ moduleId: currentModule.moduleId })
  //     .then((res) => {
  //       console.log(res, "submodule?");
  //       if (res.status === 201) {
  //         setSubModules(res.data);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, []);

  // return (
  //   <div>
  //     <div className="AdminOutlet">
  //       {/* navbar */}
  //       <Header />
  //     </div>

  //     <div style={{ width: "100vw", display: "flex" }}>
  //       <div style={!state ? { width: "20%" } : { display: "none" }}>
  //         <MainSideBar />
  //       </div>
  //       <div style={{ width: "100%" }}>
  //         <Outlet />
  //       </div>
  //     </div>
  //   </div>
  // );
  return (
    <>
      {/* Breadcrumbs */}
      {/* {!state && (
        <div className="moduledashboard_dashboard_breadcrumbs">
          <Breadcrumbs
            aria-label="breadcrumb"
            separator={<i class="fa-solid fa-angle-right"></i>}
          >
            <Link
              underline="hover"
              color="inherit"
              href={`/${currentModule.moduleName}/dashboard`}
              fontSize="small"
            >
              {currentModule.moduleName}
            </Link>

            {location.pathname === "/LMS/dashboard" ? (
              <Link
                underline="hover"
                color="inherit"
                href={`/${currentModule.moduleName}/dashboard`}
                fontSize="small"
              >
                Dashboard
              </Link>
            ) : (
              <div>
                {location.pathname === `/LMS/${params.submoduleName}/add` ? (
                  <Breadcrumbs
                    aria-label="breadcrumb"
                    separator={<i class="fa-solid fa-angle-right"></i>}
                  >
                    <Link
                      className="breadcrumb_dashboard_link"
                      underline="hover"
                      color="inherit"
                      onClick={() => {
                        navigate(`/${currentModule.moduleName}/dashboard`, {
                          state: { currentModule },
                        });
                      }}
                      fontSize="small"
                    >
                      Dashboard
                    </Link>
                    <Link underline="hover" color="inherit" fontSize="small">
                      {params.submoduleName}
                    </Link>
                    <Link
                      underline="hover"
                      color="inherit"
                      href={`/${currentModule.moduleName}/$dashboard`}
                      fontSize="small"
                    >
                      Add
                    </Link>
                  </Breadcrumbs>
                ) : (
                  <Breadcrumbs
                    aria-label="breadcrumb"
                    separator={<i class="fa-solid fa-angle-right"></i>}
                  >
                    <Link
                      className="breadcrumb_dashboard_link"
                      underline="hover"
                      color="inherit"
                      onClick={() => {
                        navigate(`/${currentModule.moduleName}/dashboard`, {
                          state: { currentModule },
                        });
                      }}
                      fontSize="small"
                    >
                      Dashboard
                    </Link>
                    <Link underline="hover" color="inherit" fontSize="small">
                      {params.submoduleName}
                    </Link>
                    <Link
                      underline="hover"
                      color="inherit"
                      onClick={() => {
                        navigate(
                          `/${currentModule.moduleName}/${params.submoduleName}/list`,
                          { state: { currentModule, params } }
                        );
                      }}
                      fontSize="small"
                    >
                      View
                    </Link>
                  </Breadcrumbs>
                )}
              </div>
            )}
          </Breadcrumbs>
        </div>
      )} */}

      <div className="moduledashboard_bodies">
        {/* <div className="moduledashboard_footer">
          <p className="moduledashboard_footer_para">
            Copyright @ 2023 Alphech Global
          </p>
        </div> */}
        {!state && params.moduleName && (
          <div style={sidebar?{}:null}
            className={
              sidebar
                ? "moduledashboard_bodies_sidebar_component_show"
                : "moduledashboard_bodies_sidebar_component_hide"
            }
          >
            <Sidebar currentModule={currentModule} isSidebarOpen={sidebar} />
          </div>
        )}
        {/* {!state && (
          <div className="sidebaricon_show">
            <SidebarIcon currentModule={currentModule} />
          </div>
        )} */}

        <div className="header_dashboard_panel" style={sidebar?{width:"100%"}:null}>
          <div>
            <Header
              src={
                currentModule.moduleIcon
                  ? currentModule.moduleIcon
                  : `https://ui-avatars.com/api/?background=0D8ABC&color=fff&name=${currentModule?.moduleName}`
              }
              moduleName={currentModule?.moduleName}
              collapse={collapse}
              state={state}
              isSidebarOpen={sidebar}
            />
          </div>
          <div>
            <div className="master_div">
              <div className="moduledashboard_panel">
                {/* {!state && (
                  <div className="moduledashboard_dashboard_bg">
                    <i
                      id="sidebar_menu"
                      className="fa-solid fa-bars"
                      onClick={collapse}
                    ></i>
                    <p className="moduledashboard_dashboard_bg_heading">
                      {currentModule.moduleName}
                    </p>
                  </div>
                )} */}

                <div className="moduleDashboard_Outlet">
                  <Outlet />
                </div>
                <Footer/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminOutlet;
