import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import './PopupModule.css'
import { Container } from 'reactstrap';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { useLocation } from 'react-router-dom';
import { ModifyApplicationAccessPost } from '../services/RestApi';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { useAppContext } from "./AppContext";
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField } from '@mui/material';



const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    // height: 500,
    bgcolor: 'background.paper',
    borderRadius: '2%',
    boxShadow: 24,
    p: 2,
};
const PopupModule = ({ handleReload }) => {
    const { currentContextState } = useAppContext();
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setRole('');
        setEmail('');
        setEmailError('');
        setRoleError('');
        setOpen(false);
    };

    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [role, setRole] = useState('');
    const [roleError, setRoleError] = useState('');

    const location = useLocation();
    const item = location.state;
    const app_id = item.item._id;
    const sourceId = item.item.team[0].source;

    const handleChange = (event) => {
        setRole(event.target.value);
        setRoleError('');
    };

    const onChangeHandler = (event) => {
        setEmail(event.target.value);
        setEmailError('');
    };

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
};

    const handleInvite = () => {
        let isValid = true;

        if (!validateEmail(email)) {
            setEmailError('Invalid email address');
            isValid = false;
        } else {
            setEmailError('');
        }

        if (!role) {
            setRoleError('Please select a role');
            isValid = false;
        } else {
            setRoleError('');
        }

        if (!isValid) {
            return;
        }

        const inviteData = {
            email,
            app_id,
            source_id: sourceId,
            role,
        };

        ModifyApplicationAccessPost(inviteData)
            .then((res) => {
                if (res.data.message === "User doesn't exist") {
                    setSnackbarMessage(res.data.message);
                    setSnackbarSeverity('error');
                    setOpenSnackbar(true);
                } else {
                    setSnackbarMessage('Invite sent successfully!');
                    setSnackbarSeverity('success');
                    setOpenSnackbar(true);

                    handleClose();
                    handleReload();
                }
            })
            .catch((error) => {
                setSnackbarMessage('Failed to send invite!');
                setSnackbarSeverity('error');
                setOpenSnackbar(true);
            });
    };

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { m: 1, width: '25ch' },
            }}
            noValidate
            autoComplete="off"
        >
            <div style={{ textAlign: 'right', margin: '20px 20px' }}>
                <Button variant="contained" onClick={handleOpen}>
                    Invite Member
                </Button>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                    <Container className="new-add">
                        <div className="board">
                            <h3>Invite Member</h3>
                            <div className="cross" onClick={handleClose}>
                                ✖
                            </div>
                        </div>

                        <div className="input-field">
                            <p>You're inviting members to the project</p>
                            <h6>Username or email address</h6>
                            <TextField

                                style={{ width: '90%' }}
                                placeholder="Email address or name"
                                value={email}
                                onChange={onChangeHandler}
                        
                            />
                            {emailError && <p style={{ color: 'red' }}>{emailError}</p>}
                            <p>Select member or type email address</p>
                        </div>

                        <div>
                            <InputLabel style={{ width: '40%' }} id="demo-simple-select-label">
                                <p style={{ fontWeight: 900 }}>Select a role</p>
                            </InputLabel>
                            <Select labelId="demo-simple-select-label" id="demo-simple-select" value={role} onChange={handleChange}>
                                <MenuItem value={"owner"}>Owner</MenuItem>
                                <MenuItem value={"admin"}>Admin</MenuItem>
                                <MenuItem value={"editor"}>Editor</MenuItem>
                                <MenuItem value={"user"}>User</MenuItem>
                            </Select>
                            {roleError && <FormHelperText style={{ color: 'red' }}>{roleError}</FormHelperText>}
                            <p>Need more about role permission</p>
                        </div>

                        <div>
                            <h6>Access expiration date (optional)</h6>
                            <LocalizationProvider style={{ padding: '0px' }} dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DatePicker']}>
                                    <DatePicker />
                                </DemoContainer>
                            </LocalizationProvider>
                        </div>

                        <div style={{ textAlign: 'right', marginTop: '20px' }}>
                            <Button variant="outlined" onClick={handleClose}>
                                Cancel
                            </Button>{' '}
                            &nbsp;&nbsp;
                            <Button variant="contained" onClick={handleInvite}>
                                Invite
                            </Button>
                        </div>
                    </Container>
                    </Box>
                </Modal>

                {/* Snackbar component */}
                <Snackbar
                    open={openSnackbar}
                    autoHideDuration={3000}
                    onClose={handleSnackbarClose}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <MuiAlert onClose={handleSnackbarClose} severity={snackbarSeverity}>
                        {snackbarMessage}
                    </MuiAlert>
                </Snackbar>
            </div>
        </Box>
    );
};

export default PopupModule;


