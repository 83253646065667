import React, { useEffect, useState } from 'react'
import { ModifyApplicationAccess, applicationListByIdRole, getUserDetailsById } from '../services/RestApi';
import LoadingModal from '../components/LoadingModal';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { vertical, horizontal } from '../Login';
import { Card, Snackbar } from '@mui/material';
import "./TeamManage.css";


export default function TeamManage() {
  const [showLoading, SetShowLoading] = useState(true);
  const [applicationName, setApplicationName] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [editData, setEditData] = useState({});
  const [message, setMessage] = useState("");
  const [snackbar, setSnackbar] = useState(false);
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEditData((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };
  const changeUserAccess = async (app_id, isUser, isEditor) => {
    let remove = false, msg = "", role = editData.role?.toLowerCase(), email = editData.email;
    if (isUser) {
      role = "user"
      email = editData.user
      remove = true
      console.log(remove)
    }
    if (isEditor) {
      role = "editor"
      email = editData.editor
      remove = true
    }
    if (!role || !app_id || !email)
      msg = "Incomplete parameters"
    // console.log(msg, role, email,app_id)

    if (msg.length) {
      setMessage(msg);
      setSnackbar(true);
    }
    else {
      await ModifyApplicationAccess({ role, app_id, email, remove }).then(res => {
        setMessage(res.data.message);
        // console.log(res)
        setSnackbar(true);
      })
        .catch(err => {
          setMessage(err.response.data.err);
          setSnackbar(true);
          // console.log(err.response.data.err,"=======")
        })
      // setMe/ackbar(true);
    }
    countApplication()
  }
  const handleChangeAccordion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  useEffect(() => {
    countApplication();
  }, [])
  const countApplication = () => {
    applicationListByIdRole({ id: localStorage.getItem("_id") }).then(async (res) => {
      if (res.status === 200) {
        let count = res?.data.length;
        if (count === 0) {
          setMessage("Start building Applications");
          setSnackbar(true);
        }
        const array = await Promise.all(res.data.map(async (ele) => {
          let editors = ele.editor && await Promise.all(ele.editor?.map(async e => {
            let result_user;
            await getUserDetailsById({ _id: e }).then(res => result_user = res.data)
            return result_user
          }))
          let users = ele.user && await Promise.all(ele?.user?.map(async e => {
            let result_user;
            await getUserDetailsById({ _id: e }).then(res => result_user = res.data)
            return result_user
          }))
          return { apl: ele, users: users, editors: editors }
        }));
        setApplicationName(array);

      }
      SetShowLoading(false);
    });
  };
  return (
    <>

      {showLoading ? <LoadingModal /> :

        <Card className='table-card' style={{ position: "absolute", left: 0, top: 80, right: 0 }} >
          {applicationName.length && applicationName.map(ele => {
            return <div className="nav nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
              {/* {console.log(editData)} */}
              <Accordion sx={{ width: "100%" }} expanded={expanded === ele.apl.aplId} onChange={handleChangeAccordion(ele.apl.aplId)}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography sx={{ width: '33%', flexShrink: 0 }}>
                    {ele.apl.aplName}
                  </Typography>
                  <Typography sx={{ color: 'text.secondary' }}>{ele.apl.aplDescription}</Typography>
                </AccordionSummary>
                <AccordionDetails>

                  <div style={{ display: "flex", alignItems: "end" }} className='mb-5'>
                    <div >
                      <TextField className='my-2' style={{ width: "18.8rem" }} name="email" onChange={(event) => { handleInputChange(event) }} placeholder={"Email"} />
                      <Autocomplete
                        onChange={(e, v) => setEditData({ ...editData, role: v?.label })}
                        disablePortal
                        id="combo-box-demo"
                        options={[{ label: "Editor" }, { label: "User" }]}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="Role" />}
                      />
                    </div>
                    <button className='btn btn-primary mx-auto' style={{}} onClick={() => changeUserAccess(ele.apl._id.toString())}>Add new user</button>
                  </div>
                  <hr></hr>
                  <div className='d-flex justify-content-between'>
                    <div>
                      <Autocomplete
                        onChange={(e, v) => setEditData({ ...editData, editor: v?.label })}
                        className='my-2'
                        disablePortal
                        id="combo-box-demo"
                        options={ele.editors ?? []}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="Editors" />}
                      />
                      <button className='btn btn-primary my-4 mx-auto' onClick={() => changeUserAccess(ele.apl._id.toString(), "", true)}>Remove Editor</button>
                    </div>
                    <div>
                      {console.log(editData)}
                      <Autocomplete
                        onChange={(e, v) => setEditData({ ...editData, user: v?.label })}
                        className='my-2'
                        disablePortal
                        id="combo-box-demo"
                        options={ele.users ?? []}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="Users" />}
                      />
                      <button className='btn btn-primary my-4 mx-auto' onClick={() => changeUserAccess(ele.apl._id.toString(), true)}>Remove User</button>
                    </div>
                  </div>

                </AccordionDetails>
              </Accordion>
            </div>
          })}
        </Card>}


      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={snackbar}
        onClose={() => {
          setSnackbar(false)
        }}
        autoHideDuration={2000}
        message={message}
        key={vertical + horizontal}
        style={{ backgroundColor: "white", color: "black" }}
      />
    </>
  )
}

