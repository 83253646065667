import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import "./AdminPanel.css";
import { applicationListByIdRole, } from "../services/RestApi";
import { useDispatch } from "react-redux";
import { application } from "../Store/Slices/AdminSlice";
import CardSetting from "../admin/CardSetting";
import LoadingModal from "../components/LoadingModal";
import ContentHeader from "../components/ContentHeader";
import GroupsIcon from '@mui/icons-material/Groups';
import LockResetIcon from '@mui/icons-material/LockReset';
import { Grid, } from "@mui/material";
import { AddOutlined, HomeOutlined } from "@mui/icons-material";
import ResetPassword from "./ResetPassword";

function Settings() {

  const [applicationName, setApplicationName] = useState([]);
  const [applicationTotal, setApplicationTotal] = useState(0);
  const [activeCount, setActiveCount] = useState(0);
  const [inactiveCount, setInactiveCount] = useState(0);
  const [showLoading, SetShowLoading] = useState(true);
  const [popUp, setPopUp] = useState(false);
  const [bottomClicked, setBottomClicked] = useState(false);
  const [currentState, setCurrentState] = useState({});
  const passBottomSheet = true;
  const [homeState, setHomeState] = useState(false);
  const [accessState, setAccessState] = useState(true);
  const [resetState, setResetState] = useState(false);


  let navigate = useNavigate();

  const [appId, setAppId] = useState({ aplName: "", moduleName: "Settings", aplDescription: "", moduleDescription: "", });
  const [settings, setSettings] = useState("Settings");

  const countApplication = () => {
    applicationListByIdRole({ id: localStorage.getItem("_id") }).then((res) => {
      if (res.status === 200) {
        let count = res?.data.length;
        if (count === 0) setPopUp(true);
        setApplicationTotal(count);
        const array = res.data.map((ele) => ele);
        setApplicationName(array);
      }
      let activeCount = 0,
        inactiveCount = 0;

      res?.data.map((ele) => {
        if (ele.status === 1) {
          activeCount++;
        } else {
          inactiveCount++;
        }
      });
      setActiveCount(activeCount);
      setInactiveCount(inactiveCount);
      SetShowLoading(false);
    });
  };
  useEffect(() => {
    countApplication();
    const interval = setInterval(() => {
      countApplication();
    }, 10000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const [resetPass, setResetPass] = useState(false)

  const bottomClickHandler = (value, item) => {
    setBottomClicked(value);
    setCurrentState(item);
  };

  const handleResetPass = () => {
    setResetPass(true);
  }

  const handleAccessControl = () => {
    setResetPass(false)
  }

  return (
    <>
      {showLoading ? (
        <LoadingModal />
      ) : (
        <div className="masterDiv">

          <div>
            <div className="dashboardContents">

              {/* ActiveApplications */}
              {applicationTotal === 0 ? (
                //  <StepperForm/>
                <Navigate to="/admin/add-new-application" />

              ) : (

                <div style={{ display: 'flex', alignItems: 'top', justifyContent: 'top' }}>

                  <div className="sidebar_settings">

                    <div style={homeState ? { backgroundColor: "#5bc0deff", color: "white" } : null} className="sidebar_flex"
                      onClick={() => {
                        setHomeState(true)
                        setAccessState(false)
                        setResetState(false)
                        navigate("/admin")
                      }} >
                      <HomeOutlined style={{ marginRight: "5px" }} fontSize="small" />
                      <p className="sidebar_profile_para">Home</p>

                    </div>

                    <div style={accessState ? { backgroundColor: "#5bc0deff", color: "white" } : null} className="sidebar_flex"
                      onClick={() => {
                        setHomeState(false)
                        setAccessState(true)
                        setResetState(false)
                        handleAccessControl()
                      }} >
                      <GroupsIcon style={{ marginRight: "5px" }} fontSize="small" />
                      <p className="sidebar_profile_para">Access Control</p>
                    </div>

                    <div style={resetState ? { backgroundColor: "#5bc0deff", color: "white" } : null} className="sidebar_flex"
                      onClick={() => {
                        setHomeState(false)
                        setAccessState(false)
                        setResetState(true)
                        handleResetPass()
                      }} >
                      <LockResetIcon style={{ marginRight: "5px" }} fontSize="small" />
                      <p className="sidebar_profile_para">Reset Password</p>
                    </div>

                  </div>
                  <div style={{ display: "flex", flexDirection: "column", width: "85%" }}>
                    <ContentHeader appId={appId} applicationName={settings} />

                    {resetPass ? <ResetPassword /> : <div style={{ padding: "1.2rem", overflow: "scroll", height: "490px" }}>
                      <Grid container spacing={2} style={{ padding: "1rem" }}>

                        {applicationName.map((item, index) => {
                          console.log(item);
                          if (item.status === 1) {
                            return (
                              <Grid item xs={5} sm={4} md={2} key={index} onClick={() => navigate("/admin/memberscreen", { state: { item: item } })}>
                                <div key={index}>
                                  <CardSetting
                                    ReducerFunction={application}
                                    item={item}
                                    navigate={`/admin/view/${item.aplName}`}
                                    edit={`/admin/view/${item.aplName}/?edit=true`}
                                    id={item._id}
                                    updatedDate={item.updated_at}
                                    Name={item.aplName}
                                    Icon={item.aplLogo}
                                    tags={item.tags}
                                    BottomClicked={
                                      passBottomSheet
                                        ? bottomClickHandler
                                        : undefined
                                    }
                                    Application={true}
                                    EditMode={false}
                                  />

                                </div>
                              </Grid>
                            );
                          }
                        })}
                      </Grid>
                    </div>}

                  </div>

                </div>
              )}
            </div>
          </div>
        </div>
      )}

    </>
  );
}

export default Settings;
