import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { ModifyApplicationAccessPost } from '../services/RestApi';



export default function AlertDialog({ user, app_id }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const deleteUser = () => {
    setOpen(false);
    if (user.role !== "Owner") {
      const inviteData = {
        email: user.email,
        app_id,
        source_id: user.source,
        role: user.role,
        remove: true
      };
      ModifyApplicationAccessPost(inviteData)
        .then((res) => {
          console.log(res);
        });
    }
  }



  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        Remove Member
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {" Are you sure you want to delete?"}
        </DialogTitle>

        <DialogActions>
          <Button variant="outlined" color="error" onClick={handleClose}>No</Button>
          <Button variant="contained" onClick={deleteUser} autoFocus> Yes</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}