import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Header from './components/Header';
import Sidebar from './components/Sidebar'
import all_images from './my_imports/Imports'
import './Profile.css'
import submodules from './utils/subModule.json';


function Profile() {

    const params = useParams();

    /*     useEffect(()=>{
                alert(params.moduleName);
        },[params]) */

    return (
        // Navbar
        <div className='Profile_bodies'>
            <Header />
            <div className='master_div'>
                <Sidebar />

                {/* Main_panel */}
                <div className='main_panel'>
                    <div className='main_background'></div>
                    <div className='main_user_div'>
                        <img src={all_images.all_images.user6} className="main_user_image" />
                        <p className='profile_main_user_para'>{params.moduleName}</p>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Profile