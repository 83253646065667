import React from "react";
import {
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import Sidebar from "./components/Sidebar";
import Header from "./components/Header";
import "./ModuleDashboard.css";
import { useState } from "react";
// import SidebarIcon from "./components/SidebarIcon";
import { Breadcrumbs, Link } from "@mui/material";
import { useSelector } from "react-redux";

function ModuleDashboard() {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const currentModule = useSelector((state) => {
    return state.application.moduleArray;
  });

  const [sidebar, setSidebar] = useState(true);
  const collapse = () => {
    setSidebar(!sidebar);
  };
  return (
    <>
      {/* Breadcrumbs */}
      <div className="moduledashboard_dashboard_breadcrumbs">
        <Breadcrumbs
          aria-label="breadcrumb"
          separator={<i class="fa-solid fa-angle-right"></i>}
        >
          <Link
            underline="hover"
            color="inherit"
            href={`/${currentModule.moduleName}/dashboard`}
            fontSize="small"
          >
            {currentModule.moduleName}
          </Link>

          {location.pathname === "/LMS/dashboard" ? (
            <Link
              underline="hover"
              color="inherit"
              href={`/${currentModule.moduleName}/dashboard`}
              fontSize="small"
            >
              Dashboard
            </Link>
          ) : (
            <div>
              {location.pathname === `/LMS/${params.submoduleName}/add` ? (
                <Breadcrumbs
                  aria-label="breadcrumb"
                  separator={<i class="fa-solid fa-angle-right"></i>}
                >
                  <Link
                    className="breadcrumb_dashboard_link"
                    underline="hover"
                    color="inherit"
                    onClick={() => {
                      navigate(`/${currentModule.moduleName}/dashboard`, {
                        state: { currentModule },
                      });
                    }}
                    fontSize="small"
                  >
                    Dashboard
                  </Link>
                  <Link underline="hover" color="inherit" fontSize="small">
                    {params.submoduleName}
                  </Link>
                  <Link
                    underline="hover"
                    color="inherit"
                    href={`/${currentModule.moduleName}/$dashboard`}
                    fontSize="small"
                  >
                    Add
                  </Link>
                </Breadcrumbs>
              ) : (
                <Breadcrumbs
                  aria-label="breadcrumb"
                  separator={<i class="fa-solid fa-angle-right"></i>}
                >
                  <Link
                    className="breadcrumb_dashboard_link"
                    underline="hover"
                    color="inherit"
                    onClick={() => {
                      navigate(`/${currentModule.moduleName}/dashboard`, {
                        state: { currentModule },
                      });
                    }}
                    fontSize="small"
                  >
                    Dashboard
                  </Link>
                  <Link underline="hover" color="inherit" fontSize="small">
                    {params.submoduleName}
                  </Link>
                  <Link
                    underline="hover"
                    color="inherit"
                    onClick={() => {
                      navigate(
                        `/${currentModule.moduleName}/${params.submoduleName}/list`,
                        { state: { currentModule, params } }
                      );
                    }}
                    fontSize="small"
                  >
                    View
                  </Link>
                </Breadcrumbs>
              )}
            </div>
          )}
        </Breadcrumbs>
      </div>

      <div className="moduledashboard_bodies">
        {/* <div className="moduledashboard_footer">
          <p className="moduledashboard_footer_para">
            Copyright @ 2023 Alphech Global
          </p>
        </div> */}

        <div
          className={
            sidebar
              ? "moduledashboard_bodies_sidebar_component_show"
              : "moduledashboard_bodies_sidebar_component_hide"
          }
        >
          <Sidebar currentModule={currentModule} />
        </div>

        {/* <div className={sidebar ? "sidebaricon_hide" : "sidebaricon_show"}>
          <SidebarIcon currentModule={currentModule} />
        </div> */}

        <div className="header_dashboard_panel">
          <div>
            <Header
              src={
                currentModule.moduleIcon
                  ? currentModule.moduleIcon
                  : `https://ui-avatars.com/api/?background=0D8ABC&color=fff&name=${currentModule?.moduleName}`
              }
              moduleName={currentModule?.moduleName}
              collapse={collapse}
            />
          </div>
          <div>
            <div className="master_div">
              <div className="moduledashboard_panel">
                <div className="moduledashboard_dashboard_bg">
                  {/* <i
                    id="sidebar_menu"
                    className="fa-solid fa-bars"
                    onClick={collapse}
                  ></i> */}
                  <p className="moduledashboard_dashboard_bg_heading">
                    {currentModule.moduleName}
                  </p>
                </div>

                <div className="moduleDashboard_Outlet">
                  <Outlet />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ModuleDashboard;
